import React from "react";
import { Button, Modal, Upload, Image } from "antd";
import Text from "components/Text";
import theme from "styles/theme";
import * as S from "../styles";

interface UploadModalProps {
  isModalVisible: boolean;
  loading: boolean;
  previewImage: string | null;
  profileImage: string | null;
  handleCancelUpload: () => void;
  handleConfirmUpload: () => void;
  handleUpload: (info: any) => void;
}

const UploadModal: React.FC<UploadModalProps> = ({
  isModalVisible,
  loading,
  previewImage,
  profileImage,
  handleCancelUpload,
  handleConfirmUpload,
  handleUpload,
}) => {
  return (
    <Modal
      open={isModalVisible}
      onCancel={handleCancelUpload}
      closable
      maskClosable
      footer={null}
      title="ALTERAR FOTO DE PERFIL"
      width={400}
      style={{
        alignItems: "center",
        justifyContent: "center",
        textAlign: "center",
      }}
    >
      {previewImage ? (
        <S.UpdateContainer>
          <Text size="medium" bold>
            PRÉ-VISUALIZAÇÃO:
          </Text>
          <Image
            src={previewImage}
            alt="Preview Image"
            style={{ width: 150, height: 150, borderRadius: "50%" }}
          />
          <div style={{ marginTop: 16 }}>
            <Button
              onClick={handleCancelUpload}
              style={{
                width: "120px",
                margin: 8,
                color: `${theme.COLORS.PRIMARY}`,
                backgroundColor: "transparent",
                border: `1px solid ${theme.COLORS.PRIMARY}`,
              }}
            >
              Cancelar
            </Button>
            <Button
              type="primary"
              onClick={handleConfirmUpload}
              loading={loading}
              style={{
                width: "120px",
                margin: 8,
                color: "#000000",
                backgroundColor: `${theme.COLORS.PRIMARY}`,
                border: "none",
              }}
            >
              Confirmar envio
            </Button>
          </div>
        </S.UpdateContainer>
      ) : (
        <div style={{ textAlign: "center", justifyContent: "center" }}>
          <S.UpdateContainer>
            {profileImage && (
              <Image
                src={profileImage}
                alt="Profile Image"
                style={{ width: 150, height: 150, borderRadius: "50%" }}
              />
            )}
            <S.ButtonsContainer>
              <Upload
                showUploadList={false}
                beforeUpload={() => false}
                onChange={handleUpload}
                accept=".jpg,.jpeg,.png,.gif,.bmp,.webp,.tiff,.svg"
              >
                <Button
                  type="link"
                  block
                  style={S.PublishButtonLinkStyle}
                  loading={loading}
                >
                  Carregar nova foto de perfil
                </Button>
              </Upload>
            </S.ButtonsContainer>
          </S.UpdateContainer>
        </div>
      )}
    </Modal>
  );
};

export default UploadModal;
