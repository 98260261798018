import React from "react";
import { CheckCircleOutline as CheckCircleOutlineIcon } from "@mui/icons-material";
import { Box } from "@mui/material";
import Text from "components/Text";
import theme from "styles/theme";

const SuccessStatus: React.FC = () => {
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
      gap={0.5}
    >
      <CheckCircleOutlineIcon style={{ color: theme.COLORS.SUCCESS }} />
      <Text>Cadastro realizado!</Text>
      <Text>Perfil atualizado em até 30 minutos.</Text>
    </Box>
  );
};

export default SuccessStatus;
