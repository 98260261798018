import React, { createContext, useContext, useState, ReactNode } from "react";

interface DrawerContextProps {
  isOpenMenus: boolean;
  setIsOpenMenus: (open: boolean) => void;
  openDrawer: boolean;
  setOpenDrawer: (open: boolean) => void;
  openDrawerFilter: boolean;
  setOpenDrawerFilter: (open: boolean) => void;
  openDrawerCopilot: boolean;
  setOpenDrawerCopilot: (open: boolean) => void;
  openDrawerAdvisor: boolean;
  openDrawerMentorship: boolean;
  setOpenDrawerAdvisor: (open: boolean) => void;
  setOpenDrawerMentorship: (open: boolean) => void;
  activeFilter: any;
  setActiveFilter: (filter: any) => void;
  activePlanFeatures: any;
  setActivePlanFeatures: (features: any) => void;
  translateXDrawerFilters: string;
  setTranslateXDrawerFilters: (value: string) => void;
  handleOpenMenus: () => void;
  handleOpenDrawerFilter: () => void;
  handleOpenDrawerCopilot: () => void;
  handleOpenDrawerAdvisor: () => void;
  handleOpenDrawerMentorship: () => void;
  openDrawerScheduledCalls: boolean;
  setOpenDrawerScheduledCalls: (open: boolean) => void;
  handleOpenDrawerScheduledCalls: () => void;
  handleActiveFilter: (
    activeItem: any,
    fetchMessages: (agentType: string) => void,
  ) => void;
  handleActiveFilterCopilot: (
    activeItem: any,
    fetchMessages: (agentType: string) => void,
  ) => void;
  handleActiveAdvisor: (activeItem: any) => void;
  handleActiveMentorship: (activeItem: any) => void;
}

interface DrawerProviderProps {
  children: ReactNode;
}

const DrawerContext = createContext<DrawerContextProps | undefined>(undefined);

export const DrawerProvider: React.FC<DrawerProviderProps> = ({ children }) => {
  const [isOpenMenus, setIsOpenMenus] = useState(false);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [openDrawerFilter, setOpenDrawerFilter] = useState(false);
  const [openDrawerCopilot, setOpenDrawerCopilot] = useState(false);
  const [openDrawerAdvisor, setOpenDrawerAdvisor] = useState(false);
  const [openDrawerMentorship, setOpenDrawerMentorship] = useState(false);
  const [openDrawerScheduledCalls, setOpenDrawerScheduledCalls] =
    useState(false);
  const [activeFilter, setActiveFilter] = useState<any | null>(null);
  const [activePlanFeatures, setActivePlanFeatures] = useState<string[]>([]);
  const [translateXDrawerFilters, setTranslateXDrawerFilters] = useState("0px");

  const handleOpenMenus = () => {
    setIsOpenMenus(!isOpenMenus);
  };

  const handleOpenDrawerFilter = () => {
    setOpenDrawer(true);
    setOpenDrawerFilter(true);
    setOpenDrawerCopilot(false);
    setOpenDrawerAdvisor(false);
    setOpenDrawerMentorship(false);
    setActiveFilter(false);
    setTranslateXDrawerFilters("88px");
  };

  const handleOpenDrawerCopilot = () => {
    setOpenDrawer(true);
    setOpenDrawerFilter(false);
    setOpenDrawerCopilot(true);
    setOpenDrawerAdvisor(false);
    setOpenDrawerMentorship(false);
    setActiveFilter(false);
    setTranslateXDrawerFilters("88px");
  };

  const handleOpenDrawerAdvisor = () => {
    setOpenDrawer(true);
    setOpenDrawerFilter(false);
    setOpenDrawerCopilot(false);
    setOpenDrawerAdvisor(true);
    setOpenDrawerMentorship(false);
    setActiveFilter(false);
    setTranslateXDrawerFilters("88px");
  };

  const handleOpenDrawerMentorship = () => {
    setOpenDrawer(true);
    setOpenDrawerFilter(false);
    setOpenDrawerCopilot(false);
    setOpenDrawerAdvisor(false);
    setOpenDrawerMentorship(true);
    setActiveFilter(false);
    setTranslateXDrawerFilters("88px");
  };

  const handleOpenDrawerScheduledCalls = () => {
    setOpenDrawer(true);
    setOpenDrawerFilter(false);
    setOpenDrawerCopilot(false);
    setOpenDrawerAdvisor(false);
    setOpenDrawerMentorship(false);
    setOpenDrawerScheduledCalls(true);
    setActiveFilter({ name: "Chamadas Agendadas" });
    setTranslateXDrawerFilters("548px");
  };

  const handleActiveFilter = (
    activeItem: any,
    fetchMessages: (agentType: string) => void,
  ) => {
    setActiveFilter(activeItem);
    setTranslateXDrawerFilters("378px");
    if (activeItem.type) {
      fetchMessages(activeItem.type);
    }
  };

  const handleActiveFilterCopilot = (
    activeItem: any,
    fetchMessages: (agentType: string) => void,
  ) => {
    setActiveFilter(activeItem);
    setTranslateXDrawerFilters("470px");
    if (activeItem.type) {
      fetchMessages(activeItem.type);
    }
  };

  const handleActiveAdvisor = (activeItem: any) => {
    setActiveFilter(activeItem);
    setTranslateXDrawerFilters("548px");
  };

  const handleActiveMentorship = (activeItem: any) => {
    setActiveFilter(activeItem);
    setTranslateXDrawerFilters("548px");
  };

  return (
    <DrawerContext.Provider
      value={{
        isOpenMenus,
        setIsOpenMenus,
        openDrawer,
        setOpenDrawer,
        openDrawerFilter,
        setOpenDrawerFilter,
        openDrawerCopilot,
        setOpenDrawerCopilot,
        openDrawerAdvisor,
        openDrawerMentorship,
        setOpenDrawerAdvisor,
        setOpenDrawerMentorship,
        openDrawerScheduledCalls,
        setOpenDrawerScheduledCalls,
        activeFilter,
        setActiveFilter,
        activePlanFeatures,
        setActivePlanFeatures,
        translateXDrawerFilters,
        setTranslateXDrawerFilters,
        handleOpenMenus,
        handleOpenDrawerFilter,
        handleOpenDrawerCopilot,
        handleOpenDrawerAdvisor,
        handleOpenDrawerMentorship,
        handleActiveFilter,
        handleActiveFilterCopilot,
        handleActiveAdvisor,
        handleActiveMentorship,
        handleOpenDrawerScheduledCalls,
      }}
    >
      {children}
    </DrawerContext.Provider>
  );
};

export const useDrawerState = (): DrawerContextProps => {
  const context = useContext(DrawerContext);
  if (context === undefined) {
    throw new Error("useDrawerState must be used within a DrawerProvider");
  }
  return context;
};
