import React, { useEffect, useState } from "react";
import TabsCustom from "components/Tabs";
import Spin from "components/Spin";
import PBIDash from "components/PBIDash";
// import { useReport } from "global/ReportContext";
import { useTab } from "global/TabContext";
import { useDiagnosisFilter } from "global/DiagnosisFilterContext";
import { useCandidates } from "hooks/useCandidates";
import { AtivacaoPresencial } from "./AtivacaoPresencial";
import * as S from "./styles";
import { useUser } from "global/UserContext";

const labels = [
    {
        label: "Ativação ",
        sub_label: "Presencial",
        key: "tab_networking_exp_activation",
        isAdmin: true,
    },
    {
        label: "Ativação ",
        sub_label: "Online",
        key: "tab_networking_exp_online_activation",
        isAdmin: true,
    },
    {
        label: "Registro de ",
        sub_label: "Ativação",
        key: "tab_networking_exp_ativacao",
    }
];

const content: React.ReactNode[] = [
    <PBIDash reportName="rmkr_ativacao_presencial" />,
    <PBIDash reportName="rmkr_ativacao_online" />,
    <AtivacaoPresencial />,
];

export const NetworkingExperience = () => {
    const { permissions } = useUser()
    const [filteredLabels, setFilteredLabels] = useState<any[]>([])
    const [filteredContent, setFilteredContent] = useState<any[]>([])
    const [activeTab, setActiveTab] = React.useState("tab_networking_exp_ativacao");

    useEffect(() => {
        // Filter the labels based on permissions
        const filteredLabels = labels.filter(label =>
            label.isAdmin ? permissions.includes("AdministratorAccess") : true
        );

        // Filter the content based on the indices of the filtered labels
        const filteredContent = filteredLabels.map(label =>
            content[labels.indexOf(label)]
        );

        setFilteredLabels(filteredLabels);
        setFilteredContent(filteredContent);

    }, [permissions])
    const { setActiveTabContext } = useTab();

    const [filterKey, setFilterKey] = useState(Date.now()); //Key for PBIDash

    useEffect(() => {
        setActiveTabContext(activeTab);
    }, [activeTab, setActiveTabContext]);


    const changePoliticalTab = (item: string) => {
        setActiveTab(item);
        setActiveTabContext(item);
    };

    return (
        <S.Wrapper>
            <S.Content>
                <TabsCustom
                    title=""
                    labels={filteredLabels}
                    activeTab={activeTab}
                    content={filteredContent.map((comp, index) =>
                        React.cloneElement(comp as React.ReactElement, {
                            key: `${filterKey}-${index}`,
                        }),
                    )}
                    onChange={(key) => changePoliticalTab(key)}
                />
            </S.Content>
        </S.Wrapper>
    );
};
