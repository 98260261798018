import React from "react";
import { Navigate } from "react-router-dom";
import { useUser } from "global/UserContext";
import { ROUTES } from "utils/constants";

interface PrivateRouteProps {
  children: JSX.Element;
}

export const PrivateRoute: React.FC<PrivateRouteProps> = ({ children }) => {
  const { token } = useUser();

  if (!token) {
    return <Navigate to={ROUTES.AUTH.LOGIN} replace />;
  }

  return children;
};
