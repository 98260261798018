import styled from "styled-components";
import theme from "styles/theme";

export const CarouselWrapper = styled.div`
  width: 100%;
  max-width: 1350px;
  position: relative;
  margin: 20px auto;
  display: flex;
  align-items: center;
`;

export const CarouselContainer = styled.div`
  display: flex;
  gap: 10px;
  width: 100%;
  overflow-x: auto;
  scroll-behavior: smooth;

  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
  &::-webkit-scrollbar {
    display: none; /* Chrome, Safari, Opera */
  }

  cursor: grab;
  &:active {
    cursor: grabbing;
  }
`;

export const ImageCard = styled.div`
  flex: 0 0 240px;
  height: 280px;
  background-color: ${theme.COLORS.BACKGROUND_SECONDARY};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  position: relative;
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

export const StyledButton = styled.div`
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  width: 128px;
  text-align: center;
  opacity: 0.5;
  transition: opacity 0.3s ease-in-out;

  &:hover {
    opacity: 1;
  }

  button {
    width: 100%;
    background-color: ${theme.COLORS.PRIMARY};
    color: ${theme.COLORS.WHITE};
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
`;
