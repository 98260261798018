import styled from "styled-components";
import "styles/global";
import theme from "styles/theme";

export const Wrapper = styled.main`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  color: ${theme.COLORS.GRAY_10};
  margin-bottom: 5rem;
`;

export const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
`;

export const Section = styled.div`
  width: 100%;
  max-width: 1350px;
  padding: 0 1.5rem;
`;

export const Content = styled.div`
  width: 100%;
  max-width: 1350px;
  height: 100%;
  max-height: 100vh;
  overflow-y: auto;
`;

export const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: auto;
  margin-right: 16px;
  margin-top: -30px;
  padding: 16px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
  background-color: ${theme.COLORS.BACKGROUND_SECONDARY};
`;

export const ProfilePictureWrapper = styled.div`
  position: relative;
  text-align: center;
`;

export const ProfilePicture = styled.img`
  width: 150px;
  height: 150px;
  border-radius: 50%;
  object-fit: cover;
`;

export const UserName = styled.h2`
  font-size: 20px;
  margin: 10px 0;
`;

export const IconsContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 10px;
  margin: 10px 0;
`;

export const LevelInfo = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin: 10px 0;
  font-size: 14px;
  color: ${theme.COLORS.PRIMARY};
`;

export const ButtonChangePictureStyle = {
  color: `${theme.COLORS.PRIMARY}`,
  cursor: "pointer",
  textDecoration: "underline",
  fontSize: "12px",
};

export const ButtonLevelStyle = {
  color: `${theme.COLORS.PRIMARY}`,
  borderColor: `${theme.COLORS.PRIMARY}`,
  marginTop: "10px",
  backgroundColor: `${theme.COLORS.BACKGROUND_SECONDARY}`,
  "&:hover": {
    borderColor: `${theme.COLORS.PRIMARY}`,
    backgroundColor: `${theme.COLORS.BACKGROUND_PRIMARY}`,
  },
};

export const ButtonStyle = {
  color: `${theme.COLORS.PRIMARY}`,
  borderColor: `${theme.COLORS.PRIMARY}`,
  backgroundColor: `${theme.COLORS.BACKGROUND_SECONDARY}`,
  marginTop: "10px",
  "&:hover": {
    borderColor: `${theme.COLORS.PRIMARY}`,
    backgroundColor: `${theme.COLORS.BACKGROUND_PRIMARY}`,
  },
};

export const PublishButtonStyle = {
  backgroundColor: `${theme.COLORS.PRIMARY}`,
  color: `${theme.COLORS.BLACK}`,
  marginTop: "10px",
  "&:hover": {
    backgroundColor: `${theme.COLORS.YELLOW_4}`,
  },
};

export const ModalContainer = styled.div`
  width: 300px;
`;

export const FormContainer = styled.div`
  width: 100%;
  background-color: ${theme.COLORS.BACKGROUND_SECONDARY};
  padding: 8px;
  border-radius: 8px;
  margin-top: 16px;

  h2 {
    color: ${theme.COLORS.BACKGROUND_SECONDARY};
  }

  .MuiFormLabel-root {
    color: ${theme.COLORS.GRAY_10};
  }

  .MuiInputBase-root {
    color: ${theme.COLORS.GRAY_10};
  }

  .MuiInputLabel-root {
    color: ${theme.COLORS.GRAY_10};
  }

  .MuiOutlinedInput-root {
    fieldset {
      border-color: ${theme.COLORS.GRAY_10};
      opacity: 0.4;
    }

    &:hover fieldset {
      border-color: ${theme.COLORS.WHITE};
    }

    &.Mui-focused fieldset {
      border-color: ${theme.COLORS.WHITE};
    }
  }
`;
