import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useState, useEffect } from "react";
import * as yup from "yup";
import { apiOrchestrator } from "services/apiService";
import { useToast } from "hooks/useToast";
import { useUser } from "global/UserContext";
import useUpdateUserContext from "hooks/useUpdateUserContext";

const schema = yup.object().shape({
  firstName: yup.string().required("Nome é obrigatório"),
  lastName: yup.string().required("Sobrenome é obrigatório"),
  email: yup.string().email("E-mail inválido").notRequired(),
  whatsapp: yup
    .string()
    .matches(/^\+?[1-9]\d{1,14}$/, "Número inválido")
    .notRequired(),
});

export const usePersonalInfo = () => {
  const { user, setUser } = useUser();
  const { updateUserContext } = useUpdateUserContext();
  const { toastSuccess, toastError } = useToast();
  const [loading, setLoading] = useState(false);

  const {
    control,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      firstName: "",
      lastName: "",
      email: "",
      whatsapp: "",
    },
  });

  const fetchPersonalInfo = async () => {
    setLoading(true);
    const uuid = user?.id;

    if (!uuid) {
      console.error("Usuário não encontrado.");
      setLoading(false);
      return;
    }

    try {
      const { data } = await apiOrchestrator.get(`/users-person/${uuid}`);

      setValue("firstName", data.first_name);
      setValue("lastName", data.last_name);
      setValue("email", data.email);
      setValue("whatsapp", data.whatsapp_number);
    } catch (error) {
      toastError("Erro ao buscar informações pessoais.");
    } finally {
      setLoading(false);
    }
  };

  const updatePersonalInfo = async (data: any) => {
    const uuid = user?.id;

    if (!uuid) {
      console.error("Usuário não encontrado.");
      return;
    }

    const whatsappNumber = data.whatsapp
      ? data.whatsapp.startsWith("+")
        ? data.whatsapp
        : `+${data.whatsapp}`
      : "";

    const payload = {
      first_name: data.firstName,
      last_name: data.lastName,
      email: data.email,
      whatsapp_number: whatsappNumber,
    };

    try {
      const response = await apiOrchestrator.patch(
        `/users-person/${uuid}`,
        payload,
      );

      if (response.status === 200) {
        const updatedUserData = response.data;
        toastSuccess("Informações pessoais atualizadas com sucesso!");

        setUser((prevUser) => ({
          ...prevUser,
          ...updatedUserData,
        }));

        setTimeout(async () => {
          await updateUserContext(uuid, {
            ...user,
            ...updatedUserData,
          });
        }, 1000);
      }
    } catch (error) {
      toastError("Erro ao atualizar as informações.");
    }
  };

  useEffect(() => {
    fetchPersonalInfo();
  }, [user]);

  return { control, handleSubmit, errors, updatePersonalInfo, watch, loading };
};
