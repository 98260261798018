import React from "react";
import * as S from "./styles";
import useSentimentIcon, {
  SentimentType,
} from "componentsNew/CockpitComponents/hooks/useSentimentIcon";

interface ITotalsCount {
  title?: string;
  description?: string;
  value?: any;
  percent?: any;
  typeStatus?: SentimentType;
  icon?: React.ReactNode;
  additionalText?: string;
}

const TotalsCount = ({
  title,
  description,
  value,
  percent,
  typeStatus,
  icon,
  additionalText,
}: ITotalsCount) => {
  const renderIcon = () => {
    return icon || useSentimentIcon(typeStatus, 8);
  };

  return (
    <S.Wrapper withTitle={!!title}>
      {title && <S.TitleCount>{title}</S.TitleCount>}
      {description && (
        <S.Description>
          {description}
          {typeStatus && renderIcon() && (
            <span
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              ({renderIcon()})
            </span>
          )}
        </S.Description>
      )}

      {!title && value !== "" && (
        <>
          <S.TextCount percent={percent} className={typeStatus}>
            {formatNumber(value)}
            {!!percent && (
              <span style={{ fontSize: "16px" }}>
                {roundNumber(percent, 2)}
              </span>
            )}
          </S.TextCount>
        </>
      )}
      {additionalText && <S.AdditionalText>{additionalText}</S.AdditionalText>}
    </S.Wrapper>
  );
};

function formatNumber(number: number) {
  if (number < 1) return "0";
  return number.toLocaleString("pt-BR");
}

function roundNumber(value: number, decimal: number): string {
  if (!value) return "";
  const factor = 10 ** decimal;
  const finalValue = Math.round(value * factor) / factor;
  return `${finalValue}%`;
}

export default TotalsCount;
