import React, { createContext, useContext, useState } from "react";

interface SidebarContextProps {
  open: boolean;
  titleMenu: string | boolean;
  setTitleMenu: React.Dispatch<React.SetStateAction<string | boolean>>;
}

const SidebarContext = createContext<SidebarContextProps | undefined>(
  undefined,
);

export const SidebarProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const open = true;
  const [titleMenu, setTitleMenu] = useState<string | boolean>("");

  return (
    <SidebarContext.Provider value={{ open, titleMenu, setTitleMenu }}>
      {children}
    </SidebarContext.Provider>
  );
};

export const useSideBar = (): SidebarContextProps => {
  const context = useContext(SidebarContext);
  if (!context) {
    throw new Error("useSideBar deve ser usado dentro de um SidebarProvider");
  }
  return context;
};
