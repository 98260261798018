import React from "react";
import { Box, Skeleton } from "@mui/material";
import theme from "styles/theme";

const SkeletonComponent: React.FC = () => {
  return (
    <Box
      sx={{
        width: "100%",
        margin: "0",
        padding: "1rem",
        background: theme.COLORS.BACKGROUND_SECONDARY,
        borderRadius: "8px",
        gap: "0.5rem",
      }}
    >
      <Skeleton animation="wave" sx={{ width: "85%", height: "48px" }} />
      <Skeleton animation="wave" sx={{ width: "70%" }} />
      <Skeleton animation="wave" sx={{ width: "40%" }} />
      <Skeleton animation="wave" sx={{ width: "60%" }} />
      <Skeleton animation="wave" sx={{ width: "40%" }} />
      <Skeleton animation="wave" sx={{ width: "60%" }} />
      <Skeleton animation={false} sx={{ width: "75%" }} />
    </Box>
  );
};

export default SkeletonComponent;
