import { apiOrchestrator } from "services/apiService";

export const fetchGroups = () => apiOrchestrator.get("/team/all");

export const fetchGroupMembers = (groupId: string) =>
  apiOrchestrator.get(`/user_to_team/${groupId}`);

export const createGroup = (data: any) => apiOrchestrator.post("/team", data);

export const getGroupDetails = async (groupId: string) => {
  return await apiOrchestrator.get(`/team/${groupId}`);
};

export const updateGroupDetails = async (
  groupId: string,
  data: { name: string; description: string; organization_uuid: string },
) => {
  return await apiOrchestrator.patch(`/team/${groupId}`, data);
};

export const unlinkUserFromGroup = async (userToTeamUuid: string) => {
  return await apiOrchestrator.delete(`/user_to_team/${userToTeamUuid}`);
};

export const linkUsersToGroup = async (
  groupId: string,
  users: Array<{ user_uuid: string; team_position: string }>,
) => {
  return await apiOrchestrator.post(`/user_to_team/${groupId}`, users);
};

export const fetchOrganizationMembers = async () => {
  return await apiOrchestrator.get("/user_to_organization/members");
};
