import React, { createContext, useContext, useState, ReactNode } from "react";

interface UserPersonContextType {
  userPersonData: any;
  setUserPersonData: (data: any) => void;
}

interface UserPersonProviderProps {
  children: ReactNode;
}

const UserPersonContext = createContext<UserPersonContextType | undefined>(
  undefined,
);

export const UserPersonProvider: React.FC<UserPersonProviderProps> = ({
  children,
}) => {
  const [userPersonData, setUserPersonData] = useState<any>(null);

  return (
    <UserPersonContext.Provider value={{ userPersonData, setUserPersonData }}>
      {children}
    </UserPersonContext.Provider>
  );
};

export const useUserPerson = (): UserPersonContextType => {
  const context = useContext(UserPersonContext);
  if (!context) {
    throw new Error("useUserPerson must be used within a UserPersonProvider");
  }
  return context;
};
