import { ReactComponent as IconAnalystAdvisor } from "assets/advisors/icon-analyst-advisor.svg";

export const mapSidebarAdvisor = [
  {
    name: "Método Sem Limites",
    type: "advisor",
    title: ``,
    icon: IconAnalystAdvisor,
    filter: false,
    path: "",
    disabled: false,
  },
];
