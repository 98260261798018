import React, { useEffect, useState } from "react";
import Spin from "components/Spin";
import { ButtonGroup, Divider, IconButton, Paper, Rating, Stack } from "@mui/material";

import Text from "components/Text";
import { apiNetworking } from "services/apiService";

import { useUser } from "global/UserContext";
import { UserPicture } from "./UserPicture";


import { SvgIconComponent } from "@mui/icons-material";

import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import theme from "styles/theme";



interface MappedMember {
    label: string;
    id: string;
}

interface EdgeTypeTotals {
    CONNECTED?: number;
    SCHEDULED?: number;
    WHATSAPP?: number;
}

interface UserStats {
    ranking: number;
    total_points: number;
    edge_type_totals: EdgeTypeTotals;
    nps: number;
}

interface CommonEdge {
    type_code: string;
}

interface OutputComponentProps {
    selectedUser: MappedMember;
}

export const OutputComponent: React.FC<OutputComponentProps> = ({ selectedUser }) => {
    const { user } = useUser();
    const [loading, setLoading] = useState<boolean>(true);
    const [userData, setUserData] = useState<UserStats | null>(null);
    const [commonEdgeTypes, setCommonEdgeTypes] = useState<string[]>([]);
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        if (selectedUser?.id) {
            fetchData();
        }
    }, [selectedUser?.id]);

    const fetchData = async () => {
        setLoading(true);
        setError(null);

        try {
            const [statsResponse, edgesResponse] = await Promise.all([
                apiNetworking.get(`/v1/stats/user/${selectedUser.id}`),
                apiNetworking.get(`/v1/edge/find?from_user_uuid=${user?.id}&to_user_uuid=${selectedUser.id}`),
            ]);

            setUserData(statsResponse.data);
            setCommonEdgeTypes(edgesResponse.data.map((edge: CommonEdge) => edge.type_code));
        } catch (err) {
            console.error("Error fetching data:", err);
            setError("Failed to load data. Please try again later.");
        } finally {
            setLoading(false);
        }
    };

    if (loading) {
        return <Spin />;
    }

    if (error) {
        return <Paper>{error}</Paper>;
    }

    const hasEdgeType = (type: string) => commonEdgeTypes.includes(type);

    return (
        <Stack gap={2}>
            <UserPicture selectedUser={selectedUser} />

            <Stack direction="row" gap={1} sx={{ justifyContent: "space-between" }}>
                <ButtonEdges
                    ariaLabel="connected"
                    label="Conectar"
                    icon={PersonAddAlt1Icon}
                    active={hasEdgeType("CONNECTED")}
                // onClick={() => handleChange({ target: { name: "CONNECTED", value: "CONNECTED" } } as SelectChangeEvent)}
                />
                <Divider orientation="vertical" sx={{ height: "60px" }} />
                <ButtonEdges
                    ariaLabel="scheduled"
                    label="Agendar"
                    icon={CalendarMonthIcon}
                    active={hasEdgeType("SCHEDULED")}

                // onClick={() => handleChange({ target: { name: "SCHEDULED", value: "SCHEDULED" } } as SelectChangeEvent)}
                />
                <Divider orientation="vertical" sx={{ height: "60px" }} />
                <ButtonEdges
                    ariaLabel="whatsapp"
                    label="WhatsApp"
                    icon={WhatsAppIcon}
                    active={hasEdgeType("WHATSAPP")}

                // onClick={() => handleChange({ target: { name: "WHATSAPP", value: "WHATSAPP" } } as SelectChangeEvent)}
                />
            </Stack>

            <Stack direction="row" gap={1}>
                <KPI title="Ranking" value={`${userData?.ranking}º` || "N/A"} />
                <KPI title="Total Points" value={`${userData?.total_points} pts` || "N/A"} />
            </Stack>

            <Stack direction="row" gap={1}>
                <KPIIcon icon={PersonAddAlt1Icon} value={userData?.edge_type_totals?.CONNECTED ?? "N/A"} />
                <KPIIcon icon={CalendarMonthIcon} value={userData?.edge_type_totals?.SCHEDULED ?? "N/A"} />
                <KPIIcon icon={WhatsAppIcon} value={userData?.edge_type_totals?.WHATSAPP ?? "N/A"} />
            </Stack>
            <Stack direction="row" gap={1} sx={{ alignSelf: "center" }}>
                <Rating name="read-only" value={userData?.nps ?? 0} precision={0.2} size="large" sx={{ fontSize: "3rem" }} readOnly />
                <Text size="xlarge" color="primary">{userData?.nps ?? "Sem avaliações"}</Text>
            </Stack>
        </Stack>
    );
};

interface KPIProps {
    title: string;
    value: string | number;
}

const KPI: React.FC<KPIProps> = ({ title, value }) => {
    return (
        <Paper
            aria-label={`KPI: ${title}`}
            sx={{
                backgroundColor: "#1E1E1E",
                color: "#FFD700",
                borderRadius: 2,
                p: 2,
                textAlign: "left",
                minWidth: "100px",
                boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.3)",
            }}>
            <Text bold>{title}</Text>
            <div style={{ textAlign: "center" }}>
                <Text size="xlarge" color="primary">{value}</Text>
            </div>
        </Paper>
    );
};



interface KPIIconProps {
    icon: SvgIconComponent;
    value: string | number;
}

const KPIIcon: React.FC<KPIIconProps> = ({ icon: Icon, value }) => {
    return (
        <Paper
            sx={{
                backgroundColor: "#1E1E1E",
                color: "#FFD700",
                borderRadius: 2,
                p: 2,
                textAlign: "center",
                minWidth: "100px",
                boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.3)",
            }}>
            <Icon fontSize="large" sx={{ fontSize: 40 }} color="action" />
            <Text size="xlarge" color="primary">{value}</Text>
        </Paper>
    );
};

interface ButtonEdgesProps {
    ariaLabel: string;
    icon: SvgIconComponent; // Icon component type
    label: string;
    onClick?: () => void; // Callback for click event
    active?: boolean;
}
const ButtonEdges: React.FC<ButtonEdgesProps> = ({ ariaLabel, icon: Icon, label, onClick, active }) => {
    const style_ = active ? { color: "#DBB958" } : { color: "gray" }
    return (
        <IconButton aria-label={ariaLabel} onClick={onClick} style={style_}>
            <Stack direction="column" sx={{ alignItems: "center" }}>
                <Icon fontSize="large" sx={{ fontSize: 40 }} />
                <Text size="medium" color={active ? "primary" : "white"}>{label}</Text>
            </Stack>
        </IconButton>
    );
};