import React from "react";
import { Avatar } from "@mui/material";

interface IAvatar {
  img: any;
  size?: any;
}

const Avatars = ({ img, size }: IAvatar) => {
  return (
    <Avatar
      src={img}
      sx={{
        ...size,
      }}
    />
  );
};

export default Avatars;
