import React, { useEffect, useState } from "react";
import { TextField, Box, Alert } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import Text from "components/Text";
import Spin from "components/Spin";
import InternalButton from "components/InternalButton";
import CreateEventModal from "./components/CreateEventModal";
import EventList from "./components/EventList";
import { apiRainmakers } from "services/apiService";
import { Event } from "./types/events";
import theme from "styles/theme";
import * as S from "./styles";

const GovernanceEvents: React.FC = () => {
  const [events, setEvents] = useState<Event[]>([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [loading, setLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);

  const fetchEvents = async () => {
    setLoading(true);
    try {
      const response = await apiRainmakers.get("/events/v1/events/all");
      if (Array.isArray(response.data)) {
        setEvents(response.data);
      } else {
        console.error("Dados inesperados:", response.data);
      }
    } catch (error) {
      console.error("Erro ao buscar eventos:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchEvents();
  }, []);

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
  };

  const filteredEvents = events.filter((event) =>
    event.name.toLowerCase().includes(searchTerm.toLowerCase()),
  );

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        my: 0,
        padding: 0,
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "0.5rem",
        }}
      >
        <Text size="large">Eventos Online ({filteredEvents.length})</Text>
        <Box sx={{ display: "flex", gap: "1rem", flex: "0.4" }}>
          <Box sx={{ display: "flex", flex: "0.7" }}>
            <TextField
              variant="outlined"
              placeholder="Buscar por Eventos"
              value={searchTerm}
              onChange={handleSearchChange}
              size="small"
              fullWidth
              sx={S.SearchService}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              flex: "0.3",
              alignItems: "center",
            }}
          >
            <InternalButton
              onClick={() => setOpenModal(true)}
              size="large"
              background={theme.COLORS.PRIMARY}
              variant="outline"
              iconPosition="left"
              textColor={theme.COLORS.TEXT_PRIMARY}
              icon={<AddIcon sx={{ fontSize: "auto" }} />}
            >
              Criar novo evento
            </InternalButton>
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
          minHeight: "300px",
        }}
      >
        {loading ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              minHeight: "200px",
            }}
          >
            <Spin />
          </Box>
        ) : filteredEvents.length === 0 ? (
          <Alert
            severity="info"
            sx={{
              backgroundColor: theme.COLORS.BACKGROUND_SECONDARY,
              color: theme.COLORS.TEXT_PRIMARY,
              fontSize: "1rem",
              textAlign: "center",
            }}
          >
            {searchTerm
              ? "Nenhum evento encontrado com o termo buscado."
              : "Nenhum evento cadastrado ainda."}
          </Alert>
        ) : (
          <EventList
            events={filteredEvents}
            searchTerm={searchTerm}
            onUpdate={fetchEvents}
            loading={loading}
          />
        )}

        <CreateEventModal
          open={openModal}
          onClose={() => setOpenModal(false)}
          onEventCreated={(eventId) => {}}
          onUpdate={fetchEvents}
        />
      </Box>
    </Box>
  );
};

export default GovernanceEvents;
