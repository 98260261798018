import styled from "styled-components";

interface propsContainer {
  open: boolean;
}

export const Main = styled.div`
  display: flex;
  justify-content: center;
  background-color: ${({ theme }) => theme.COLORS.BACKGROUND_SECONDARY};
`;

export const Container = styled.div`
  width: 100%;
  height: 100vh;
  background: ${({ theme }) => theme.COLORS.BACKGROUND_PRIMARY};
  transition: padding-left 0.5s;
  overflow: hidden;
  max-width: 1366px;
  position: relative;
`;

export const Box = styled.div`
  display: flex;
  flex-direction: row;
`;

export const NewsContainer = styled.div`
  display: flex;
  margin-left: 9rem;
`;

export const UserCardContainer = styled.div`
  width: 20rem;
  display: flex;
  justify-content: start;
  align-items: start;
  margin-left: 9rem;
  height: 100%;
`;

export const Wrapper = styled.div<propsContainer>`
  width: 100%;
  overflow: auto;
  height: calc(100% - 56px);
  padding-left: ${({ open }) => (open ? "18rem" : "0px")};
  padding-top: 16px;
  padding-bottom: 16px;
  background: ${({ theme }) => theme.COLORS.BACKGROUND_PRIMARY};
  display: flex;
  justify-content: center;
  position: relative;
  transition: padding-left 0.3s;
`;

export const WrapperMobile = styled.div<propsContainer>`
  width: 100%;
  overflow: auto;
  height: calc(100% - 56px);
  padding-top: 64px;
  padding-bottom: 16px;
  background: ${({ theme }) => theme.COLORS.BACKGROUND_PRIMARY};
  display: flex;
  justify-content: center;
  position: relative;
  transition: padding-left 0.3s;
  padding: 1rem 0.5rem;
`;

export const Content = styled.div`
  display: flex;
  width: 100%;
  height: max-content;
  background: ${({ theme }) => theme.COLORS.BACKGROUND_PRIMARY};
  padding-left: 1rem;
`;

export const ContentMobile = styled.div<{ isCommunity: boolean }>`
  display: flex;
  width: 100%;
  height: ${({ isCommunity }) => (isCommunity ? "100%" : "max-content")};
  background: ${({ theme }) => theme.COLORS.BACKGROUND_PRIMARY};
  padding: 0.5rem;
  padding-bottom: 2rem;
`;
