import React from "react";
import { Outlet, useLocation } from "react-router-dom";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import { TransitionContainer } from "./RouteTransitionStyles";

const RouteTransition = () => {
  const location = useLocation();

  return (
    <TransitionGroup>
      <CSSTransition key={location.key} timeout={500} classNames="page">
        <TransitionContainer in>
          <Outlet />
        </TransitionContainer>
      </CSSTransition>
    </TransitionGroup>
  );
};

export default RouteTransition;
