import React from "react";
import { IoWarningOutline } from "react-icons/io5";
import theme from "styles/theme";
import * as S from "./styles";

const UnderConstruction = () => {
  return (
    <S.Background>
      <S.Container>
        <S.IconWrapper>
          <IoWarningOutline color={theme.COLORS.YELLOW_1} />
        </S.IconWrapper>
        <S.Title>Página em construção!</S.Title>
        <S.ContentText>
          <S.Description>
            Ops! Esta área ainda está sendo construída. Enquanto isso, dê uma
            olhada na versão para computador.
          </S.Description>
        </S.ContentText>
      </S.Container>
    </S.Background>
  );
};

export default UnderConstruction;
