import { useQuery } from "react-query";
import { useEffect, useRef } from "react";
import { apiAdvisor } from "services/apiService";
import { useUser } from "global/UserContext";
import usePlanType from "hooks/usePlanType";

const fetchMeetingDetails = async (userId: string, isAdvisor: boolean) => {
  let endpoint = "";

  if (!isAdvisor) {
    endpoint = `/v1/meeting/next?id_user=${userId}&page=1&size=50`;
  } else {
    const advisorResponse = await apiAdvisor.get(
      `/v1/advisors/by-user-id/${userId}`,
    );
    const advisorId = advisorResponse.data.id;
    endpoint = `/v1/meeting/next?id_advisor=${advisorId}&page=1&size=50`;
  }

  const response = await apiAdvisor.get(endpoint);
  return response.data.items[0];
};

const useMeetingDetails = () => {
  const { user } = useUser();
  const planType = usePlanType();
  const intervalRef = useRef<NodeJS.Timeout | null>(null);

  const {
    data: meetingDetails,
    isLoading,
    error,
    refetch,
  } = useQuery(
    ["meetingDetails", user?.id, planType.isAdvisor],
    () => fetchMeetingDetails(user!.id, planType.isAdvisor),
    {
      enabled: !!user?.id,
      staleTime: 5 * 60 * 1000,
      refetchOnWindowFocus: false,
    },
  );

  useEffect(() => {
    if (meetingDetails) {
      intervalRef.current = setInterval(() => {
        refetch();
      }, 5000);
    } else if (intervalRef.current) {
      clearInterval(intervalRef.current);
      intervalRef.current = null;
    }

    return () => {
      if (intervalRef.current) clearInterval(intervalRef.current);
    };
  }, [meetingDetails, refetch]);

  return { meetingDetails, isLoading, error, refetch };
};

export default useMeetingDetails;
