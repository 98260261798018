import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Modal, TextField, IconButton, InputAdornment } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { apiOrchestrator } from "services/apiService";
import Button from "components/Button";
import { useToast } from "hooks/useToast";
import { useUser } from "global/UserContext";
import Spin from "components/Spin";
import Text from "components/Text";
import theme from "styles/theme";
import * as S from "./styles";

const passwordSchema = yup.object().shape({
  newPassword: yup
    .string()
    .min(8, "A senha deve ter no mínimo 8 caracteres")
    .required("Senha é obrigatória"),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref("newPassword")], "As senhas devem coincidir")
    .required("Confirmação de senha é obrigatória"),
});

interface ChangePasswordModalProps {
  open: boolean;
  onClose: () => void;
  session: string;
  email: string;
}

const ChangePasswordModal: React.FC<ChangePasswordModalProps> = ({
  open,
  onClose,
  session,
  email,
}) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(passwordSchema),
  });
  const { toastSuccess, toastError } = useToast();
  const { setToken, setUser } = useUser();
  const [loading, setLoading] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const navigate = useNavigate();

  const toggleShowNewPassword = () => setShowNewPassword(!showNewPassword);
  const toggleShowConfirmPassword = () =>
    setShowConfirmPassword(!showConfirmPassword);

  const onSubmit = async (data: { newPassword: string }) => {
    setLoading(true);
    try {
      const response = await apiOrchestrator.post(
        "/auth/respond_to_new_password/",
       
      );

      if (response.data.status === "success") {
        const { access_token, user_id } = response.data;

        setToken(access_token);

        const userResponse = await apiOrchestrator.get(`/auth/me/${user_id}`);

        setUser(userResponse.data);

        toastSuccess("Senha alterada com sucesso!");
        onClose();

        navigate("/dashboard/cockpit");
      } else {
        toastError("Erro ao redefinir senha.");
      }
    } catch (error) {
      toastError("Erro ao enviar nova senha. Tente novamente.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <div
        style={{
          width: "400px",
          backgroundColor: theme.COLORS.BACKGROUND_SECONDARY,
          padding: "2rem",
          borderRadius: "10px",
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
          textAlign: "center",
        }}
      >
        <h2 style={{ color: theme.COLORS.TEXT_PRIMARY }}>REDEFINIR SENHA</h2>

        <S.Content>
          <Text size="xSmall" className="description">
            É necessário redefinir a senha para continuar com o Login.
          </Text>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Controller
              name="newPassword"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Nova Senha"
                  type={showNewPassword ? "text" : "password"}
                  fullWidth
                  error={!!errors.newPassword}
                  helperText={errors.newPassword?.message}
                  InputLabelProps={{
                    style: { color: theme.COLORS.TEXT_PRIMARY },
                  }}
                  InputProps={{
                    style: {
                      color: theme.COLORS.TEXT_PRIMARY,
                      backgroundColor: theme.COLORS.BACKGROUND_SECONDARY,
                    },
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={toggleShowNewPassword}
                          edge="end"
                        >
                          {showNewPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  style={{ marginBottom: "1rem" }}
                />
              )}
            />
            <Controller
              name="confirmPassword"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Confirme a Nova Senha"
                  type={showConfirmPassword ? "text" : "password"}
                  fullWidth
                  error={!!errors.confirmPassword}
                  helperText={errors.confirmPassword?.message}
                  InputLabelProps={{
                    style: { color: theme.COLORS.TEXT_PRIMARY },
                  }}
                  InputProps={{
                    style: {
                      color: theme.COLORS.TEXT_PRIMARY,
                      backgroundColor: theme.COLORS.BACKGROUND_SECONDARY,
                    },
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={toggleShowConfirmPassword}
                          edge="end"
                        >
                          {showConfirmPassword ? (
                            <VisibilityOff />
                          ) : (
                            <Visibility />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  style={{ marginBottom: "1rem" }}
                />
              )}
            />
            <Button
              type="submit"
              variant="primary"
              fullWidth
              disabled={loading}
              style={{
                backgroundColor: theme.COLORS.PRIMARY,
                color: theme.COLORS.BLACK,
                marginTop: "1rem",
                padding: "0.75rem 0",
                fontWeight: "bold",
                fontSize: "1rem",
              }}
            >
              {loading ? <Spin size="small" /> : "Redefinir Senha"}
            </Button>
          </form>
        </S.Content>
      </div>
    </Modal>
  );
};

export default ChangePasswordModal;
