import React, { useState, useRef } from "react";
import Button from "components/Button";
import useImages from "./utils/useImages";
import theme from "styles/theme";
import * as S from "./styles";

const ImageCarousel: React.FC = () => {
  const { images } = useImages();
  const carouselRef = useRef<HTMLDivElement>(null);
  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);

  const preventImageDrag = (e: React.DragEvent) => {
    e.preventDefault();
  };

  const handleStart = (e: React.MouseEvent | React.TouchEvent) => {
    setIsDragging(true);
    const pageX = "touches" in e ? e.touches[0].pageX : e.pageX;
    setStartX(pageX - (carouselRef.current?.offsetLeft || 0));
    setScrollLeft(carouselRef.current?.scrollLeft || 0);
  };

  const handleMove = (e: React.MouseEvent | React.TouchEvent) => {
    if (!isDragging) return;
    e.preventDefault();
    const pageX = "touches" in e ? e.touches[0].pageX : e.pageX;
    const x = pageX - (carouselRef.current?.offsetLeft || 0);
    const walk = (x - startX) * 1.75;
    if (carouselRef.current) {
      carouselRef.current.scrollLeft = scrollLeft - walk;
    }
  };

  const handleEnd = () => {
    setIsDragging(false);
  };

  return (
    <S.CarouselWrapper>
      <S.CarouselContainer
        ref={carouselRef}
        onMouseDown={handleStart}
        onMouseMove={handleMove}
        onMouseUp={handleEnd}
        onMouseLeave={handleEnd}
        onTouchStart={handleStart}
        onTouchMove={handleMove}
        onTouchEnd={handleEnd}
        style={{ cursor: isDragging ? "grabbing" : "grab" }}
      >
        {images.map((image) => (
          <S.ImageCard key={image.id}>
            <img
              src={image.src}
              alt={image.title}
              onDragStart={preventImageDrag}
            />
            {image.link && (
              <S.StyledButton>
                <Button
                  onClick={() => window.open(image.link, "_blank")}
                  style={{
                    color: theme.COLORS.BLACK,
                    borderRadius: "16px",
                    height: "24px",
                    opacity: 0.5,
                  }}
                  onMouseEnter={(e: any) =>
                    (e.currentTarget.style.opacity = "1")
                  }
                  onMouseLeave={(e: any) =>
                    (e.currentTarget.style.opacity = "0.3")
                  }
                >
                  Saiba Mais
                </Button>
              </S.StyledButton>
            )}
          </S.ImageCard>
        ))}
      </S.CarouselContainer>
    </S.CarouselWrapper>
  );
};

export default ImageCarousel;
