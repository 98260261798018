import styled from "styled-components";
import "styles/global";

export const Content = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 16px;

  .description {
    display: flex;
    justify-self: start;
    align-self: start;
    text-align: start;
    margin: 20px 0;
  }
`;
