import React, { useEffect, useState } from "react";
import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material";
import { RiPencilFill } from "react-icons/ri";
import { Button } from "antd";
import Text from "components/Text";
import SkeletonComponent from "../components/SkeletonComponent";
import { useDebounce } from "hooks/useDebounce";
import { useSubmitForm } from "../useSubmitForm";
import { fetchFieldSavedData } from "../helpers/fetchFieldSavedData";
import { useUser } from "global/UserContext";
import { useEditStatus } from "global/EditStatusContext";
import theme from "styles/theme";
import * as I from "components/DynamicForm/types/dynamicFormTypes";
import * as S from "../styles";

interface RadioFieldProps {
  field: I.DropdownField;
}

const RadioField: React.FC<RadioFieldProps> = ({ field }) => {
  const { user } = useUser();
  const { setEditStatus } = useEditStatus();
  const [placeholders, setPlaceholders] = useState<string[]>([]);
  const [radioValue, setRadioValue] = useState<Record<string, string>>({});
  const [otherText, setOtherText] = useState<Record<string, string>>({});
  const [isOtherReadOnly, setIsOtherReadOnly] = useState<
    Record<string, boolean>
  >({});

  const debouncedOtherText = useDebounce(otherText, 500);

  const [prevLoading, setPrevLoading] = useState<boolean>(true);
  const [prevError, setPrevError] = useState<string | null>(null);

  const { submitForm, loading: submissionLoading } = useSubmitForm();
  const user_id = user?.id;

  useEffect(() => {
    const phold = field.extras?.placeholders || [];
    setPlaceholders(phold);
    fetchData();
  }, []);

  const fetchData = async () => {
    const data = await fetchFieldSavedData(field.id, user_id);

    if (data && data.length > 0) {
      const savedSubmission = data[0];

      if (savedSubmission) {
        const savedValues = savedSubmission.values;
        if (Array.isArray(savedValues)) {
          const savedValue = savedValues[0];

          if (savedValue?.option_id && savedValue.value !== undefined) {
            const otherOption = field.options.filter(
              (o) => o.option_label === "Outro",
            );
            if (otherOption && otherOption.length > 0) {
              if (savedValue.option_id === otherOption[0].id) {
                setOtherText({
                  [savedValue.option_id]: savedValue.value || "",
                });
              }
            }
            setRadioValue({ [savedValue.option_id]: savedValue.value });
          }
        }
      }
    }
    setPrevLoading(false);
  };

  const handleRadioChange = async (optionId: string, optionLabel: string) => {
    setEditStatus(true, optionLabel);
    if (optionLabel !== "Outro") {
      setOtherText({});
    }
    setRadioValue({ [optionId]: optionLabel });
    const submissionData = {
      values: [{ option_id: optionId, value: optionLabel }],
    };
    await submitForm(field.id, submissionData);
  };

  const handleOtherTextChange = (optionId: string, value: string) => {
    setEditStatus(true, value);
    setOtherText((prev) => ({ ...prev, [optionId]: value }));
    setRadioValue({ [optionId]: value });
  };

  useEffect(() => {
    const optionId = Object.keys(debouncedOtherText)[0];
    if (optionId) {
      setEditStatus(true, "Edit Other Text");
      const submissionData = {
        values: [{ option_id: optionId, value: debouncedOtherText[optionId] }],
      };
      const submitData = async () => {
        await submitForm(field.id, submissionData);
      };

      submitData();
    }
  }, [debouncedOtherText]);

  const handleOtherChange = (optionId: string, label: string) => {
    setRadioValue({ [optionId]: label });
    setIsOtherReadOnly((prevState) => ({ ...prevState, [field.id]: false }));
  };

  const handleBlur = (optionId: string) => {
    const updatedValue = otherText[optionId];
    setRadioValue({
      [optionId]: updatedValue,
    });
  };

  if (prevLoading) {
    return <SkeletonComponent />;
  }

  if (prevError) {
    return <div>Error loading data</div>;
  }

  return (
    <S.Card key={field.id}>
      <Text size="medium" bold>
        {field.label}
      </Text>
      {field.description}
      <FormControl component="fieldset">
        {field.options.map((option) => {
          const isOtherOption = option.option_label === "Outro";
          return (
            <S.StyledFormControlLabel key={option.id}>
              <RadioGroup>
                <FormControlLabel
                  control={
                    <Radio
                      sx={{
                        color: theme.COLORS.TEXT_PRIMARY,
                        "&.Mui-checked": {
                          color: theme.COLORS.TEXT_PRIMARY,
                        },
                      }}
                      checked={Object.keys(radioValue)[0] === option.id}
                      onChange={() => {
                        if (isOtherOption) {
                          handleOtherChange(option.id, option.option_label);
                        } else {
                          handleRadioChange(option.id, option.option_label);
                        }
                      }}
                    />
                  }
                  label={option.option_label}
                  sx={{
                    alignItems: "flex-start",
                    marginTop: "8px",
                  }}
                />
              </RadioGroup>

              {isOtherOption && !!radioValue[option.id] && (
                <S.Specify>
                  <TextField
                    placeholder="Especifique"
                    value={otherText[option.id] || ""}
                    onChange={(e) =>
                      handleOtherTextChange(option.id, e.target.value)
                    }
                    variant="standard"
                    InputProps={{
                      readOnly: isOtherReadOnly[option.id],
                    }}
                    onBlur={() => handleBlur(option.id)}
                  />

                  {isOtherReadOnly[option.id] && (
                    <Button
                      icon={<RiPencilFill />}
                      type="link"
                      size="small"
                      style={S.ButtonEditStyle}
                      onClick={() =>
                        setIsOtherReadOnly((prevState) => ({
                          ...prevState,
                          [option.id]: false,
                        }))
                      }
                    >
                      Editar
                    </Button>
                  )}
                </S.Specify>
              )}
            </S.StyledFormControlLabel>
          );
        })}
      </FormControl>
    </S.Card>
  );
};

export default RadioField;
