import styled from "styled-components";
import theme from "styles/theme";
import "styles/global";

export const Wrapper = styled.main<{ backgroundImage: string }>`

  width: 100%;
  background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0)),
    url(${(props) => props.backgroundImage});
  background-size: 100% 100%; 
  background-position: center;
  background-repeat: no-repeat;
  height: 85vh;
  display: flex;
  justify-content: center;
  padding: 0 16px;
  color: ${theme.COLORS.GRAY_10};

  @media screen and (max-width: 768px) {
    overflow-x: auto;
    min-width: 600px;
    margin-right: 32px;
  }
`;

export const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  @media screen and (min-width: 1190px) {
    max-width: 1200px;
    min-width: 1024px;
  }
`;

export const ImgContent = styled.div`
  height: 80%;
  display: flex;
  justify-content: end;
  align-items: end;
  float: right;

  @media screen and (max-width: 768px) {
    float: none;
    align-items: center;
    justify-content: center;
  }
`;
