import React from "react";
import { Pagination, PaginationItem } from "@mui/material";
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md";
import theme from "styles/theme";

interface PaginationComponentProps {
  totalPages: number;
  currentPage: number;
  onPageChange: (page: number) => void;
  siblingCount?: number;
  boundaryCount?: number;
  color?: "primary" | "secondary" | "standard" | "custom";
  size?: "small" | "medium" | "large";
  disabled?: boolean;
  hidePrevNext?: boolean;
  customColor?: string;
}

const PaginationComponent: React.FC<PaginationComponentProps> = ({
  totalPages,
  currentPage,
  onPageChange,
  siblingCount = 1,
  boundaryCount = 1,
  color = "primary",
  size = "medium",
  disabled = false,
  hidePrevNext = false,
  customColor,
}) => {
  return (
    <Pagination
      count={totalPages}
      page={currentPage}
      onChange={(_, page) => onPageChange(page)}
      siblingCount={siblingCount}
      boundaryCount={boundaryCount}
      size={size}
      disabled={disabled}
      renderItem={(item) => (
        <PaginationItem
          {...item}
          components={{
            previous: MdKeyboardArrowLeft,
            next: MdKeyboardArrowRight,
          }}
          sx={{
            color: theme.COLORS.TEXT_PRIMARY,
            "&.Mui-selected": {
              color: theme.COLORS.TEXT_PRIMARY,
              backgroundColor: color === "custom" ? customColor : undefined,
            },
          }}
        />
      )}
      hidePrevButton={hidePrevNext}
      hideNextButton={hidePrevNext}
    />
  );
};

export default PaginationComponent;
