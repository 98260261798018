import React from "react";
import { IconButton, Box, SxProps } from "@mui/material";
import theme from "styles/theme";

interface IconActiveProps {
  icon: React.ReactNode;
  active: boolean;
  anyActive: boolean;
  onToggle?: () => void;
  disabled?: boolean;
  size?: "small" | "medium" | "large";
}

const IconActive: React.FC<IconActiveProps> = ({
  icon,
  active,
  anyActive,
  onToggle,
  disabled = false,
  size = "medium",
}) => {
  const dynamicStyles: SxProps = {
    borderRadius: "50%",
    border: `5px solid ${active ? theme.COLORS.PRIMARY : "transparent"}`,
    opacity: anyActive ? (active ? 1 : 0.5) : 1,
    pointerEvents: disabled ? "none" : "auto",
    transition: "all 0.3s ease-in-out",
    backgroundColor: disabled
      ? theme.COLORS.BACKGROUND_SECONDARY
      : "transparent",
    "&:hover": {
      backgroundColor: disabled
        ? theme.COLORS.BACKGROUND_SECONDARY
        : theme.COLORS.PRIMARY,
      svg: {
        color: !disabled ? theme.COLORS.BLACK : undefined,
      },
    },
  };

  return (
    <IconButton
      onClick={onToggle}
      disabled={disabled}
      size={size}
      sx={dynamicStyles}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: size === "small" ? 36 : size === "large" ? 64 : 52,
          height: size === "small" ? 36 : size === "large" ? 64 : 52,
          svg: {
            color: active ? theme.COLORS.GRAY_10 : theme.COLORS.GRAY_10, // Cor padrão do ícone
            transition: "color 0.3s ease-in-out",
          },
        }}
      >
        {icon}
      </Box>
    </IconButton>
  );
};

export default IconActive;
