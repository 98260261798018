import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Checkbox,
  List,
  ListItem,
  ListItemText,
  Collapse,
  IconButton,
  TextField,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import CloseIcon from "@mui/icons-material/Close";
import { apiOrchestrator, apiRainmakers } from "services/apiService";
import Button from "components/Button";
import { useToast } from "hooks/useToast";
import { Group, User } from "../../types/groups";
import theme from "styles/theme";

interface ParticipantTabProps {
  eventId: string;
  onNext: () => void;
  onCancel: () => void;
}

const SearchService = {
  height: "1.5rem",
  "& .MuiInputBase-root": {
    minHeight: "1.5rem",
    padding: "2px",
  },
  "& .MuiInputBase-input": {
    height: "1.5rem",
    padding: "0 8px",
    fontSize: "0.8rem",
  },
};

const ParticipantTab: React.FC<ParticipantTabProps> = ({
  eventId,
  onNext,
  onCancel,
}) => {
  const { toastSuccess, toastError } = useToast();
  const [groups, setGroups] = useState<Group[]>([]);
  const [users, setUsers] = useState<User[]>([]);
  const [selected, setSelected] = useState<any[]>([]);
  const [expandedGroups, setExpandedGroups] = useState<Set<string>>(new Set());
  const [tab, setTab] = useState<"PARTICIPANTS" | "COORDINATORS">(
    "PARTICIPANTS",
  );
  const [searchTerm, setSearchTerm] = useState("");

  const currentRole = tab === "PARTICIPANTS" ? "PARTICIPANT" : "COORDINATOR";
  const otherRole = tab === "PARTICIPANTS" ? "COORDINATOR" : "PARTICIPANT";

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [groupRes, userRes] = await Promise.all([
          apiOrchestrator.get("/team/all"),
          apiOrchestrator.get("/user_to_organization/members"),
        ]);
        setGroups(groupRes.data);
        setUsers(
          userRes.data.map((u: any) => ({
            id: u.user.id,
            first_name: u.user.first_name,
            last_name: u.user.last_name,
          })),
        );
      } catch (error) {
        toastError("Erro ao buscar grupos ou usuários.");
        console.error(error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchSelectedParticipants = async () => {
      try {
        const res = await apiRainmakers.get(
          `/events/v1/participants/${eventId}`,
        );
        setSelected(res.data);
      } catch (error) {
        toastError("Erro ao buscar participantes.");
        console.error(error);
      }
    };

    fetchSelectedParticipants();
  }, [eventId]);

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value.toLowerCase());
  };

  const filteredGroups = groups.filter((group) =>
    group.name.toLowerCase().includes(searchTerm),
  );

  const filteredUsers = users
    .filter((user) =>
      `${user.first_name} ${user.last_name}`.toLowerCase().includes(searchTerm),
    )
    .filter(
      (user) =>
        !selected.some(
          (item) => item.user_uuid === user.id && item.role === otherRole,
        ),
    );

  const toggleGroupExpansion = (groupId: string) => {
    setExpandedGroups((prev) => {
      const newSet = new Set(prev);
      if (newSet.has(groupId)) {
        newSet.delete(groupId);
      } else {
        newSet.add(groupId);
      }
      return newSet;
    });
  };

  const handleToggleGroup = (group: Group) => {
    const role = currentRole;
    const groupMembersNotInOtherRole = group.members.filter(
      (member) =>
        !selected.some(
          (item) => item.user_uuid === member.uuid && item.role === otherRole,
        ),
    );

    const groupSelected = groupMembersNotInOtherRole.every((member) =>
      selected.some(
        (item) => item.user_uuid === member.uuid && item.role === role,
      ),
    );

    if (groupSelected) {
      // Remove members of the group with the current role
      setSelected((prev) =>
        prev.filter(
          (item) =>
            !groupMembersNotInOtherRole.some(
              (member) => member.uuid === item.user_uuid && item.role === role,
            ),
        ),
      );
    } else {
      // Add members of the group with the current role
      const newSelections = groupMembersNotInOtherRole.map((member) => ({
        user_uuid: member.uuid,
        role,
        is_blocked: false,
      }));
      setSelected((prev) => [...prev, ...newSelections]);
    }
  };

  const handleToggleUser = (userId: string) => {
    const role = currentRole;
    const alreadySelected = selected.some(
      (item) => item.user_uuid === userId && item.role === role,
    );

    if (alreadySelected) {
      setSelected((prev) =>
        prev.filter(
          (item) => !(item.user_uuid === userId && item.role === role),
        ),
      );
    } else {
      setSelected((prev) => [
        ...prev.filter(
          (item) => !(item.user_uuid === userId && item.role === otherRole),
        ),
        {
          user_uuid: userId,
          role,
          is_blocked: false,
        },
      ]);
    }
  };

  const handleRemoveGroup = (groupId: string) => {
    const role = currentRole;
    setSelected((prev) =>
      prev.filter(
        (item) =>
          !groups
            .find((group) => group.uuid === groupId)
            ?.members.some(
              (member) => member.uuid === item.user_uuid && item.role === role,
            ),
      ),
    );
  };

  const handleRemoveUser = (userId: string) => {
    const role = currentRole;
    setSelected((prev) =>
      prev.filter((item) => !(item.user_uuid === userId && item.role === role)),
    );
  };

  const handleSave = async () => {
    try {
      const uniqueSelected = Array.from(
        new Map(
          selected.map((item) => [`${item.user_uuid}-${item.role}`, item]),
        ).values(),
      );

      await apiRainmakers.post(
        `/events/v1/participants/${eventId}`,
        uniqueSelected,
      );

      toastSuccess(
        `${tab === "PARTICIPANTS" ? "Participantes" : "Palestrantes"} salvos com sucesso!`,
      );
      onNext();
    } catch (error) {
      toastError("Erro ao salvar Participantes/Palestrantes.");
      console.error(error);
    }
  };

  return (
    <Box>
      <Box display="flex" maxWidth="50%" gap="0" mb="1rem">
        <Button
          size="small"
          variant={tab === "PARTICIPANTS" ? "primary" : "outline"}
          onClick={() => setTab("PARTICIPANTS")}
          fullWidth
          style={{ borderRadius: "8px 0px 0px 8px" }}
        >
          Definir Participantes
        </Button>
        <Button
          size="small"
          variant={tab === "COORDINATORS" ? "primary" : "outline"}
          onClick={() => setTab("COORDINATORS")}
          fullWidth
          style={{ borderRadius: "0px 8px 8px 0px" }}
        >
          Definir Palestrantes
        </Button>
      </Box>

      <Box display="flex" gap="2rem">
        <Box width="50%">
          <Typography variant="subtitle1">
            {tab === "PARTICIPANTS" ? "Grupos Temáticos" : "Usuários"}
          </Typography>
          <Box
            sx={{
              border: `1px solid ${theme.COLORS.GRAY_10}`,
              borderRadius: "8px",
              overflow: "auto",
              height: "300px",
              padding: 0.5,
            }}
          >
            <TextField
              variant="outlined"
              placeholder={`Buscar por ${tab === "PARTICIPANTS" ? "Grupo" : "Usuário"}`}
              value={searchTerm}
              onChange={handleSearchChange}
              size="small"
              fullWidth
              sx={SearchService}
            />

            <List>
              {tab === "PARTICIPANTS"
                ? filteredGroups.map((group) => {
                    const groupMembersNotInOtherRole = group.members.filter(
                      (member) =>
                        !selected.some(
                          (item) =>
                            item.user_uuid === member.uuid &&
                            item.role === otherRole,
                        ),
                    );

                    if (groupMembersNotInOtherRole.length === 0) {
                      // Skip group if all members are in the other role
                      return null;
                    }

                    return (
                      <React.Fragment key={group.uuid}>
                        <ListItem sx={{ padding: 0 }}>
                          <Checkbox
                            checked={groupMembersNotInOtherRole.every(
                              (member) =>
                                selected.some(
                                  (item) =>
                                    item.user_uuid === member.uuid &&
                                    item.role === currentRole,
                                ),
                            )}
                            indeterminate={
                              groupMembersNotInOtherRole.some((member) =>
                                selected.some(
                                  (item) =>
                                    item.user_uuid === member.uuid &&
                                    item.role === currentRole,
                                ),
                              ) &&
                              !groupMembersNotInOtherRole.every((member) =>
                                selected.some(
                                  (item) =>
                                    item.user_uuid === member.uuid &&
                                    item.role === currentRole,
                                ),
                              )
                            }
                            onChange={() => handleToggleGroup(group)}
                            sx={{
                              color: theme.COLORS.PRIMARY,
                              "&.Mui-checked": {
                                color: theme.COLORS.PRIMARY,
                              },
                            }}
                          />
                          <ListItemText primary={group.name} />
                          <IconButton
                            onClick={() => toggleGroupExpansion(group.uuid)}
                          >
                            {expandedGroups.has(group.uuid) ? (
                              <ExpandLessIcon />
                            ) : (
                              <ExpandMoreIcon />
                            )}
                          </IconButton>
                        </ListItem>
                        <Collapse
                          in={expandedGroups.has(group.uuid)}
                          timeout="auto"
                          unmountOnExit
                        >
                          <List sx={{ pl: 0 }}>
                            {groupMembersNotInOtherRole.map((member) => (
                              <ListItem
                                key={member.uuid}
                                dense
                                sx={{ padding: 0, pl: 1 }}
                              >
                                <Checkbox
                                  checked={selected.some(
                                    (item) =>
                                      item.user_uuid === member.uuid &&
                                      item.role === currentRole,
                                  )}
                                  onChange={() => handleToggleUser(member.uuid)}
                                  sx={{
                                    color: theme.COLORS.PRIMARY,
                                    "&.Mui-checked": {
                                      color: theme.COLORS.PRIMARY,
                                    },
                                  }}
                                />
                                <ListItemText
                                  primary={`${member.first_name} ${member.last_name}`}
                                />
                              </ListItem>
                            ))}
                          </List>
                        </Collapse>
                      </React.Fragment>
                    );
                  })
                : filteredUsers.map((user) => (
                    <ListItem key={user.id} dense sx={{ padding: 0 }}>
                      <Checkbox
                        checked={selected.some(
                          (item) =>
                            item.user_uuid === user.id &&
                            item.role === currentRole,
                        )}
                        onChange={() => handleToggleUser(user.id)}
                        sx={{
                          color: theme.COLORS.PRIMARY,
                          "&.Mui-checked": {
                            color: theme.COLORS.PRIMARY,
                          },
                        }}
                      />
                      <ListItemText
                        primary={`${user.first_name} ${user.last_name}`}
                      />
                    </ListItem>
                  ))}
            </List>
          </Box>
        </Box>
        {tab === "PARTICIPANTS" && (
          <Box width="50%">
            <Typography variant="subtitle1">Selecionados</Typography>
            <Box
              sx={{
                border: `1px solid ${theme.COLORS.GRAY_10}`,
                borderRadius: "8px",
                overflow: "auto",
                height: "300px",
              }}
            >
              <List sx={{ marginLeft: 1, marginRight: 1 }}>
                {groups.map((group) => {
                  const groupParticipants = group.members.filter((member) =>
                    selected.some(
                      (item) =>
                        item.user_uuid === member.uuid &&
                        item.role === currentRole,
                    ),
                  );

                  if (groupParticipants.length > 0) {
                    return (
                      <React.Fragment key={group.uuid}>
                        <ListItem
                          sx={{
                            background: theme.COLORS.BACKGROUND_PRIMARY,
                            marginTop: "4px",
                            gap: 1,
                            padding: 0,
                            borderRadius: 1,
                          }}
                        >
                          <Checkbox
                            checked
                            disabled
                            sx={{
                              color: theme.COLORS.PRIMARY,
                              "&.Mui-checked": {
                                color: theme.COLORS.PRIMARY,
                              },
                            }}
                          />
                          <ListItemText primary={group.name} />
                          <IconButton
                            onClick={() => handleRemoveGroup(group.uuid)}
                          >
                            <CloseIcon fontSize="inherit" />
                          </IconButton>
                        </ListItem>
                        <List
                          sx={{
                            background: theme.COLORS.BACKGROUND_PRIMARY,
                            gap: 1,
                            padding: 0,
                          }}
                        >
                          {groupParticipants.map((member) => (
                            <ListItem
                              key={member.uuid}
                              dense
                              sx={{ padding: 0, pl: 1 }}
                            >
                              <Checkbox
                                checked
                                onChange={() => handleToggleUser(member.uuid)}
                                sx={{
                                  color: theme.COLORS.PRIMARY,
                                  "&.Mui-checked": {
                                    color: theme.COLORS.PRIMARY,
                                  },
                                }}
                              />
                              <ListItemText
                                primary={`${member.first_name} ${member.last_name}`}
                              />
                            </ListItem>
                          ))}
                        </List>
                      </React.Fragment>
                    );
                  }

                  return null;
                })}

                {selected
                  .filter(
                    (item) =>
                      item.role === currentRole &&
                      !groups.some((group) =>
                        group.members.some(
                          (member) => member.uuid === item.user_uuid,
                        ),
                      ),
                  )
                  .map((participant) => {
                    const user = users.find(
                      (user) => user.id === participant.user_uuid,
                    );
                    return (
                      <ListItem key={participant.user_uuid} dense>
                        <Checkbox
                          checked
                          onChange={() =>
                            handleRemoveUser(participant.user_uuid)
                          }
                          sx={{
                            color: theme.COLORS.PRIMARY,
                            "&.Mui-checked": {
                              color: theme.COLORS.PRIMARY,
                            },
                          }}
                        />
                        <ListItemText
                          primary={`${user?.first_name} ${user?.last_name}`}
                        />
                        <IconButton
                          onClick={() =>
                            handleRemoveUser(participant.user_uuid)
                          }
                        >
                          <CloseIcon fontSize="inherit" />
                        </IconButton>
                      </ListItem>
                    );
                  })}
              </List>
            </Box>
          </Box>
        )}
      </Box>

      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        mt="1rem"
        gap={2}
      >
        <Button variant="outline" onClick={onCancel} fullWidth>
          Cancelar
        </Button>
        <Button variant="primary" onClick={handleSave} fullWidth>
          Config. Avançadas
        </Button>
      </Box>
    </Box>
  );
};

export default ParticipantTab;
