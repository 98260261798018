import { useState } from "react";
import { apiVox } from "services/apiService";
import { useUser } from "global/UserContext";
import { useEditStatus } from "global/EditStatusContext";

export const useSubmitForm = () => {
  const { user } = useUser();
  const { setEditStatus } = useEditStatus();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [response, setResponse] = useState<any>(null);
  const uuid = user?.id;

  const submitForm = async (field_id: string | null, submissionData: any) => {
    if (!field_id || !uuid) {
      setError("field_id ou uuid ausente.");
      return;
    }

    setLoading(true);
    setError(null);
    try {
      const response = await apiVox.post(
        `/forms/v1/submissions/${field_id}?user_id=${uuid}`,
        submissionData,
      );
      setResponse(response.data);
      setEditStatus(false, "Success submit!");
    } catch (err: any) {
      setError(err.message || "Erro ao enviar o formulário.");
    } finally {
      setLoading(false);
    }
  };

  return { submitForm, loading, error, response };
};
