import React, { useEffect } from "react";
import { ThemeProvider } from "styled-components";
import { BrowserRouter } from "react-router-dom";
import AntProvider from "./styles/stylesProviderAnt";
import ProviderContext from "./context";
import { ThemeProvider as ThemeProviderMUI } from "@mui/material/styles";
import MaterialTheme from "./styles/MaterialTheme";
import GlobalStyles from "./styles/global";
import { AuthRoutes } from "./routes/auth.routes";
import { ToastContainer, Zoom } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import theme from "./styles/theme";

const App = () => {
  useEffect(() => {
    const resizeObserverLoopErr = /ResizeObserver loop limit exceeded/;
    const originalConsoleError = console.error;

    console.error = (...args) => {
      if (args[0] && resizeObserverLoopErr.test(args[0])) {
        // Ignore the error
        return;
      }
      originalConsoleError(...args);
    };
  }, []);

  return (
    <BrowserRouter>
      <ProviderContext>
        <AntProvider>
          <ThemeProviderMUI theme={MaterialTheme}>
            <ThemeProvider theme={theme}>
              <GlobalStyles />
              <AuthRoutes />
            </ThemeProvider>
          </ThemeProviderMUI>
        </AntProvider>
        <ToastContainer theme="dark" transition={Zoom} />
      </ProviderContext>
    </BrowserRouter>
  );
};

export default App;
