import { useState, useEffect } from "react";
import { apiVox } from "services/apiService";
import * as I from "components/DynamicForm/types/dynamicFormTypes"


export const useFetchFields = (form_id: string) => {
  const [fields, setFields] = useState<I.Field[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchFields = async () => {
      setLoading(true);
      try {
        const response = await apiVox.get(`/forms/v1/fields/${form_id}`);
        const fieldsData: I.Field[] = response.data;

        const dropdownAndCheckboxFields = fieldsData.filter(
          (field) =>
            field.field_type === "dropdown" || field.field_type === "checkbox",
        ) as (I.DropdownField | I.CheckboxField)[];

        const optionsPromises = dropdownAndCheckboxFields.map((field) =>
          apiVox.get(`/forms/v1/fields/${field.id}/options`),
        );

        const optionsResponses = await Promise.all(optionsPromises);

        const fieldsWithOptions = fieldsData.map((field, index) => {
          if (
            field.field_type === "dropdown" ||
            field.field_type === "checkbox"
          ) {
            const options = optionsResponses[index]?.data || [];
            return { ...field, options } as I.DropdownField | I.CheckboxField;
          }
          return field;
        });

        setFields(fieldsWithOptions);
      } catch (err: any) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchFields();
  }, [form_id]);

  return { fields, loading, error };
};
