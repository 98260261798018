import { useState, useEffect } from "react";
import { apiOrchestrator } from "services/apiService";
import { useUser } from "global/UserContext";

interface SocialNetwork {
  id: number;
  social_network_type: string;
  social_network_username: string;
}

export const useUserSocialNetworks = () => {
  const { user } = useUser();
  const [socialNetworks, setSocialNetworks] = useState<SocialNetwork[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchSocialNetworks = async () => {
      if (!user) return;

      setLoading(true);
      try {
        const { data } = await apiOrchestrator.get(
          `/user-social-networks/?user_id=${user.id}`,
        );
        setSocialNetworks(data);
      } catch (err) {
        setError("Erro ao carregar redes sociais.");
      } finally {
        setLoading(false);
      }
    };

    fetchSocialNetworks();
  }, [user]);

  return { socialNetworks, loading, error };
};
