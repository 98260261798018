import { useState, useEffect } from "react";
import { fetchMeetingRooms } from "utils/meetingUtils";
import { MeetingRoom } from "types/meetingTypes";
import { useUser } from "global/UserContext";

export const useMeetingRooms = () => {
  const [meetingRooms, setMeetingRooms] = useState<MeetingRoom[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const { user } = useUser();

  useEffect(() => {
    const loadMeetingRooms = async () => {
      if (user?.user_to_organization[0]?.organization.uuid && user?.id) {
        try {
          setLoading(true);
          const organizationId = user.user_to_organization[0].organization.uuid;
          const userId = user.id;
          const rooms = await fetchMeetingRooms(organizationId, userId);
          setMeetingRooms(rooms);
        } catch (error) {
          console.error(error);
        } finally {
          setLoading(false);
        }
      }
    };

    loadMeetingRooms();
  }, [user]);

  return { meetingRooms, loading };
};
