import styled from "styled-components";

export const SidebarContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.COLORS.BACKGROUND_SECONDARY};
  width: 250px;
  height: 100vh;
`;

export const MenuItem = styled.div<{ isActive: boolean }>`
  padding: 16px 32px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: ${({ isActive, theme }) =>
    isActive ? theme.COLORS.BACKGROUND_SECONDARY : theme.COLORS.GRAY_80};
  color: ${({ isActive, theme }) =>
    isActive ? theme.COLORS.TEXT_PRIMARY : theme.COLORS.GRAY_40};
  cursor: pointer;
  border-bottom: 1px solid ${({ theme }) => theme.COLORS.GRAY_60};

  img {
    margin-right: 10px;
  }
`;

export const MenuIconLabel = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const DrawerContent = styled.div<{ isMobile: boolean }>`
  width: ${({ isMobile }) => (isMobile ? "100%" : "300px")};
  background-color: ${({ theme }) => theme.COLORS.BACKGROUND_SECONDARY};
  height: 100%;
  display: flex;
  flex-direction: column;
  top: "72px";
`;

export const SubMenuContainer = styled.div`
  padding-left: 32px;
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.COLORS.BACKGROUND_SECONDARY};
`;

export const SubMenuItem = styled.div<{ isActive: boolean }>`
  padding: 16px 36px;
  display: flex;
  align-items: center;
  background-color: ${({ isActive, theme }) =>
    isActive ? theme.COLORS.GRAY_60 : theme.COLORS.GRAY_80};
  color: ${({ isActive, theme }) =>
    isActive ? theme.COLORS.TEXT_PRIMARY : theme.COLORS.GRAY_40};
  cursor: pointer;

  img {
    margin-right: 10px;
  }
`;
