import styled from "styled-components";
import theme from "styles/theme";

interface GridContainerProps {
  isTagsLayout?: boolean;
}

export const ContainerForm = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-color: ${theme.COLORS.BACKGROUND_PRIMARY};
  color: ${theme.COLORS.TEXT_PRIMARY};
`;

export const GridContainer = styled.div<GridContainerProps>`
  display: grid;
  grid-template-columns: ${({ isTagsLayout }) =>
    isTagsLayout ? "1fr" : "repeat(auto-fit, minmax(25rem, 1fr))"};
  gap: 1rem;
  width: 100%;

  @media (max-width: 1024px) {
    grid-template-columns: 1fr;
  }

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    gap: 0.5rem;
  }
`;

export const Card = styled.div`
  background-color: ${theme.COLORS.BACKGROUND_SECONDARY};
  padding: 8px;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  color: ${theme.COLORS.TEXT_PRIMARY};
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 8px;

  .edit-button {
    transition:
      transform 0.3s ease,
      box-shadow 0.3s ease;
    box-shadow: none;
  }

  .textfield:hover + div .edit-button {
    transform: scale(1.1);
    box-shadow: 0 0 8px ${theme.COLORS.PRIMARY};
  }

  @keyframes pulse {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.2);
    }
    100% {
      transform: scale(1);
    }
  }

  @media (max-width: 768px) {
    padding: 12px;
  }
`;

export const GroupRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  gap: 16px;

  @media (max-width: 1024px) {
    flex-direction: column;
    gap: 12px;
  }

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 8px;
  }
`;

export const CardGroup = styled.div`
  display: flex;
  flex-direction: row;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  color: ${theme.COLORS.TEXT_PRIMARY};
  width: 100%;
  margin-bottom: 16px;
  background-color: ${theme.COLORS.BACKGROUND_SECONDARY};

  @media (max-width: 1024px) {
    flex-direction: column;
    gap: 12px;
  }

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 8px;
  }
`;

export const CardTags = styled.div`
  background-color: ${theme.COLORS.BACKGROUND_SECONDARY};
  padding: 16px;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  color: ${theme.COLORS.TEXT_PRIMARY};
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0;
  flex-wrap: wrap;

  @media (max-width: 1024px) {
    padding: 12px;
  }

  @media (max-width: 768px) {
    padding: 8px;
  }
`;

export const StyledFormControlLabel = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  font-size: 10px;

  .MuiCheckbox-root,
  .MuiRadio-root {
    padding: 0px 4px 0px 0px;
  }

  label {
    font-size: 10px;
    margin-left: 4px;
  }
`;

export const FormGroupContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
`;

export const Specify = styled.div`
  display: flex;
  align-items: end;
  justify-content: start;
`;

export const Tag = styled.div`
  background-color: ${theme.COLORS.BACKGROUND_SECONDARY};
  color: ${theme.COLORS.TEXT_PRIMARY};
  padding: 6px 12px;
  border-radius: 20px;
  display: inline-flex;
  align-items: center;
  margin: 4px;
`;

export const TagsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  gap: 8px;
  margin: 12px 0;

  @media (max-width: 1024px) {
    flex-direction: column;
    align-items: flex-start;
  }

  @media (max-width: 768px) {
    gap: 6px;
    margin: 8px 0;
  }
`;

export const Tags = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  border-radius: 32px;
  color: ${theme.COLORS.BACKGROUND_PRIMARY};
  background-color: ${theme.COLORS.PRIMARY};
  margin-bottom: 4px;
`;

export const TextFieldStyle = {
  marginBottom: "8px",
  width: "100%",
};

export const ButtonPlusStyle = {
  alignItems: "center",
  justifyContent: "start",
  fontSize: "0.8rem",
  borderRadius: "32px",
  color: `${theme.COLORS.BLACK_5}`,
  backgroundColor: `${theme.COLORS.WHITE_3}`,
  height: "1.25rem",
  "&:hover": {
    borderColor: `${theme.COLORS.PRIMARY}`,
    backgroundColor: `${theme.COLORS.PRIMARY}`,
    opacity: 0.7,
  },

  "@media (max-width: 768px)": {
    width: "100%",
  },
};

export const ButtonEditStyle = {
  fontSize: "0.85rem",
  maxWidth: "75px",
  borderRadius: "32px",
  marginTop: "4px",
  color: `${theme.COLORS.WHITE_3}`,
  backgroundColor: `${theme.COLORS.BACKGROUND_SECONDARY}`,
  "&:hover": {
    borderColor: `${theme.COLORS.PRIMARY}`,
    backgroundColor: `${theme.COLORS.BACKGROUND_PRIMARY}`,
    opacity: 0.6,
  },
};

export const TagsChipsContainer = styled.div`
  .MuiChip-root {
    background-color: ${theme.COLORS.WHITE_3};
    color: ${theme.COLORS.BLACK_1};
    height: 21px;

    .MuiChip-deleteIcon {
      color: ${theme.COLORS.BLACK_1};
      padding: 5px;

      &:hover {
        color: ${theme.COLORS.BLACK_1};
        opacity: 0.5;
      }
    }
  }

  @media (max-width: 768px) {
    .MuiChip-root {
      width: 100%;
      justify-content: space-between;
    }
  }
`;
