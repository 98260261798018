import { useEffect, useState } from "react";
import { differenceInSeconds, isAfter, isBefore, parseISO } from "date-fns";

const useActiveMeetingTimer = (meetingDetails: any) => {
  const [remainingTime, setRemainingTime] = useState("");
  const [isMeetingActive, setIsMeetingActive] = useState(false);

  const convertToLocalTime = (utcDate: string) => {
    const date = parseISO(utcDate);
    const offset = date.getTimezoneOffset();
    return new Date(date.getTime() - offset * 60 * 1000);
  };

  useEffect(() => {
    if (!meetingDetails) {
      setRemainingTime("");
      setIsMeetingActive(false);
      return;
    }

    const startTime = convertToLocalTime(meetingDetails.start_at);
    const finishTime = convertToLocalTime(meetingDetails.finish_at);

    const updateTimer = () => {
      const now = new Date();
      const isActive = isAfter(now, startTime) && isBefore(now, finishTime);

      setIsMeetingActive(isActive);

      if (isActive) {
        const remainingSeconds = differenceInSeconds(finishTime, now);

        const minutes = Math.floor(remainingSeconds / 60);
        const seconds = remainingSeconds % 60;

        setRemainingTime(
          `${String(minutes).padStart(2, "0")}:${String(seconds).padStart(2, "0")}`,
        );
      } else {
        setRemainingTime("");
      }
    };

    updateTimer();
    const interval = setInterval(updateTimer, 1000);

    return () => clearInterval(interval);
  }, [meetingDetails]);

  return { remainingTime, isMeetingActive };
};

export default useActiveMeetingTimer;
