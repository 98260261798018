import React, { useEffect, useState } from "react";
import SponsorImage from "assets/images/rooms/sponsorship-algoricca.png";
import MeetingRoom from "pages/MeetingRoom";
import TabsCustom from "components/Tabs";
import { Image } from "antd";
import { useTab } from "global/TabContext";
import { useDiagnosisFilter } from "global/DiagnosisFilterContext";
import { useMeetingRooms } from "./hooks/useMeetingRooms";
import { backgroundImages } from "./utils/meetingImages";
import { generateLabels } from "./utils/meetingLabels";
import Spin from "components/Spin";
import * as S from "./styles";

export const ActivationRounds = () => {
  const { meetingRooms, loading } = useMeetingRooms();
  const [activeTab, setActiveTab] = useState<string>("clubPlenaryRoom");
  const { setActiveTabContext } = useTab();
  const { filters } = useDiagnosisFilter();
  const [filterKey, setFilterKey] = useState(Date.now());

  const filteredMeetingRooms = meetingRooms.slice(7, 14); // Mudar para (7, 14)

  useEffect(() => {
    setActiveTabContext(activeTab);
  }, [activeTab, setActiveTabContext]);

  useEffect(() => {
    setFilterKey(Date.now());
  }, [filters]);

  if (loading) {
    return <Spin fullscreen />;
  }

  const changePoliticalTab = (item: string) => {
    setActiveTab(item);
    setActiveTabContext(item);
  };

  const backgroundImage =
    backgroundImages[activeTab as keyof typeof backgroundImages] || "";

  const labels = generateLabels(filteredMeetingRooms);

  const imageSponsorship =
    activeTab === "strategies" || activeTab === "clubEspace6";

  return (
    <S.Wrapper backgroundImage={backgroundImage}>
      <S.Content>
        <TabsCustom
          title=""
          labels={labels}
          activeTab={activeTab}
          content={filteredMeetingRooms.map((room, index) => (
            <MeetingRoom
              key={`${filterKey}-${room.room_data.id}`}
              roomData={room.room_data}
              participantStatus={room.participation_status}
              isActivationRounds={true}
            />
          ))}
          onChange={(key) => changePoliticalTab(key)}
        />
        {imageSponsorship && (
          <S.ImgContent>
            <Image
              width={350}
              src={SponsorImage}
              style={{ marginBottom: "1rem" }}
            />
          </S.ImgContent>
        )}
      </S.Content>
    </S.Wrapper>
  );
};
