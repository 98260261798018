import React, { useState, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import { AnimatePresence } from "framer-motion";
import { GiHamburgerMenu } from "react-icons/gi";
import { BsFillSuitcaseLgFill } from "react-icons/bs";
import {
  IconCommitteeYellow,
  IconCommittee,
  IconFilter,
  IconCoin,
  IconHelpDeskAdvisor,
} from "utils/utilsIcons";
import Text from "components/Text";
import Cockpit from "components/Helper/Cockpit";
import { UnderConstruction } from "components/Helper/UnderConstruction";
import IframeContainer from "microfrontends/components/IframeContainer";
import ScheduledAdvisorCalls from "microfrontends/Advisors/ScheduledAdvisorCalls";
import TimerDisplay from "microfrontends/components/TimeDisplay";
import useMeetingDetails from "microfrontends/hooks/useMeetingDetails";
import useActiveMeetingTimer from "microfrontends/hooks/useActiveMeetingTimer";
import ScheduledCalls from "microfrontends/Advisors/ScheduledCalls";
import { useDrawerState } from "global/DrawerContext";
import { useTab } from "global/TabContext";
import usePlanType from "hooks/usePlanType";
import useOnClickOutside from "components/Helper/utils/useOnClickOutsideFilter";
import { TabContextKeys } from "./mappings/activeTabContext";
import { mapSidebarAdvisor } from "./mappings/sidebarAdvisor";
import { mapSidebarMentorship } from "./mappings/sidebarMentorship";
import { enableCopilot } from "./mappings/tabsEnables";
import { getMapSidebarItems } from "./mappings/sidebarItems";
import theme from "styles/theme";
import * as S from "./styles";

interface IFilter {
  onClick: () => void;
  open: boolean;
  currentPath: string;
  onActiveFilterChange: (activeItem: any) => void;
  payloadData?: any;
}

const Filter = ({
  onClick,
  open,
  currentPath = "",
  onActiveFilterChange,
  onTranslateXChange,
  payloadData,
}: IFilter & { onTranslateXChange: (value: string) => void }) => {
  const location = useLocation();
  const { planType, isAdvisor } = usePlanType();
  const { activeTabContext } = useTab() as {
    activeTabContext: keyof typeof enableCopilot;
  };
  const {
    isOpenMenus,
    setIsOpenMenus,
    openDrawer,
    setOpenDrawer,
    openDrawerFilter,
    setOpenDrawerFilter,
    setOpenDrawerCopilot,
    openDrawerAdvisor,
    openDrawerMentorship,
    setOpenDrawerAdvisor,
    openDrawerScheduledCalls,
    activeFilter,
    setActiveFilter,
    translateXDrawerFilters,
    setTranslateXDrawerFilters,
    handleOpenMenus,
    handleOpenDrawerAdvisor,
    handleOpenDrawerMentorship,
    handleActiveFilter,
    handleActiveAdvisor,
    handleActiveMentorship,
    handleOpenDrawerScheduledCalls,
  } = useDrawerState();

  const menuRef = useRef(null);
  const drawerRef = useRef(null);
  const [disabledButtons, setDisabledButtons] = useState({
    advisor: false,
    dataCuration: false,
    virtualTeam: false,
  });

  const mapSidebarItems = getMapSidebarItems(
    activeTabContext as TabContextKeys,
    location.pathname,
  );

  useOnClickOutside(
    drawerRef,
    () => {
      setIsOpenMenus(false);
      setOpenDrawer(false);
      setOpenDrawerFilter(false);
      setOpenDrawerCopilot(false);
      setOpenDrawerAdvisor(false);
      setActiveFilter(null);
      setTranslateXDrawerFilters("0px");
      onTranslateXChange("0px");
    },
    openDrawer,
    openDrawerFilter,
  );

  const handleMenuClick = (action: () => void) => {
    action();
    handleOpenMenus();
  };

  const {
    meetingDetails,
    isLoading: isLoadingMeetingsDetails,
    refetch,
  } = useMeetingDetails();
  const { remainingTime, isMeetingActive } =
    useActiveMeetingTimer(meetingDetails);

  const handleInteraction = () => {
    refetch();
  };

  useEffect(() => {
    if (openDrawer) {
      refetch();
    }
  }, [openDrawer, refetch]);

  return (
    <>
      <S.Timer
        ref={drawerRef}
        pixelsDrawer={translateXDrawerFilters}
        drawerOpen={openDrawer}
      >
        {isMeetingActive ? (
          <TimerDisplay
            time={remainingTime}
            onClick={() => {
              handleOpenDrawerScheduledCalls();
            }}
          />
        ) : (
          <TimerDisplay time={null} />
        )}
      </S.Timer>

      <S.Coin
        ref={drawerRef}
        pixelsDrawer={translateXDrawerFilters}
        drawerOpen={openDrawer}
      >
        <IconCoin />
      </S.Coin>

      <S.FilterButton
        ref={drawerRef}
        onClick={() => {
          handleOpenMenus();
          handleInteraction();
        }}
        pixelsDrawer={translateXDrawerFilters}
        drawerOpen={openDrawer}
      >
        <GiHamburgerMenu size="32px" color={theme.COLORS.WHITE_3} />
        <S.FilterTitle>Menu</S.FilterTitle>
      </S.FilterButton>

      <AnimatePresence>
        {isOpenMenus && !openDrawer && (
          <S.MenuContainer
            ref={menuRef}
            initial={{ opacity: 0, rotateX: -90 }}
            animate={{ opacity: 1, rotateX: 0 }}
            exit={{ opacity: 0, rotateX: -90 }}
            transition={{ duration: 0.5 }}
          >
            <S.MenuItem
              onClick={() => handleOpenDrawerScheduledCalls()}
              initial={{ opacity: 0, rotateX: -90 }}
              animate={{ opacity: 1, rotateX: 0 }}
              exit={{ opacity: 0, rotateX: -90 }}
              transition={{ duration: 0.5, delay: 0.3 }}
            >
              <S.FilterButtonCopilot
                drawerOpen={openDrawer}
                pixelsDrawer={translateXDrawerFilters}
                disabled={disabledButtons.dataCuration}
                className="z-index"
              >
                <IconHelpDeskAdvisor />
                <S.FilterTitleCopilot disabled={disabledButtons.dataCuration}>
                  Chamadas Agendadas
                </S.FilterTitleCopilot>
              </S.FilterButtonCopilot>
            </S.MenuItem>

            <S.MenuItem
              onClick={() => handleMenuClick(handleOpenDrawerAdvisor)}
              initial={{ opacity: 0, rotateX: -90 }}
              animate={{ opacity: 1, rotateX: 0 }}
              exit={{ opacity: 0, rotateX: -90 }}
              transition={{ duration: 0.5, delay: 0.1 }}
            >
              <S.FilterButtonCopilotCenter
                drawerOpen={openDrawer}
                className="z-index"
                isForward
                pixelsDrawer={translateXDrawerFilters}
                disabled={disabledButtons.advisor}
              >
                <IconCommittee />
                <S.FilterTitleCopilot disabled={disabledButtons.advisor}>
                  Comitê
                </S.FilterTitleCopilot>
              </S.FilterButtonCopilotCenter>
            </S.MenuItem>
            <S.MenuItem
              onClick={() => handleMenuClick(handleOpenDrawerMentorship)}
              initial={{ opacity: 0, rotateX: -90 }}
              animate={{ opacity: 1, rotateX: 0 }}
              exit={{ opacity: 0, rotateX: -90 }}
              transition={{ duration: 0.5, delay: 0.1 }}
            >
              <S.FilterButtonCopilot
                drawerOpen={openDrawer}
                className="z-index"
                pixelsDrawer={translateXDrawerFilters}
                disabled={disabledButtons.advisor}
              >
                <BsFillSuitcaseLgFill color={theme.COLORS.GRAY_10} size={24} />
                <S.FilterTitleCopilot disabled={disabledButtons.advisor}>
                  Mentoria
                </S.FilterTitleCopilot>
              </S.FilterButtonCopilot>
            </S.MenuItem>
          </S.MenuContainer>
        )}
      </AnimatePresence>

      {openDrawer && (
        <S.DrawerMain ref={drawerRef}>
          {openDrawerAdvisor && (
            <S.HeaderMenuAdvisor>
              <IconCommitteeYellow /> Comitês
            </S.HeaderMenuAdvisor>
          )}
          {openDrawerMentorship && (
            <S.HeaderMenuAdvisor>
              <BsFillSuitcaseLgFill color={theme.COLORS.PRIMARY} /> Mentorias
            </S.HeaderMenuAdvisor>
          )}
          <S.DrawerContainer>
            <S.SidebarContainer>
              {openDrawer &&
                openDrawerFilter &&
                mapSidebarItems.map((item) => (
                  <S.SidebarButton
                    key={item.name}
                    active={activeFilter?.name === item.name}
                    onClick={() => handleActiveFilter(item, () => null)}
                    disabled={
                      (!!item.path && item.path !== currentPath) ||
                      !item.enabled
                    }
                  >
                    <S.IconContainer>
                      <item.icon color="white" />
                      {item.filter && (
                        <IconFilter
                          width="10px"
                          height="10px"
                          color={theme.COLORS.PRIMARY}
                        />
                      )}
                    </S.IconContainer>
                    <S.ButtonTitle>{item.name}</S.ButtonTitle>
                  </S.SidebarButton>
                ))}

              {openDrawer &&
                openDrawerAdvisor &&
                mapSidebarAdvisor.map((item: any) => (
                  <S.SidebarButtonCopilot
                    key={item.name}
                    active={activeFilter?.name === item.name}
                    onClick={() => handleActiveAdvisor(item)}
                    disabled={item.disabled}
                  >
                    <S.IconContainer>
                      <item.icon color="white" />
                    </S.IconContainer>
                    <S.ButtonTitle>{item.name}</S.ButtonTitle>
                  </S.SidebarButtonCopilot>
                ))}

              {openDrawer &&
                openDrawerMentorship &&
                mapSidebarMentorship.map((item: any) => (
                  <S.SidebarButtonCopilot
                    key={item.name}
                    active={activeFilter?.name === item.name}
                    onClick={() => handleActiveMentorship(item)}
                    disabled={item.disabled}
                  >
                    <S.IconContainer>
                      <item.icon color="white" />
                    </S.IconContainer>
                    <S.ButtonTitle>{item.name}</S.ButtonTitle>
                  </S.SidebarButtonCopilot>
                ))}
            </S.SidebarContainer>
          </S.DrawerContainer>

          <AnimatePresence>
            {activeFilter && openDrawer && openDrawerFilter && (
              <S.ContentFilter>
                <S.BoxContent
                  key={activeFilter.name}
                  initial={{ opacity: 0, y: 50 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: -50 }}
                  transition={{ duration: 0.5 }}
                >
                  <S.Heading>{activeFilter?.name}</S.Heading>
                  {activeFilter?.name === "Redes Sociais Cockpit" && (
                    <Cockpit />
                  )}
                  {activeFilter?.name === "Wizard Dados" && (
                    <UnderConstruction />
                  )}
                  {activeFilter?.name === "Bloco de notas" && (
                    <UnderConstruction />
                  )}
                  {activeFilter?.name === "Onboarding" && <UnderConstruction />}
                </S.BoxContent>
              </S.ContentFilter>
            )}

            {activeFilter && openDrawer && openDrawerAdvisor && (
              <S.ContentAdvisor>
                <S.BoxContent>
                  <Text size="medium" color="yellow" bold>
                    {activeFilter.name}
                  </Text>
                  {activeFilter.name === "Método Sem Limites" && (
                    <IframeContainer specialty="limitless-method" />
                  )}
                </S.BoxContent>
              </S.ContentAdvisor>
            )}

            {activeFilter && openDrawer && openDrawerMentorship && (
              <S.ContentAdvisor>
                <S.BoxContent>
                  <Text size="medium" color="yellow" bold>
                    {activeFilter.name}
                  </Text>
                  {activeFilter.name === "Mentoria" && (
                    <IframeContainer specialty="mentorship" />
                  )}
                </S.BoxContent>
              </S.ContentAdvisor>
            )}

            {activeFilter && openDrawer && openDrawerScheduledCalls && (
              <S.ContentAdvisor>
                <S.BoxContent>
                  <Text size="medium" color="yellow" bold>
                    {activeFilter.name}
                  </Text>
                  {activeFilter.name === "Chamadas Agendadas" &&
                    (isAdvisor ? (
                      <ScheduledAdvisorCalls />
                    ) : (
                      <ScheduledCalls />
                    ))}
                </S.BoxContent>
              </S.ContentAdvisor>
            )}
          </AnimatePresence>
        </S.DrawerMain>
      )}
    </>
  );
};

export default Filter;
