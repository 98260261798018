import { MeetingRoom } from "pages/MeetingRoom/types/meetingTypes";
import { Feature, UserPermissions } from "types/userPlanFeaturesTypes";
import { ROUTES, FEATURE_TO_ROUTE_MAP } from "utils/constants";
import * as Icon from "utils/utilsIcons";

export interface SidebarMenuItem {
  code: string;
  title: string;
  label: string;
  path: string;
  icon: string;
  isActive: boolean;
  isMobile: boolean;
  rank?: number;
  subtitle?: string;
  subMenu?: SidebarMenuItem[];
  data?: any;
  participantStatus?: any;
}

const iconMapping: Record<string, string> = {
  Cockpit: Icon.IconCockpit,
  GestaoEmpresarial: Icon.IconBusinessManagement,
  GestaoFinanceira: Icon.IconFinancialManagement,
  GestaoComercial: Icon.IconCommercialManagement,
  MerketingDigital: Icon.IconDigitalMarketing,
  Comunidade: Icon.IconCommunity,
  ClubeDashboards: Icon.IconClub,
  Educacao: Icon.IconEducation,
  PerfilComunidade: Icon.IconProfileCommunity,
  InteligenciaNetowrking: Icon.IconIntelligenceNetworking,
  GruposTematicos: Icon.IconThematicGroups,
  OrquestracaoComunidade: Icon.IconCommunityOrchestration,
  GovernancaDigital: Icon.IconDigitalGovernance,
  PerfilAvaliacoes: Icon.IconMemberJourney,
  RodadasAtivacao: Icon.IconActivationRounds,
  PerfilPrimeCLub: Icon.IconProfileCommunity,
  InteligenciaConexoes: Icon.IconIntelligenceNetworking,
  AtivacaoConexoes: Icon.IconActivationConnections,
  OrquestracaoRede: Icon.IconOrchestration,
  NetworkingPratica: Icon.IconNetworkingInPractice,
  Perfil: Icon.IconGroupProfile,
  CuradoriaDados: ROUTES.NOT_FOUND,
  Advisors: ROUTES.NOT_FOUND,
  NetworkingExperience: Icon.IconNetworkingExp,
  AtivacaoPresencial: Icon.IconAtivacaoPresencial,
};

const hasPermission = (
  restrictionPolicy: { code: string; name: string } | null | undefined,
  permissions: string[],
): boolean => {
  if (!restrictionPolicy) return true;
  return permissions.includes(restrictionPolicy.code);
};

export const generateSidebarMenuFromFeatures = (
  features: Feature[],
  permissions: UserPermissions,
): SidebarMenuItem[] => {
  const sidebarItems = features
    .filter(
      (feature) =>
        feature.is_sidebar &&
        (!feature.is_restrict ||
          hasPermission(feature.restriction_policy, permissions)),
    )
    .map((feature) => ({
      code: feature.code,
      title: feature.name,
      label: feature.name,
      path: `${ROUTES.ROOT}${FEATURE_TO_ROUTE_MAP[feature.code] || "not-found"}`,
      icon: iconMapping[feature.code] || "",
      isActive: feature.is_active,
      isMobile: feature.is_mobile,
      rank: feature.rank,
      subMenu: feature.children?.length
        ? feature.children
            .filter(
              (child) =>
                child.is_sidebar &&
                (!child.is_restrict ||
                  hasPermission(child.restriction_policy, permissions)),
            )
            .map((child) => ({
              code: child.code,
              title: child.name,
              label: child.name,
              path: `${ROUTES.ROOT}${FEATURE_TO_ROUTE_MAP[child.code] || "not-found"}`,
              icon: iconMapping[child.code] || "",
              isActive: child.is_active,
              isMobile: feature.is_mobile,
              rank: child.rank,
            }))
            .sort((a, b) => (a.rank ?? 0) - (b.rank ?? 0))
        : undefined,
    }))
    .sort((a, b) => (a.rank ?? 0) - (b.rank ?? 0));

  return sidebarItems;
};

export const generateSidebarMenuForDevice = (
  features: Feature[],
  permissions: UserPermissions,
  isMobile: boolean,
): SidebarMenuItem[] => {
  const mobileItems = features
    .filter(
      (feature) =>
        feature.is_sidebar &&
        feature.is_active &&
        (!isMobile || feature.is_mobile) &&
        !feature.in_mobile_menu,
    )
    .map((feature) => ({
      code: feature.code,
      title: feature.name,
      label: feature.name,
      path: `${ROUTES.ROOT}${FEATURE_TO_ROUTE_MAP[feature.code] || "not-found"}`,
      icon: iconMapping[feature.code] || "",
      isActive: feature.is_active,
      isMobile: feature.is_mobile,
      rank: feature.rank,
      subMenu: feature.children
        ?.filter(
          (child) =>
            child.is_sidebar &&
            child.is_active &&
            (!isMobile || child.is_mobile),
        )
        .map((child) => ({
          code: child.code,
          title: child.name,
          label: child.name,
          path: `${ROUTES.ROOT}${FEATURE_TO_ROUTE_MAP[child.code] || "not-found"}`,
          icon: iconMapping[child.code] || "",
          isActive: child.is_active,
          isMobile: child.is_mobile,
          rank: child.rank,
        }))
        .sort((a, b) => (a.rank ?? 0) - (b.rank ?? 0)),
    }))
    .sort((a, b) => (a.rank ?? 0) - (b.rank ?? 0));

  return mobileItems;
};

export const generateSidebarMainMenu = (
  features: Feature[],
  permissions: UserPermissions,
  isMobile: boolean,
): SidebarMenuItem[] => {
  const mobileMainMenu = features
    .filter(
      (feature) =>
        /* feature.is_sidebar && */
        feature.is_active && feature.is_mobile && feature.in_mobile_menu,
    )
    .map((feature) => ({
      code: feature.code,
      title: feature.name,
      label: feature.name,
      path: `${ROUTES.ROOT}${FEATURE_TO_ROUTE_MAP[feature.code] || "not-found"}`,
      icon: iconMapping[feature.code] || "",
      isActive: feature.is_active,
      isMobile: feature.is_mobile,
      rank: feature.rank,
      subMenu: feature.children
        ?.filter(
          (child) =>
            child.is_sidebar &&
            child.is_active &&
            (!isMobile || child.is_mobile),
        )
        .map((child) => ({
          code: child.code,
          title: child.name,
          label: child.name,
          path: `${ROUTES.ROOT}${FEATURE_TO_ROUTE_MAP[child.code] || "not-found"}`,
          icon: iconMapping[child.code] || "",
          isActive: child.is_active,
          isMobile: child.is_mobile,
          rank: child.rank,
        }))
        .sort((a, b) => (a.rank ?? 0) - (b.rank ?? 0)),
    }))
    .sort((a, b) => (a.rank ?? 0) - (b.rank ?? 0));

  return mobileMainMenu;
};

export const adjustMainMenu = (
  mainMenu: SidebarMenuItem[],
): SidebarMenuItem[] => {
  return mainMenu.map((menuItem) => {
    if (menuItem.code === "Perfil" && menuItem.isMobile) {
      return {
        ...menuItem,
        subMenu: [
          ...(menuItem.subMenu || []),
          {
            code: menuItem.code,
            title: "Perfil Geral",
            label: "Perfil Geral",
            path: "/dashboard/community/intelligence-networking/general-profile",
            icon: "",
            isActive: true,
            isMobile: true,
            rank: (menuItem.rank || 0) + 1,
          },
          {
            code: menuItem.code,
            title: "Bio",
            label: "Bio",
            path: "/dashboard/community/intelligence-networking/bio",
            icon: "",
            isActive: true,
            isMobile: true,
            rank: (menuItem.rank || 0) + 2,
          },
        ],
      };
    }

    if (menuItem.code === "PerfilAvaliacoes" && menuItem.isMobile) {
      return {
        ...menuItem,
        subMenu: [
          ...(menuItem.subMenu || []),
          {
            code: menuItem.code,
            title: "Auto Avaliação Networking",
            label: "Auto Avaliação Networking",
            path: "/dashboard/user-profile/self-assessment",
            icon: "",
            isActive: true,
            isMobile: true,
            rank: (menuItem.rank || 0) + 1,
          },
          {
            code: menuItem.code,
            title: "Desafios e Projetos 2025",
            label: "Desafios e Projetos 2025",
            path: "/dashboard/user-profile/challenges-and-projects-2025",
            icon: "",
            isActive: true,
            isMobile: true,
            rank: (menuItem.rank || 0) + 1,
          },
          {
            code: menuItem.code,
            title: "Feedback Networking Experience",
            label: "Feedback Networking Experience",
            path: "/dashboard/user-profile/feedback-networking-experience",
            icon: "",
            isActive: true,
            isMobile: true,
            rank: (menuItem.rank || 0) + 1,
          },
          {
            code: menuItem.code,
            title: "Indicações",
            label: "Indicações",
            path: "/dashboard/user-profile/invites",
            icon: "",
            isActive: true,
            isMobile: true,
            rank: (menuItem.rank || 0) + 1,
          },
        ],
      };
    }
    return menuItem;
  });
};

export const mapMenuItemsForThreeLevels = (
  menuItems: SidebarMenuItem[],
  filteredMeetingRooms: MeetingRoom[],
): SidebarMenuItem[] => {
  return menuItems.map((item) => {
    if (item.code === "Comunidade") {
      return {
        ...item,
        subMenu: item.subMenu?.map((subItem) => {
          if (subItem.code === "GruposTematicos") {
            return {
              ...subItem,
              subMenu: filteredMeetingRooms.map((room) => ({
                code: room.room_data.title,
                title: room.room_data.title,
                label: room.room_data.title,
                path: `#`,
                icon: "",
                isActive: true,
                isMobile: true,
                data: room.room_data,
                participantStatus: room.participation_status,
              })),
            };
          }

          if (subItem.code === "InteligenciaNetowrking") {
            return {
              ...subItem,
              subMenu: [
                ...(subItem.subMenu || []),
                {
                  code: "BioIndividual",
                  title: "Bio Individual",
                  label: "Bio Individual",
                  path: "/dashboard/community/intelligence-networking/bio",
                  icon: "",
                  isActive: true,
                  isMobile: true,
                },
              ],
            };
          }

          return subItem;
        }),
      };
    }

    if (item.label === "Clube") {
      return {
        ...item,
        subMenu: item.subMenu?.map((subItem) => {
          if (subItem.label === "Rodadas de Ativação") {
            return {
              ...subItem,
              subMenu: filteredMeetingRooms.map((room) => ({
                code: room.room_data.title,
                title: room.room_data.title,
                label: room.room_data.title,
                path: `#`,
                icon: "",
                isActive: true,
                isMobile: true,
                data: room.room_data,
                participantStatus: room.participation_status,
              })),
            };
          }

          if (subItem.code === "InteligenciaConexoes") {
            return {
              ...subItem,
              subMenu: [
                ...(subItem.subMenu || []),
                {
                  code: subItem.code,
                  title: "Bio Individual",
                  label: "Bio Individual",
                  path: "/dashboard/community/intelligence-networking/bio",
                  icon: "",
                  isActive: true,
                  isMobile: true,
                },
              ],
            };
          }

          return subItem;
        }),
      };
    }

    return item;
  });
};
