import axios from "axios";
import * as process from "process";

export const apiCopilot = axios.create({
  baseURL: process.env.REACT_APP_URL_COPILOT,
});

export const apiUser = axios.create({
  baseURL: process.env.REACT_APP_URL_API,
});

export const api = axios.create({
  baseURL: process.env.REACT_APP_URL_API,
});

api.interceptors.response.use(
  (response) => {
    response.headers["Cache-Control"] = "no-cache, no-store, must-revalidate";
    response.headers["Pragma"] = "no-cache";
    response.headers["Expires"] = "0";
    return response;
  },
  (error) => {
    return Promise.reject(error);
  },
);

apiUser.interceptors.response.use(
  (response) => {
    response.headers["Cache-Control"] = "no-cache, no-store, must-revalidate";
    response.headers["Pragma"] = "no-cache";
    response.headers["Expires"] = "0";
    return response;
  },
  (error) => {
    return Promise.reject(error);
  },
);

export const apiPlains = axios.create({
  baseURL: process.env.REACT_APP_URL_ORCHESTRATOR_POLICY,
});

export const apiElectoralIntelligence = axios.create({
  baseURL: process.env.REACT_APP_URL_ELECTORAL_INTELLIGENCE,
});

export const apiPredeterminedCandidate = axios.create({
  baseURL: process.env.REACT_APP_URL_PREDETERMINED_CANDIDATE,
});

export const apiAnalysis = axios.create({
  baseURL: process.env.REACT_APP_URL_ANALYSIS,
});

export const apiMentions = axios.create({
  baseURL: process.env.REACT_APP_URL_MENTIONS,
});

export const apiPortifolio = axios.create({
  baseURL: process.env.REACT_APP_URL_PORTIFOLIO,
});

export const apiPortifolioSponsored = axios.create({
  baseURL: process.env.REACT_APP_URL_PORTIFOLIO_SPONSORED,
});

export const apiPBI = axios.create({
  baseURL: process.env.REACT_APP_URL_PBI,
});

export const apiOrchestrator = axios.create({
  baseURL: process.env.REACT_APP_URL_ORCHESTRATOR_POLICY,
});

export const apiAdvisor = axios.create({
  baseURL: process.env.REACT_APP_URL_ADVISOR_API,
});

export const apiVox = axios.create({
  baseURL: process.env.REACT_APP_URL_VOX,
});

export const apiRainmakers = axios.create({
  baseURL: process.env.REACT_APP_URL_MEETING,
});

export const apiServices = axios.create({
  baseURL: process.env.REACT_APP_URL_SERVICES_API,
});

export const apiNetworking = axios.create({
  baseURL: process.env.REACT_APP_URL_NETWORKING_API,
});


export const defaultsHeadersAxios = (token: string) => {
  api.defaults.headers.common.Authorization = token && `Token ${token}`;
  apiElectoralIntelligence.defaults.headers.common.Authorization =
    token && `Token ${token}`;
  apiPredeterminedCandidate.defaults.headers.common.Authorization =
    token && `Token ${token}`;
  apiAnalysis.defaults.headers.common.Authorization = token && `Token ${token}`;
  apiMentions.defaults.headers.common.Authorization = token && `Token ${token}`;
  apiPortifolio.defaults.headers.common.Authorization =
    token && `Token ${token}`;
  apiOrchestrator.defaults.headers.common.Authorization =
    token && `Token ${token}`;
  apiVox.defaults.headers.common.Authorization = token && `Token ${token}`;
  apiServices.defaults.headers.common.Authorization = token && `Token ${token}`;
  apiPBI.defaults.headers.common.Authorization = token && `Token ${token}`;
  apiRainmakers.defaults.headers.common.Authorization =
    token && `Token ${token}`;
};

export const defaultsHeadersAxiosUser = (token: string) => {
  apiUser.defaults.headers.common.Authorization = token && `Token ${token}`;
  apiPortifolioSponsored.defaults.headers.common.Authorization =
    token && `Token ${token}`;
};
