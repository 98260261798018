import React from "react";
import { useFetchForms } from "hooks/useFetchForms";
import DynamicForm from "components/DynamicForm";
import Text from "components/Text";
import Spin from "components/Spin";

import TitleFormComponent from "components/TitleForm";

import { useUser } from "global/UserContext";
import * as S from "./styles";

const FeedbackNetorkingExp: React.FC = () => {
  const { user } = useUser();
  const {
    forms,
    loading: formsLoading,
    error,
  } = useFetchForms("2e192c7d-daab-4d25-b094-b50ca32b53e6");

  const uuid = user?.id;

  if (formsLoading) return <Spin fullscreen />;
  if (error)
    return (
      <Text color="red" bold>
        Erro ao carregar formulário: {error}
      </Text>
    );

  return (
    <S.Container>
      {forms.map((form) => (
        <S.Container key={form.id}>
          <TitleFormComponent title={form?.form_name} />
          <DynamicForm form_id={form.id} uuid={uuid} />
        </S.Container>
      ))}
    </S.Container>
  );
};

export default FeedbackNetorkingExp;
