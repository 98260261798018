import { apiServices } from "services/apiService";
import { MeetingRoom } from "../types/meetingTypes";

export const fetchMeetingRooms = async (
  organizationId: string,
  userId: string,
): Promise<MeetingRoom[]> => {
  try {
    const response = await apiServices.get(
      `/meetings/v1/rooms/get-rooms-with-participant-status?organization_id=${organizationId}&user_id=${userId}`,
    );

    return response.data;
  } catch (error) {
    console.error("Erro ao buscar salas de reunião:", error);
    throw new Error("Não foi possível obter as salas de reunião.");
  }
};
