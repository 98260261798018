import axios from "axios";

export interface State {
  value: string;
  label: string;
}

export interface City {
  value: string;
  label: string;
}

export const fetchStates = async (): Promise<State[]> => {
  try {
    const response = await axios.get(
      "https://servicodados.ibge.gov.br/api/v1/localidades/estados",
    );
    return response.data.map((state: { id: number; nome: string }) => ({
      value: state.id.toString(),
      label: state.nome,
    }));
  } catch (error) {
    console.error("Erro ao buscar estados:", error);
    return [];
  }
};

export const fetchCitiesByState = async (stateId: string): Promise<City[]> => {
  try {
    const response = await axios.get(
      `https://servicodados.ibge.gov.br/api/v1/localidades/estados/${stateId}/municipios`,
    );
    return response.data.map((city: { id: number; nome: string }) => ({
      value: city.id.toString(),
      label: city.nome,
    }));
  } catch (error) {
    console.error("Erro ao buscar municípios:", error);
    return [];
  }
};
