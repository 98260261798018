import { useState, useCallback } from "react";
import { useCandidates } from "hooks/useCandidates";
import { useFilterGlobal } from "hooks/useFilterGlobal";
import { ITotalsMentions } from "componentsNew/CockpitComponents/types/totalsMentions";
import { getSentiment } from "componentsNew/PoliticalListeningComponents/services/getListeners";
import { apiVox } from "services/apiService";
import { ALL } from "utils/constants";

interface IPrincipalMessage {
  isCandidate?: boolean;
  selectedSN?: string[];
}

const PAGE_SIZE = 7;

const useMentions = ({
  isCandidate = false,
  selectedSN = [],
}: IPrincipalMessage) => {
  const { filterMentions, rangeDate, setInitialDate, filterRaioX } =
    useFilterGlobal();
  const { selectedCandidate } = useCandidates();
  const [mentions, setMentions] = useState([]);
  const [totalsMentions, setTotalsMentions] = useState<ITotalsMentions | null>(
    null,
  );
  const [loading, setLoading] = useState(false);
  const [loadingSentiment, setLoadingSentiment] = useState(false);
  const [isDropdownActive, setIsDropdownActive] = useState(true);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [sortField, setSortField] = useState<string>("date");

  const [mentionsSentiment, setMentionsSentiment] = useState({
    series: [],
    options: {
      chart: {
        id: "basic-bar",
      },
      colors: ["#00E23F", "#FF0000", "#FFFFFF"],
      xaxis: {
        categories: [],
      },
    },
  });

  const fetchSentimentData = useCallback(
    async (candidateId: string) => {
      setLoadingSentiment(true);

      const socialNetworks =
        selectedSN.length > 0 ? `${selectedSN.join(",")}` : "";

      try {
        const params = {
          parent_name: filterMentions !== ALL ? filterMentions : undefined,
          date_start: rangeDate.initial_date,
          date_end: rangeDate.end_date,
          sentiment: filterRaioX !== ALL ? filterRaioX : undefined,
          candidate_id: candidateId,
          page_type_name: socialNetworks,
          id_cand: "rainmakers.adm",
        };

        const data = await getSentiment(params);
        setMentionsSentiment({
          series: data.series || [],
          options: {
            chart: {
              id: "basic-bar",
            },
            colors: ["#00E23F", "#FF0000", "#FFFFFF"],
            xaxis: {
              categories: data.dates || [],
            },
          },
        });
      } catch (e) {
        console.error(e);
      } finally {
        setLoadingSentiment(false);
      }
    },
    [selectedSN, filterMentions, rangeDate, filterRaioX],
  );

  const getTopMentions = useCallback(
    async (currentPage: number) => {
      const parentName =
        filterMentions !== ALL ? `&parent_name=${filterMentions}` : "";
      const initialDate = rangeDate.initial_date
        ? `&initial_date=2010-01-01`
        : "";
      const endDate = rangeDate.end_date
        ? `&final_date=${rangeDate.end_date}`
        : "";
      const sentiment = filterRaioX !== ALL ? `&sentiment=${filterRaioX}` : "";
      const city =
        selectedCandidate?.locality && isCandidate
          ? `&city=${selectedCandidate?.locality}`
          : "";
      const targetCity =
        selectedCandidate?.target_locality && isCandidate
          ? `&target_cd_city=${selectedCandidate?.target_locality}`
          : "";
      const idCandidate = `&id_cand=rainmakers.adm`;
      const socialNetworks =
        selectedSN.length > 0 ? `&page_type_name=${selectedSN.join(",")}` : "";
      const sortBy = `&sort_field=${sortField}`;
      const paginationParams = `&page=${currentPage}&page_size=${PAGE_SIZE}`;

      try {
        setLoading(true);

        const { data } = await apiVox.get(
          `/vox/v1/political-vox/top-mentions?${parentName}${initialDate}${endDate}${sentiment}${idCandidate}${socialNetworks}${sortBy}${paginationParams}`,
        );

        const total = await apiVox.get(
          `/vox/v1/political-vox/mentions-total-counter?${parentName}${initialDate}${endDate}${sentiment}${idCandidate}${socialNetworks}${targetCity}`,
        );

        const totalItems = total.data.total_mentions || 0;

        setMentions(data);
        setTotalPages(Math.ceil(totalItems / PAGE_SIZE));
        setTotalsMentions(total.data);
        setLoading(false);

        // await fetchSentimentData(selectedCandidate?.id_cand || "");
      } catch (e) {
        console.error(e);
      } finally {
        setLoading(false);
      }
    },
    [
      filterMentions,
      rangeDate,
      filterRaioX,
      selectedSN,
      selectedCandidate,
      isCandidate,
      fetchSentimentData,
      sortField,
      page,
    ],
  );

  return {
    mentions,
    totalsMentions,
    loading,
    loadingSentiment,
    isDropdownActive,
    page,
    totalPages,
    sortField,
    setSortField,
    getTopMentions,
    fetchSentimentData,
    setDropdownActive: setIsDropdownActive,
    setPage,
    setMentionsSentiment,
  };
};

export default useMentions;
