import React, { useState, useEffect } from "react";
import { useUser } from "global/UserContext";
import ToUserSelectionComponent from "./ToUserSelectionComponent";
import { InputComponent } from "./InputComponent";

import * as S from "./styles"

import { apiNetworking } from "services/apiService";
import { OutputComponent } from "./OutputComponent";
import { Stack } from "@mui/material";

interface MappedMember {
    label: string;
    id: string;
}

interface CommonEdge {
    type_code: string;
}


export const AtivacaoPresencial: React.FC = () => {
    const { user } = useUser();


    const [selectedUser, setSelectedUser] = useState<MappedMember | null>(null);

    const [connectionLevel, setConnectionLevel] = useState<any>(0)
    const [commonEdgeTypes, setCommonEdgeTypes] = useState<string[]>([]);


    useEffect(() => {
        fetchConnectionLevel()
        fetchCommonEdgeTypes()
    }, [selectedUser])

    const fetchConnectionLevel = async () => {
        if (selectedUser) {
            try {
                const response = await apiNetworking.get(`/v1/stats/connection/${user?.id}/${selectedUser?.id}`);
                setConnectionLevel(response.data);
            } catch (error) {
                console.error("Error fetching members:", error);
            }
            // finally {
            //     setLoading(false);
            // }    
        }

    }

    const fetchCommonEdgeTypes = async () => {
        if (selectedUser) {
            try {
                const [edgesResponse] = await Promise.all([
                    apiNetworking.get(`/v1/edge/find?from_user_uuid=${user?.id}&to_user_uuid=${selectedUser?.id}`),
                ]);
                setCommonEdgeTypes(edgesResponse.data.map((edge: CommonEdge) => edge.type_code));
            } catch (err) {
                console.error("Error fetching data:", err);
                // setError("Failed to load data. Please try again later.");
            }
            // finally {
            // setLoading(false);
            // }
        }

    };


    return (
        <S.Wraper>
            <Stack gap={1}>
                <ToUserSelectionComponent setSelectedUser={setSelectedUser} />
                {selectedUser && connectionLevel < 1 && <InputComponent selectedUser={selectedUser} commonEdgeTypes={commonEdgeTypes} />}
                {selectedUser && connectionLevel >= 1 && <OutputComponent selectedUser={selectedUser} />}
            </Stack>
        </S.Wraper>
    );
};
