import styled from "styled-components";
import theme from "styles/theme";

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  margin: 1rem 0;

  @media (max-width: 1024px) {
    margin-top: 24px;
    margin-bottom: 24px;
  }

  @media (max-width: 768px) {
    margin-top: 32px;
    margin-bottom: 32px;
  }
`;

export const Content = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
`;

export const PersonalDataContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  margin: 8px 0 24px 0;

  @media (max-width: 1024px) {
    margin: 16px 0 32px 0;
  }

  @media (max-width: 768px) {
    margin: 24px 0 40px 0;
  }
`;
