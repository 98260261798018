import React, { createContext, useState, useContext, useEffect } from "react";

interface MenuTitleContextProps {
  menuTitle: string;
  updateMenuTitle: (title: string) => void;
}

const MenuTitleContext = createContext<MenuTitleContextProps | undefined>(
  undefined,
);

export const useMenuTitle = (): MenuTitleContextProps => {
  const context = useContext(MenuTitleContext);
  if (!context) {
    throw new Error(
      "useMenuTitle deve ser usado dentro de um MenuTitleProvider",
    );
  }
  return context;
};

export const MenuTitleProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [menuTitle, setMenuTitle] = useState<string>(() => {
    return localStorage.getItem("menuTitle") || "";
  });

  const updateMenuTitle = (title: string) => {
    setMenuTitle(title);
    localStorage.setItem("menuTitle", title);
  };

  useEffect(() => {
    const handleStorageChange = () => {
      const storedTitle = localStorage.getItem("menuTitle") || "";
      if (storedTitle !== menuTitle) {
        setMenuTitle(storedTitle);
      }
    };

    window.addEventListener("storage", handleStorageChange);
    return () => window.removeEventListener("storage", handleStorageChange);
  }, [menuTitle]);

  return (
    <MenuTitleContext.Provider value={{ menuTitle, updateMenuTitle }}>
      {children}
    </MenuTitleContext.Provider>
  );
};
