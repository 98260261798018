import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  IconButton,
  Box,
} from "@mui/material";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import PaginationComponent from "components/Pagination/Pagination";
import { GroupMember } from "../../types/groups";
import theme from "styles/theme";

interface MemberTableProps {
  members: GroupMember[];
}

const ITEMS_PER_PAGE = 9;

const MemberTable: React.FC<MemberTableProps> = ({ members }) => {
  const [currentPage, setCurrentPage] = useState(1);

  const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
  const currentItems = members.slice(startIndex, startIndex + ITEMS_PER_PAGE);

  return (
    <Box>
      <TableContainer
        component={Paper}
        sx={{ width: "100%", overflow: "hidden" }}
      >
        <Table>
          <TableBody>
            {currentItems.map((member, index) => (
              <TableRow
                key={member.uuid}
                sx={{
                  backgroundColor:
                    index % 2 === 0
                      ? theme.COLORS.SHADE_30
                      : theme.COLORS.SHADE_10,
                  "&:last-child td, &:last-child th": { border: 0 },
                  justifyContent: "space-between",
                }}
              >
                <TableCell
                  sx={{
                    padding: "0 8px",
                    fontSize: "0.875rem",
                    width: 0.35,
                  }}
                >
                  {member
                    ? `${member.first_name} ${member.last_name}`
                    : "Nome indisponível"}
                </TableCell>
                <TableCell
                  sx={{
                    textAlign: "center",
                    padding: "0 8px",
                    width: 0.2,
                  }}
                >
                  {member.position ? member.position : "Posição indisponível"}
                </TableCell>
                <TableCell
                  sx={{
                    textAlign: "center",
                    padding: "0 8px",
                    width: 0.35,
                  }}
                >
                  Ativo em: {member.date ? member.date : "Data indisponível"}
                </TableCell>
                <TableCell
                  sx={{
                    textAlign: "right",
                    padding: "0 8px",
                    paddingRight: "2.5rem",
                    width: 0.1,
                  }}
                >
                  <IconButton onClick={() => console.log("")}>
                    <MoreHorizIcon sx={{ color: theme.COLORS.PRIMARY }} />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        mt={2}
        mb={2}
      >
        <PaginationComponent
          totalPages={Math.ceil(members.length / ITEMS_PER_PAGE)}
          currentPage={currentPage}
          onPageChange={(page) => setCurrentPage(page)}
          siblingCount={1}
          boundaryCount={1}
          color="custom"
          customColor={theme.COLORS.BACKGROUND_PRIMARY}
        />
      </Box>
    </Box>
  );
};

export default MemberTable;
