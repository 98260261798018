import { styled } from "styled-components";
import theme from "styles/theme";

export const Wrapper = styled.main``;

export const ContentFilters = styled.div`
  background: ${theme.COLORS.BACKGROUND_PRIMARY};
  width: 224px;
  min-height: 372px;
  padding: 8px 0;
  max-height: 372px;
  overflow-y: auto;
`;

export const FilterList = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  width: 100%;

  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const ItemList = styled.li`
  all: unset;
  background-color: transparent;
  font-weight: 700;
  font-size: 12px;
  color: ${theme.COLORS.GRAY_10};
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 0 8px;

  &:nth-child(odd) {
    background-color: ${theme.COLORS.BLACK_7};
    padding: 8px;
  }

  p {
    color: ${theme.COLORS.PRIMARY};
  }

  &.type_list {
    display: flex;
    gap: 4px;
    align-items: flex-start;
    flex-direction: column;
  }
`;

export const KeyText = styled.h4`
  font-size: 12px;
  font-weight: 500;
  color: ${theme.COLORS.GRAY_10};
`;

export const ListTextBox = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 3px;
`;
