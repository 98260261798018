import { useUser } from "global/UserContext";

const usePlanType = () => {
  const { user } = useUser();

  const planType = user?.user_to_organization[0]?.organization.plan.name || "";
  const isAdvisor = user?.is_advisor || false;

  return { planType, isAdvisor };
};

export default usePlanType;
