import styled from "styled-components";
import theme from "styles/theme";
import { BREAKPOINTS } from "styles/spacing";
import "styles/global";

export const Background = styled.div`
  background: ${({ theme }) => theme.COLORS.BACKGROUND_PRIMARY};
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 100;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  padding: 0 5%;
  text-align: center;
  color: ${theme.COLORS.GRAY_50};
  z-index: 101;

  @media (min-width: ${BREAKPOINTS.WIDTH.MD}) {
    justify-content: center;
    align-items: center;
    text-align: center;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 65%;
  left: 50%;
  transform: translate(-50%, -50%);
  gap: 1rem;

  @media (min-width: ${BREAKPOINTS.WIDTH.MD}) {
    top: auto;
    bottom: 25%;
    transform: none;
    align-items: center;
  }
`;
