import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { apiOrchestrator } from "services/apiService";
import { useState } from "react";
import { RegisterResponse } from "./types/registerTypes";

const schema = yup.object().shape({
  firstName: yup.string().required("Nome é obrigatório"),
  lastName: yup.string().required("Sobrenome é obrigatório"),
  email: yup.string().email("E-mail inválido").required("E-mail é obrigatório"),
  phone: yup
    .string()
    .matches(/^\+?[1-9]\d{1,14}$/, "Número inválido")
    .required("Whatsapp é obrigatório"),
  password: yup
    .string()
    .required("Senha é obrigatória")
    .min(8, "A senha deve ter no mínimo 8 caracteres")
    .matches(/[A-Z]/, "A senha deve ter pelo menos uma letra maiúscula")
    .matches(/[a-z]/, "A senha deve ter pelo menos uma letra minúscula")
    .matches(/\d/, "A senha deve ter pelo menos um número")
    .matches(/[\W_]/, "A senha deve ter pelo menos um caractere especial"),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref("password")], "As senhas devem corresponder")
    .required("Confirmação de senha é obrigatória"),
});

const useRegister = () => {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    shouldFocusError: false,
  });

  const [loading, setLoading] = useState(false);

  const onSubmit = async (data: any): Promise<RegisterResponse> => {
    setLoading(true);

    try {
      const requestData = {
        username: data.email,
        user_attributes: [
          { name: "given_name", value: data.firstName },
          { name: "family_name", value: data.lastName },
          { name: "email", value: data.email },
          { name: "phone_number", value: `+${data.phone}` },
        ],
        password: data.password,
        message_action: "SUPPRESS",
        delivery_mediums: ["EMAIL"],
      };

      const response = await apiOrchestrator.post("/auth/", requestData);
      return response.data as RegisterResponse;
    } catch (error: any) {
      console.error(
        "Error registering user:",
        error.response ? error.response.data : error.message,
      );
      throw error;
    } finally {
      setLoading(false);
    }
  };

  return {
    control,
    handleSubmit,
    errors,
    onSubmit,
    loading,
  };
};

export default useRegister;
