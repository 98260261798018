import styled from "styled-components";
import { BREAKPOINTS } from "styles/spacing";
import theme from "styles/theme";
import "styles/global";

export const Background = styled.div`
  background: ${({ theme }) => theme.COLORS.BACKGROUND_MAIN};
  background-size: cover;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  overflow: hidden;
`;

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1.5rem;
  flex-direction: column;

  @media (min-width: ${BREAKPOINTS.WIDTH.MD}) {
    flex-direction: row;
  }

  @media (max-width: ${BREAKPOINTS.WIDTH.SM}) {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0;
    margin: 0;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 1rem;
  gap: 1rem;

  @media (max-width: ${BREAKPOINTS.WIDTH.SM}) {
    display: flex;
    width: 100%;
    height: 100vh;
    padding: 0;
    gap: 0;
    margin: 0;
  }

  @media (min-width: ${BREAKPOINTS.WIDTH.MD}) and (max-width: ${BREAKPOINTS
      .WIDTH.LG}) {
    flex-direction: column;
    max-width: 900px;
    justify-content: center;
  }

  @media (min-width: ${BREAKPOINTS.WIDTH.LG}) {
    flex-direction: row;
    justify-content: center;
    gap: 2rem;
  }
`;

export const Card = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 450px;
  margin-bottom: 1rem;
  position: relative;
  overflow: hidden;

  @media (max-width: ${BREAKPOINTS.WIDTH.MD}) {
    margin-bottom: 1rem;
  }

  @media (max-width: ${BREAKPOINTS.WIDTH.SM}) {
    height: 25vh;
    margin-bottom: 0;

    &::before {
      content: "";
      background: ${({ theme }) => theme.COLORS.BACKGROUND_MAIN};
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      transform: scale(1.5);
      z-index: -1;
    }
  }

  @media (min-width: ${BREAKPOINTS.WIDTH.MD}) and (max-width: ${BREAKPOINTS
      .WIDTH.LG}) {
    width: 100%;
    max-width: 350px;
  }

  @media (min-width: ${BREAKPOINTS.WIDTH.LG}) {
    max-width: 450px;
  }
`;

export const Image = styled.img`
  width: 100%;
  height: auto;
  object-fit: contain;
  max-width: 200px;

  @media (max-width: ${BREAKPOINTS.WIDTH.SM}) {
    max-width: 140px;
  }

  @media (min-width: ${BREAKPOINTS.WIDTH.MD}) and (max-width: ${BREAKPOINTS
      .WIDTH.LG}) {
    max-width: 180px;
  }

  @media (min-width: ${BREAKPOINTS.WIDTH.LG}) {
    max-width: 200px;
  }
`;

export const FormContainer = styled.div`
  background-color: ${theme.COLORS.BACKGROUND_SECONDARY};
  border-radius: 16px;
  padding: 2rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1.5rem;
  margin: 1rem 0;
  position: relative;

  box-shadow:
    0px 8px 15px rgba(0, 0, 0, 0.3),
    0px -24px 12px rgba(0, 0, 0, 0.25);

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 60%;
    height: 4px;
    background: rgba(0, 0, 0, 0.1);
    border-radius: 2px;
  }

  @media (max-width: ${BREAKPOINTS.WIDTH.SM}) {
    border-radius: 24px 24px 0 0;
    margin: 0;
    height: 75vh;
  }

  @media (min-width: ${BREAKPOINTS.WIDTH.MD}) and (max-width: ${BREAKPOINTS
      .WIDTH.LG}) {
    max-width: 400px;
    padding: 2rem;
  }

  @media (min-width: ${BREAKPOINTS.WIDTH.LG}) {
    max-width: 441px;
    padding: 2.5rem;
    height: max-content;
  }
`;

export const ImageLogoMark = styled.img`
  display: none;

  @media (min-width: ${BREAKPOINTS.WIDTH.MD}) {
    display: block;
    max-width: 180px;
    margin-bottom: 1rem;
  }
`;

export const StyledForm = styled.form`
  display: flex;
  width: 100%;
  height: 32rem;
  justify-content: space-around;
  flex-direction: column;
  gap: 1rem;

  .text-title {
    text-align: center;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.408px;
  }

  .text-form {
    margin-bottom: -0.5rem;
  }

  .error-message {
    display: flex;
    justify-content: start;
    margin-top: 0.5rem;
  }
`;

export const InputsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  width: 100%;
`;

export const RememberForgotContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

export const Separator = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  margin: 2rem 0 1rem 0;

  span {
    padding: 0 10px;
    color: ${theme.COLORS.TEXT_PRIMARY};
  }

  &:before,
  &:after {
    content: "";
    flex: 1;
    border-bottom: 2px solid ${theme.COLORS.GRAY_10};
  }

  &:before {
    margin-right: 10px;
  }

  &:after {
    margin-left: 10px;
  }
`;

export const ContentCenter = styled.div`
  display: flex;
  height: auto;
  gap: 2rem;
  justify-content: space-evenly;
  flex-direction: column;
  margin-top: 1rem;
`;

export const TopButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const FooterButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-bottom: 3rem;
`;
