import React, { useState, useEffect } from 'react'
import * as S from './styles'
import { Button, Skeleton } from '@mui/material'
import LoadingButton from '@mui/lab/LoadingButton';



interface GeneralConfirmationModalProps {
  isOpen: boolean
  loading: boolean
  text: string
  onClose: () => void
  onConfirm: () => void
  variant: "normal" | "justClose"
}

const GeneralConfirmationModal: React.FC<GeneralConfirmationModalProps> = ({ isOpen, loading, text, onClose, onConfirm, variant = "normal" }) => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(isOpen)
  const [isloading, setIsLoading] = useState<boolean>(loading)

  useEffect(() => {
    setIsModalOpen(isOpen)
    setIsLoading(loading)
  }, [isOpen, loading])

  const handleSelect = () => {
    onClose()
    setIsModalOpen(false)
  }

  const handleConfirm = () => {
    onConfirm()
    setIsModalOpen(false)
  }

  if (!isModalOpen) {
    return null
  }

  return (
    <S.ModalContainer>
      <S.ModalContent>
        <S.ModalTitle>
          <div className="title">
            <p>CONFIRMAÇÃO</p>
          </div>
        </S.ModalTitle>
        <div className="content-text">
          {
            isloading && <Skeleton /> || <p>{text}</p>
          }
        </div>
        <S.ModalFooter>
          {
            variant === "normal" && <LoadingButton className="btn-no" onClick={handleSelect} variant="outlined" size="large" loading={isloading}>Não</LoadingButton>
          }

          <LoadingButton className="btn-yes" onClick={handleConfirm} variant="contained" size="large" loading={isloading}>{variant === "normal" ? "Sim" : "Fechar"}</LoadingButton>
        </S.ModalFooter>
      </S.ModalContent>
    </S.ModalContainer >
  )
}

export default GeneralConfirmationModal
