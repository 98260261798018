import { apiVox } from "services/apiService";

interface SavedSubmission {
  created_at: string;
  field_id: string;
  id: string;
  updated_at: string;
  user_id: string;
  values?: { option_id?: string; value?: string }[];
}

export const fetchFieldSavedData = async (
  fieldId: string,
  userId: any,
): Promise<SavedSubmission[]> => {
  try {
    const savedSubmission = await apiVox.get(
      `/forms/v1/submissions/${fieldId}?user_id=${userId}`,
    );
    const data: SavedSubmission[] = savedSubmission?.data || [];
    return data;
  } catch (error) {
    console.error("Error fetching field saved data:", error);
    return [];
  }
};
