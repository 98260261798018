import React from "react";
import GlobalModal from "modals/GlobalModal/GlobalModal";

interface SuccessModalProps {
  isOpen: boolean;
  onClose: () => void;
  inviteeName?: string | null;
}

const SuccessModal: React.FC<SuccessModalProps> = ({
  isOpen,
  onClose,
  inviteeName,
}) => {
  return (
    <GlobalModal
      open={isOpen}
      title="CONFIRMAÇÃO"
      description={`Cadastro realizado com sucesso!
         Agradecemos pelo aceite do convite.
      `}
      onClose={onClose}
      confirmButtonText="Fechar"
      onConfirm={onClose}
    />
  );
};

export default SuccessModal;
