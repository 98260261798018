import React, { useState } from "react";
import { Box, Grid, TextField } from "@mui/material";
import { useInvites } from "pages/UserProfile/useInvitesHook";
import { useUser } from "global/UserContext";
import { useIsMobile, useIsTablet } from "utils/mediaQueries";
import GlobalModal from "modals/GlobalModal/GlobalModal";
import * as S from "../styles";

interface Form {
  id?: string;
  invitee_first_name: string;
  invitee_last_name: string;
  invitee_cell_phone: string;
  invitee_email: string;
  invite_status: string;
}

interface ModalNovaIndicacaoProps {
  handleCloseModal: () => void;
  initialFormValues: Form;
}

const ModalNovaIndicacao: React.FC<ModalNovaIndicacaoProps> = (props) => {
  const { user } = useUser();
  const isMobile = useIsMobile();
  const userId = user?.id;

  const [formValues, setFormValues] = useState(props.initialFormValues);

  const { submitInvite, updateInvite } = useInvites({ userId });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { id, value } = e.target;
    setFormValues((prevValues) => ({
      ...prevValues,
      [id]: value,
    }));
  };

  const handleSubmit = async (formValues: any) => {
    if (!userId) {
      console.error("User ID not available");
      return;
    }
    try {
      await submitInvite(formValues);
      props.handleCloseModal();
    } catch (err) {
      console.error("Error submitting invite:", err);
    }
  };

  const handleUpdate = async (formValues: any) => {
    if (!userId) {
      console.error("User ID not available");
      return;
    }
    try {
      await updateInvite(formValues);
      props.handleCloseModal();
    } catch (err) {
      console.error("Error updating invite:", err);
    }
  };

  return (
    <GlobalModal
      open
      title={"NOVA INDICAÇÃO"}
      confirmButtonText={formValues.id ? "Editar Convite" : "Enviar Convite"}
      cancelButtonText="Cancelar"
      onConfirm={() =>
        formValues.id ? handleUpdate(formValues) : handleSubmit(formValues)
      }
      onClose={props.handleCloseModal}
      onCancel={props.handleCloseModal}
      width={isMobile ? "95%" : "700px"}
    >
      <form onSubmit={() => handleSubmit(formValues)}>
        <Box
          sx={{
            display: "flex",
            flexDirection: isMobile ? "column" : "column",
            gap: isMobile ? "1rem" : 0,
          }}
        >
          <Box
            sx={{
              display: "flex",
              width: "100%",
              flexDirection: isMobile ? "column" : "row",
              gap: 1,
            }}
          >
            <TextField
              id="invitee_first_name"
              label="Nome"
              variant="outlined"
              fullWidth
              value={formValues.invitee_first_name}
              onChange={handleChange}
              sx={{ marginBottom: "1rem" }}
            />
            <TextField
              id="invitee_last_name"
              label="Sobrenome do convidado"
              variant="outlined"
              fullWidth
              value={formValues.invitee_last_name}
              onChange={handleChange}
              sx={{ marginBottom: "1rem" }}
            />
            <TextField
              id="invitee_cell_phone"
              label="Telefone do convidado"
              variant="outlined"
              fullWidth
              value={formValues.invitee_cell_phone}
              onChange={handleChange}
              sx={{ marginBottom: "1rem" }}
            />
          </Box>
          <TextField
            id="invitee_email"
            label="E-mail do convidado"
            variant="outlined"
            fullWidth
            value={formValues.invitee_email}
            onChange={handleChange}
            sx={{ marginBottom: "1rem" }}
          />
        </Box>
      </form>
    </GlobalModal>
  );
};

export default ModalNovaIndicacao;
