import { useState, useCallback } from "react";
import { apiVox } from "services/apiService";

const useProfileCompletion = () => {
  const [progress, setProgress] = useState<number | null>(null);

  const fetchCompletion = useCallback(async (userId: string) => {
    try {
      const response = await apiVox.get(
        `/forms/v1/analysis/completion/${userId}?group_id=9e038ecb-1542-4e90-acbd-91c537102bc3`,
      );
      const completion = response.data.completion;
      setProgress(completion);
      return completion;
    } catch (error) {
      console.error("Erro ao verificar progresso do perfil:", error);
      return null;
    }
  }, []);

  return { progress, fetchCompletion };
};

export default useProfileCompletion;
