import React, { useEffect, useState } from "react";
import { useUser } from "global/UserContext";
import { Container, Stack } from "@mui/material";
import Text from "components/Text";
import Spin from "components/Spin";
import InviteList from "./InviteList";
import { useInvites } from "../useInvitesHook";
import theme from "styles/theme";
import ModalNovaIndicacao from "./NovaIndicacaoModal";
import InternalButton from "components/InternalButton";
import AddIcon from "@mui/icons-material/Add";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { useIsMobile, useIsTablet } from "utils/mediaQueries";

const initialFormValues = {
  invitee_first_name: "",
  invitee_last_name: "",
  invitee_cell_phone: "",
  invitee_email: "",
  invite_status: "pending",
};

const InvitesPage: React.FC = () => {
  const isMobile = useIsMobile();
  const isTablet = useIsTablet();
  const { user } = useUser();
  const userId = user?.id;

  const [page, setPage] = useState<number>(0);
  const [size, setSize] = useState<number>(10); // default size
  const [orderBy, setOrderBy] = useState<string>("invited_at"); // Default order
  const [isDesc, setIsDesc] = useState<boolean>(true); // Default order
  const inviteStatus = ""; // Set to desired invite status or leave empty for all

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [formValues, setFormValues] = useState<any>(initialFormValues);

  const {
    invites,
    total,
    loading,
    error,
    fetchInvites,
    deleteInvite,
    getGeneralInviteId,
  } = useInvites({ userId, page, size, orderBy, isDesc, inviteStatus });

  // Chama fetchInvites apenas quando userId está disponível e as dependências mudam
  useEffect(() => {
    if (userId) {
      fetchInvites(userId);
    }
  }, [userId, page, size, orderBy, isDesc]);

  const onPageChange = (newPage: number) => {
    setPage(newPage);
  };

  const onRowsPerPageChange = (newRowsPerPage: number) => {
    setSize(newRowsPerPage);
    setPage(0); // Reset to the first page when changing rows per page
  };

  const onClickEdit = (invite: any) => {
    setFormValues({
      id: invite.id,
      invitee_first_name: invite.invitee_first_name,
      invitee_last_name: invite.invitee_last_name,
      invitee_cell_phone: invite.invitee_cell_phone,
      invitee_email: invite.invitee_email,
      invite_status: invite.invite_status,
    });
    handleOpenModal();
  };

  const onClickOrderBy = (column: string) => {
    setOrderBy(column);
  };

  const onClickDelete = (inviteId: string) => {
    deleteInvite(inviteId);
  };

  const onClickCopy = (inviteId: string) => {
    const url = generateInviteUrl(inviteId);
    copyTextToClipboard(url);
  };

  const handleClickNewInvite = () => {
    setFormValues(initialFormValues);
    handleOpenModal();
  };

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    if (userId) {
      fetchInvites(userId);
    }
    setIsModalOpen(false);
  };

  const handleClickCopyLink = async () => {
    if (!userId) return;
    const generalInviteId = await getGeneralInviteId();
    const url = generateInviteUrl(generalInviteId);
    copyTextToClipboard(url);
  };

  const generateInviteUrl = (inviteId: string) => {
    const origin = window.location.origin;
    return `${origin}/register?invite_id=${inviteId}`;
  };

  const copyTextToClipboard = (text: string) => {
    if (navigator.clipboard) {
      navigator.clipboard
        .writeText(text)
        .then(() => {
          alert("A URL do convite foi copiada!");
        })
        .catch((err) => {
          console.error("Failed to copy the URL: ", err);
          alert(`Failed to copy the URL. Please try again. ${err}`);
        });
    } else {
      // Fallback for older browsers or environments where clipboard API is unavailable
      const textarea = document.createElement("textarea");
      textarea.value = text;
      textarea.style.position = "fixed"; // Avoid scrolling to bottom
      document.body.appendChild(textarea);
      textarea.focus();
      textarea.select();
      try {
        document.execCommand("copy");
        alert("A URL do convite foi copiada!");
      } catch (err) {
        console.error("Fallback: Failed to copy the URL: ", err);
        alert("Failed to copy the URL. Please try again.");
      }
      document.body.removeChild(textarea);
    }
  };


  if (!userId) {
    return (
      <Text color="red" bold>
        Usuário não encontrado. Por favor, tente novamente.
      </Text>
    );
  }

  if (loading) {
    return <Spin fullscreen />;
  }

  if (error) {
    console.error(error);
    return (
      <Text color="red" bold>
        Erro ao carregar convites.
      </Text>
    );
  }

  return (
    <Stack
      spacing={1}
      sx={{
        display: "flex",
        width: "100%",
        flexDirection: "column",
        alignItems: "end",
      }}
    >
      <Stack
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-end",
          marginLeft: "auto",
          gap: isMobile ? "1rem" : "0.5rem",
        }}
      >
        <InternalButton
          background={`${theme.COLORS.PRIMARY}`}
          icon={<ContentCopyIcon fontSize="small" />}
          style={{
            alignSelf: isMobile ? "end" : "end",
            padding: "2px 8px",
          }}
          variant="outline"
          onClick={handleClickCopyLink}
        >
          Link compartilhável
        </InternalButton>
        <InternalButton
          background={`${theme.COLORS.PRIMARY}`}
          icon={<AddIcon fontSize="small" />}
          style={{
            alignSelf: isMobile ? "end" : "end",
            padding: "2px 8px",
          }}
          variant="outline"
          onClick={handleClickNewInvite}
        >
          Novo Convite
        </InternalButton>
      </Stack>
      <Container>
        <InviteList
          invites={invites}
          totalInvites={total}
          page={page}
          rowsPerPage={size}
          sortColumn={orderBy}
          isDesc={isDesc}
          setIsDesc={setIsDesc}
          onPageChange={onPageChange}
          onRowsPerPageChange={onRowsPerPageChange}
          onClickEdit={onClickEdit}
          onClickDelete={onClickDelete}
          onClickOrderBy={onClickOrderBy}
          onClickCopy={onClickCopy}
        />
      </Container>

      {isModalOpen && (
        <ModalNovaIndicacao
          handleCloseModal={handleCloseModal}
          initialFormValues={formValues}
        />
      )}
    </Stack>
  );
};

export default InvitesPage;
