import React from "react";
import { getSocialNetworkIcon } from "../utils/socialNetworkIcons";
import Text from "components/Text";
import * as S from "../styles";

interface SocialIconsContainerProps {
  socialNetworks: any[];
  socialLoading: boolean;
  socialError: string | null;
}

const SocialIconsContainer: React.FC<SocialIconsContainerProps> = ({
  socialNetworks,
  socialLoading,
  socialError,
}) => {
  return (
    <S.IconsContainer>
      {socialLoading && <Text>Carregando redes sociais...</Text>}
      {socialError && <Text color="red">{socialError}</Text>}

      {!socialLoading &&
        !socialError &&
        socialNetworks
          .filter((network) => network.social_network_username)
          .map((network) => (
            <a
              key={network.id}
              href={`https://${network.social_network_type.toLowerCase()}.com/${network.social_network_username}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {getSocialNetworkIcon(network.social_network_type)}
            </a>
          ))}
    </S.IconsContainer>
  );
};

export default SocialIconsContainer;
