import React, { useEffect, useState } from "react";
import { FormControl, TextField, Tooltip } from "@mui/material";
import { Button } from "antd";
import Text from "components/Text";
import SkeletonComponent from "../components/SkeletonComponent";
import { RiPencilFill } from "react-icons/ri";
import { useDebounce } from "hooks/useDebounce";
import { useSubmitForm } from "../useSubmitForm";
import { fetchFieldSavedData } from "../helpers/fetchFieldSavedData";
import { useUser } from "global/UserContext";
import { useEditStatus } from "global/EditStatusContext";
import * as I from "components/DynamicForm/types/dynamicFormTypes";
import * as S from "../styles";

interface TextFormFieldProps {
  field: I.TextField | I.TextAreaField;
}

const TextFormField: React.FC<TextFormFieldProps> = ({ field }) => {
  const { user } = useUser();
  const { setEditStatus } = useEditStatus();
  const [text, setText] = useState<string>("");
  const [isTextareaReadOnly, setIsTextareaReadOnly] = useState<boolean>(true);
  const debouncedText = useDebounce(text, 1000);

  const [prevLoading, setPrevLoading] = useState<boolean>(true);
  const [prevError, setPrevError] = useState<string | null>(null);

  const { submitForm, loading, error } = useSubmitForm();
  const textLimit = field.field_type === "textarea" ? 800 : 100;
  const user_id = user?.id;

  const debouncedEditStatus = useDebounce(text, 500);
  useEffect(() => {
    if (!isTextareaReadOnly && debouncedEditStatus.length <= textLimit) {
      setEditStatus(true, debouncedEditStatus);
    }
  }, [debouncedEditStatus]);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const data = await fetchFieldSavedData(field.id, user_id);
      if (data && data.length > 0) {
        const savedSubmission = data[0];
        if (savedSubmission && Array.isArray(savedSubmission.values)) {
          const savedValue = savedSubmission.values[0];
          setText(savedValue?.value || "");
        } else {
          console.warn(
            "savedSubmission or values is undefined:",
            savedSubmission,
          );
        }
      }
    } catch (error) {
      setPrevError("Error fetching saved data.");
      console.error(error);
    } finally {
      setPrevLoading(false);
    }
  };

  useEffect(() => {
    const submitData = async () => {
      try {
        await submitForm(field.id, { values: [{ value: debouncedText }] });
        setEditStatus(false, "");
      } catch (error) {
        console.error("Error submitting data:", error);
      }
    };

    if (!isTextareaReadOnly && debouncedText.length <= textLimit) {
      submitData();
    } else if (debouncedText.length > textLimit) {
      console.error("Text exceeds the allowed limit");
    }
  }, [debouncedText]);

  const handleEditClick = () => {
    setIsTextareaReadOnly(false);
    setEditStatus(true, debouncedText);
  };

  const handleTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setText(e.target.value);
  };

  const handleBlur = () => {
    setIsTextareaReadOnly(true);
  };

  if (prevLoading) {
    return <SkeletonComponent />;
  }

  if (prevError) {
    return <div>Error loading data</div>;
  }

  return (
    <S.Card key={field.id}>
      <Text size="medium" bold>
        {field.label}
      </Text>
      <FormControl fullWidth>
        <Tooltip
          title={
            isTextareaReadOnly ? (
              <Text size="xSmall">É necessário liberar o modo de edição</Text>
            ) : (
              ""
            )
          }
          arrow
        >
          <TextField
            required={field.is_required}
            placeholder={field.extras?.placeholders?.[0] || ""}
            value={text}
            onChange={handleTextChange}
            onBlur={handleBlur}
            fullWidth
            variant="outlined"
            error={text.length > textLimit}
            multiline={field.field_type === "textarea"}
            rows={7}
            style={{
              marginTop: "12px",
              cursor: isTextareaReadOnly ? "not-allowed" : "text",
            }}
            InputProps={{
              readOnly: isTextareaReadOnly,
            }}
            className="textfield"
          />
        </Tooltip>
        <div
          style={{
            width: "100%",
            display: "flex",
            placeContent: "space-between",
            placeItems: "center",
          }}
        >
          <Text size="small" color={text.length > textLimit ? "red" : "gray"}>
            {text.length}/{textLimit} caracteres
          </Text>
          {isTextareaReadOnly && (
            <Button
              icon={<RiPencilFill />}
              type="link"
              size="small"
              style={S.ButtonEditStyle}
              onClick={handleEditClick}
              className="edit-button"
            >
              Editar
            </Button>
          )}
        </div>
      </FormControl>
    </S.Card>
  );
};

export default TextFormField;
