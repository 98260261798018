import React from "react";
import { useNavigate } from "react-router-dom";
import Text from "components/Text";
import * as S from "./styles";

export const UnderConstruction: React.FC = () => {
  const history = useNavigate();
  const backDashboard = () => {
    history("/dashboard/cockpit");
  };

  return (
    <S.Wrapper>
      <S.Container>
        <S.Icon />
        <S.Title>
          <Text size="xSmall" bold>
            EM CONSTRUÇÃO...
          </Text>
        </S.Title>
      </S.Container>
    </S.Wrapper>
  );
};
