import styled from "styled-components";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import theme from "styles/theme";

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  margin-bottom: 16px;
`;

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  background-color: ${theme.COLORS.BACKGROUND_SECONDARY};
  border-radius: 8px;
  padding: 8px;
  gap: 8px;
`;

export const TextContainer = styled.div`
  display: flex;
  justify-content: start;
  flex-direction: column;
  align-items: start;
`;

export const Description = styled.div`
  display: flex;
  font-family: Roboto;
  font-size: 1rem;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.5px;
  text-align: left;
  color: ${theme.COLORS.GRAY_50};
`;

export const AcceptedContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const CheckIcon = styled(CheckCircleOutlineOutlinedIcon)`
  color: ${theme.COLORS.GREEN_1};
  font-size: 24px;
`;

export const ScrollableContent = styled.div`
  max-height: 300px;
  overflow-y: auto;
  padding: 16px;
  background-color: ${theme.COLORS.BACKGROUND_SECONDARY};
  border-radius: 8px;
  margin-top: 16px;

  .content-markdown {
    color: ${theme.COLORS.TEXT_PRIMARY};
    font-family: Roboto;
    font-size: 1rem;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    letter-spacing: 0.5px;

    a {
      color: ${theme.COLORS.PRIMARY};
      text-decoration: underline;
      &:hover {
        color: ${theme.COLORS.PRIMARY};
        opacity: 0.5;
      }
    }
  }
`;

export const ModalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: `${theme.COLORS.BACKGROUND_SECONDARY}`,
  boxShadow: 24,
  p: 4,
  borderRadius: 2,
};
