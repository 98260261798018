import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Controller } from "react-hook-form";
import ImageMain from "assets/images/login/logo-rainmakers.png";
import { ToastContainer, toast } from "react-toastify";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { TextField, IconButton, InputAdornment } from "@mui/material";
import PhoneInput from "react-phone-input-2";
import GlobalModal from "modals/GlobalModal/GlobalModal";
import Button from "components/Button";
import Text from "components/Text";
import useRegister from "./useRegister";
import "react-toastify/dist/ReactToastify.css";
import "react-phone-input-2/lib/style.css";
import { apiOrchestrator } from "services/apiService";
import { RegisterResponse } from "./types/registerTypes";
import { useIsMobile, useIsTablet, useIsDesktop } from "utils/mediaQueries";
import theme from "styles/theme";
import * as S from "./styles";

export const Register: React.FC = () => {
  const { control, handleSubmit, errors, onSubmit, loading } = useRegister();
  const navigate = useNavigate();
  const location = useLocation();

  const [inviteId, setInviteId] = useState<string | null>(null);
  const [showInviteModal, setShowInviteModal] = useState(false);
  const [inviteAction, setInviteAction] = useState<"accept" | "decline" | null>(
    null,
  );

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const invite = searchParams.get("invite_id");
    if (invite) {
      setInviteId(invite);
      setShowInviteModal(true);
    }
  }, [location.search]);

  const toggleShowPassword = () => setShowPassword(!showPassword);
  const toggleShowConfirmPassword = () =>
    setShowConfirmPassword(!showConfirmPassword);

  const handleInviteResponse = async (action: "accept" | "decline") => {
    setInviteAction(action);
    setShowInviteModal(false);

    if (action === "decline" && inviteId) {
      try {
        await respondToInvite(inviteId, null, "decline");
        toast.success("Convite recusado com sucesso.");
      } catch (error) {
        console.error("Erro ao recusar o convite:", error);
        toast.error("Erro ao recusar o convite.");
      }
    }
  };

  const handleFormSubmit = async (data: any) => {
    try {
      const response: RegisterResponse = await onSubmit(data);
      const userId = response.uuid;
      toast.success("Cadastro realizado com sucesso!");

      if (inviteId) {
        try {
          const inviteResponse = await respondToInvite(
            inviteId,
            userId,
            "accept",
          );

          console.log("Save inviteName Register invite: ", inviteResponse);

          if (
            inviteResponse &&
            inviteAction === "accept" &&
            inviteResponse.invite
          ) {
            const inviteeFullName = `${inviteResponse.invite.invitee_first_name} ${inviteResponse.invite.invitee_last_name}`;
            localStorage.setItem("inviteeName", inviteeFullName);
          }
        } catch (error) {
          console.error("Erro ao aceitar o convite:", error);
          toast.error("Erro ao aceitar o convite.");
        }
      }

      localStorage.setItem("isSuccessFinal", "true");
      navigate("/login");
    } catch (error: any) {
      let errorMessage =
        "Erro ao realizar o cadastro. Verifique os dados e tente novamente.";
      if (error.response && error.response.data && error.response.data.detail) {
        errorMessage = error.response.data.detail;
      }
      toast.error(errorMessage);
    }
  };

  const respondToInvite = async (
    inviteId: string,
    userId: string | null,
    action: "accept" | "decline",
  ) => {
    const url = `/invites/invite/${inviteId}/respond-invite?action=${action}${userId ? `&user_id=${userId}` : ""}`;
    const response = await apiOrchestrator.post(url);
    return response.data;
  };

  const isMobile = useIsMobile();
  const isTablet = useIsTablet();
  const isDesktop = useIsDesktop();

  return (
    <S.Background>
      <S.Container>
        <S.Content>
          <S.Card>
            <S.Image src={ImageMain} />
          </S.Card>
          <S.Form onSubmit={handleSubmit(handleFormSubmit)}>
            <Text size="xlarge" className="text-title">
              Registre-se em nossa plataforma!
            </Text>

            <S.ContentForm>
              <S.NameSurnameContainer>
                <Controller
                  name="firstName"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Nome"
                      variant="outlined"
                      fullWidth
                      error={!!errors.firstName}
                      helperText={errors.firstName?.message}
                      size={isMobile ? "small" : "medium"}
                    />
                  )}
                />

                <Controller
                  name="lastName"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Sobrenome"
                      variant="outlined"
                      fullWidth
                      error={!!errors.lastName}
                      helperText={errors.lastName?.message}
                      size={isMobile ? "small" : "medium"}
                    />
                  )}
                />
              </S.NameSurnameContainer>

              <Controller
                name="email"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="E-mail"
                    variant="outlined"
                    fullWidth
                    error={!!errors.email}
                    helperText={errors.email?.message}
                    size={isMobile ? "small" : "medium"}
                  />
                )}
              />

              <Controller
                name="phone"
                control={control}
                render={({ field }) => (
                  <PhoneInput
                    {...field}
                    country={"br"}
                    preferredCountries={["br", "us", "gb"]}
                    inputStyle={{
                      height: isMobile ? "2.8rem" : "4.2rem",
                    }}
                    buttonStyle={{
                      backgroundColor: theme.COLORS.BACKGROUND_SECONDARY,
                      border: `1px solid ${errors.phone ? theme.COLORS.RED_1 : theme.COLORS.GRAY_40}`,
                    }}
                    dropdownStyle={{
                      backgroundColor: theme.COLORS.BACKGROUND_PRIMARY,
                      color: theme.COLORS.TEXT_PRIMARY,
                    }}
                    placeholder="Digite seu WhatsApp"
                    inputProps={{
                      name: "phone",
                      required: true,
                      autoFocus: false,
                    }}
                    isValid={(value: string) =>
                      !!value.match(/^\+?[1-9]\d{1,14}$/)
                    }
                    specialLabel=""
                    inputClass={`phone-input ${errors.phone ? "error" : ""}`}
                  />
                )}
              />
              {errors.phone && (
                <span
                  style={{
                    color: `${theme.COLORS.RED_1}`,
                    fontSize: "10px",
                    marginLeft: "1rem",
                    marginTop: "-0.5rem",
                  }}
                >
                  {errors.phone.message}
                </span>
              )}

              <Controller
                name="password"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="Senha"
                    type={showPassword ? "text" : "password"}
                    variant="outlined"
                    fullWidth
                    error={!!errors.password}
                    helperText={errors.password?.message}
                    size={isMobile ? "small" : "medium"}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={toggleShowPassword}
                            edge="end"
                          >
                            {showPassword ? (
                              <VisibilityOff
                                style={{
                                  color: errors.password
                                    ? theme.COLORS.RED_1
                                    : "inherit",
                                }}
                              />
                            ) : (
                              <Visibility
                                style={{
                                  color: errors.password
                                    ? theme.COLORS.RED_1
                                    : "inherit",
                                }}
                              />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
              />

              <Controller
                name="confirmPassword"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="Confirme a Senha"
                    type={showConfirmPassword ? "text" : "password"}
                    variant="outlined"
                    fullWidth
                    error={!!errors.confirmPassword}
                    helperText={errors.confirmPassword?.message}
                    size={isMobile ? "small" : "medium"}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle confirm password visibility"
                            onClick={toggleShowConfirmPassword}
                            edge="end"
                          >
                            {showConfirmPassword ? (
                              <VisibilityOff
                                style={{
                                  color: errors.confirmPassword
                                    ? theme.COLORS.RED_1
                                    : "inherit",
                                }}
                              />
                            ) : (
                              <Visibility
                                style={{
                                  color: errors.confirmPassword
                                    ? theme.COLORS.RED_1
                                    : "inherit",
                                }}
                              />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
              />
            </S.ContentForm>

            <S.ButtonsContainer>
              <Button
                type="submit"
                variant="minimal"
                color="primary"
                size="medium"
                onClick={() => navigate("/login")}
                fullWidth
              >
                Voltar
              </Button>

              <Button
                type="submit"
                variant="primary"
                color="primary"
                fullWidth
                disabled={loading || !inviteId}
              >
                {loading ? "Carregando..." : "Registrar-se"}
              </Button>
            </S.ButtonsContainer>
          </S.Form>
        </S.Content>

        <ToastContainer position="top-right" autoClose={5000} />

        <GlobalModal
          open={showInviteModal}
          title="CONVITE DE REGISTRO"
          description="Você recebeu um convite para se registrar. Deseja aceitá-lo?"
          onClose={() => setShowInviteModal(false)}
          onConfirm={() => handleInviteResponse("accept")}
          confirmButtonText="Aceitar"
          cancelButtonText="Recusar"
          onCancel={() => handleInviteResponse("decline")}
        />
      </S.Container>
    </S.Background>
  );
};
