import React from "react";
import {
  IconFacebook,
  IconInstagram,
  IconYouTube,
  IconLinkedIn,
  IconTT,
  IconReddit,
  IconGoogle,
  IconTelegram,
} from "utils/utilsIcons";

export const getSocialNetworkIcon = (
  networkType: string,
): JSX.Element | null => {
  switch (networkType) {
    case "FACEBOOK":
      return React.createElement(IconFacebook);
    case "INSTAGRAM":
      return React.createElement(IconInstagram);
    case "LINKEDIN":
      return React.createElement(IconLinkedIn);
    case "YOUTUBE":
      return React.createElement(IconYouTube);
    case "X":
      return React.createElement(IconTT);
    case "REDDIT":
      return React.createElement(IconReddit);
    case "GOOGLE":
      return React.createElement(IconGoogle);
    case "TELEGRAM":
      return React.createElement(IconTelegram);
    default:
      return null;
  }
};
