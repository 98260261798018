import React from "react";
import * as S from "./styles";

interface Title {
  title: string;
}

const TitleFormComponent = ({ title }: Title) => {
  return <S.TitleSession>{title}</S.TitleSession>;
};

export default TitleFormComponent;
