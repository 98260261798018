import React from "react";
import { Button } from "antd";
import { IoWarningOutline } from "react-icons/io5";
import * as S from "../styles";
import theme from "styles/theme";

interface ErrorPageProps {
  title?: string;
  error?: string;
  message?: string;
  buttonText?: string;
  onButtonClick?: () => void;
}

const ErrorPage = ({
  title,
  error,
  message,
  buttonText,
  onButtonClick,
}: ErrorPageProps) => {
  const formattedMessage = message
    ? message.split(".").filter((part) => part.trim() !== "")
    : [];

  return (
    <S.Background>
      <S.Container>
        <S.IconWrapper>
          <IoWarningOutline color={theme.COLORS.YELLOW_1} />
        </S.IconWrapper>
        <S.Title>{title || "Erro!"}</S.Title>
        <S.ContentText>
          <S.Subtitle>{error || ""}.</S.Subtitle>
          <S.Description>
            {formattedMessage.length > 0
              ? formattedMessage.map((part, index) => (
                  <React.Fragment key={index}>
                    {part.trim()}.
                    <br />
                  </React.Fragment>
                ))
              : "Algo deu errado. Por favor, tente novamente."}
          </S.Description>
        </S.ContentText>
        {buttonText && onButtonClick && (
          <S.ButtonWrapper>
            <Button onClick={onButtonClick} type="primary">
              {buttonText}
            </Button>
          </S.ButtonWrapper>
        )}
      </S.Container>
    </S.Background>
  );
};

export default ErrorPage;
