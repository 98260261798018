import styled from "styled-components";
import theme from "styles/theme";
import { BREAKPOINTS } from "styles/spacing";
import "styles/global";

export const Background = styled.div`
  background: ${({ theme }) => theme.COLORS.BACKGROUND_PRIMARY};

  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 100;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 80%;
  padding: 0 5%;
  text-align: center;
  color: ${theme.COLORS.GRAY_50};
  z-index: 101;

  @media (min-width: ${BREAKPOINTS.WIDTH.MD}) {
    justify-content: end;
    align-items: start;
    text-align: start;
    padding: 0 0 10% 20%;
  }
`;

export const Title = styled.span`
  color: ${theme.COLORS.GRAY_50};
  font-family: Poppins;
  font-size: 1.5rem;
  text-align: center;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  @media (min-width: ${BREAKPOINTS.WIDTH.MD}) {
    text-align: start;
  }
`;

export const ContentText = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  margin-top: 2rem;

  @media (min-width: ${BREAKPOINTS.WIDTH.MD}) {
    justify-content: start;
    align-items: start;
  }
`;

export const Subtitle = styled.span`
  color: ${theme.COLORS.GRAY_50};
  font-family: Poppins;
  font-size: 1.5rem;
  font-weight: 700;
  text-align: center;

  @media (min-width: ${BREAKPOINTS.WIDTH.MD}) {
    text-align: center;
  }
`;

export const Description = styled.span`
  color: ${theme.COLORS.GRAY_50};
  font-family: Roboto;
  font-size: 1rem;
  text-align: center;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  max-width: 500px;

  @media (min-width: ${BREAKPOINTS.WIDTH.MD}) {
    text-align: start;
  }
`;

export const IconWrapper = styled.div`
  margin-bottom: 1.5rem;
  width: 60px;

  svg {
    width: 60px;
    height: 60px;
    fill: ${theme.COLORS.WARNING};
  }

  @media (min-width: ${BREAKPOINTS.WIDTH.MD}) {
    width: 3rem;

    svg {
      width: 3rem;
      height: 3rem;
    }
  }
`;

export const ButtonWrapper = styled.div`
  margin-top: 2rem;
  width: 100%;
  display: flex;
  justify-content: center;

  @media (min-width: ${BREAKPOINTS.WIDTH.MD}) {
    justify-content: start;
  }
`;
