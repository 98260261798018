import styled from "styled-components";
import "styles/global";

export const ModalContainer = styled.div`
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

export const ModalContent = styled.div`
  background-color: ${({ theme }) => theme.COLORS.BLACK_2};
  border-radius: 16px;
  max-width: 32rem;
  max-height: 90%;
  width: 90%;
  overflow-y: auto;
  text-align: left;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

  @media (max-width: 768px) {
    margin: 1rem;
    width: calc(100% - 2rem);
  }

  .content-text {
    border-top: 1px solid ${({ theme }) => theme.COLORS.TEXT_PRIMARY};
    padding: 1rem;

    > p {
      color: ${({ theme }) => theme.COLORS.TEXT_PRIMARY};
      font-family: Roboto, sans-serif;
      font-size: 1rem;
      font-weight: 400;
      line-height: 1.5rem;
      letter-spacing: 0.5px;
      max-width: 27.25rem;
      margin: 0 auto;
      text-align: center;
    }
  }
`;

export const ModalTitle = styled.div`
.title {
    color: ${({ theme }) => theme.COLORS.TEXT_PRIMARY};
    text-align: center;
    font-family: Roboto, sans-serif;
    font-size: 1.5rem;
    font-weight: 600;
    line-height: 2rem;
    text-transform: uppercase;
    margin: 1.5rem 0;
  }
`


export const ModalFooter = styled.div`s
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem;
    place-content:space-around;

    > button {
      width: 152px;
      height: 56px;
      border-color: ${({ theme }) => theme.COLORS.PRIMARY}; 
      } 

      .btn-no {
        color:  ${({ theme }) => theme.COLORS.PRIMARY}; 
      }
      
      .btn-yes {
      background-color: ${({ theme }) => theme.COLORS.PRIMARY}; 
    }

  `