import React from "react";
import Text from "components/Text";
import SocialNetworkField from "components/SocialNetworkField";
import * as S from "./styles";

interface SocialNetworkFieldsProps {
  networkTypes: string[];
}

const formatType = (type: string) =>
  type.charAt(0).toUpperCase() + type.slice(1).toLowerCase();

const SocialNetworkFields: React.FC<SocialNetworkFieldsProps> = ({
  networkTypes,
}) => {
  return (
    <S.SocialNetworkContainer>
      {networkTypes.map((type: string) => (
        <S.BoxSocialNetworkField key={type}>
          <Text size="medium">{`Perfil pessoal do ${formatType(type)}`}</Text>
          <S.ContentBoxSocialNetworkField>
            <Text
              size="xSmall"
              color="gray"
            >{`www.${formatType(type).toLowerCase()}.com/`}</Text>
            <SocialNetworkField
              socialNetworkType={type}
              placeholder={formatType(type)}
            />
          </S.ContentBoxSocialNetworkField>
        </S.BoxSocialNetworkField>
      ))}
    </S.SocialNetworkContainer>
  );
};

export default SocialNetworkFields;
