import React from "react";
import { Box } from "@mui/material";
import ScheduleOutlinedIcon from "@mui/icons-material/ScheduleOutlined";
import Text from "components/Text";
import theme from "styles/theme";
import { useEditStatus } from "global/EditStatusContext";

const SavingStatus: React.FC = () => {
  const { currentFormId } = useEditStatus();

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
      gap={0.5}
      color="red"
    >
      <ScheduleOutlinedIcon style={{ color: theme.COLORS.WARNING }} />
      <Text>Salvando alterações...</Text>
      {/* <Text>{currentFormId}</Text> */}
    </Box>
  );
};

export default SavingStatus;
