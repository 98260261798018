import React, { useRef } from "react";
import Text from "components/Text";
import ButtonSibBar from "components/ButtonSidebar";
import useOnClickOutside from "hooks/useOnClickOutside";
import * as S from "./styles";

interface IModalSubMenu {
  closeSubMenu: () => void;
  subMenu: any[];
  title: string;
  onSelectSubMenu: (path: string) => void;
  open: boolean;
  isSubMenuActive?: boolean;
}

const ModalSubMenu = ({
  closeSubMenu,
  subMenu,
  title,
  onSelectSubMenu,
  open,
  isSubMenuActive = false,
}: IModalSubMenu) => {
  const ref = useRef<HTMLDivElement>(null);

  useOnClickOutside(ref, () => {
    if (!isSubMenuActive) {
      closeSubMenu();
    }
  });

  const handleSubMenuClick = (path: string, disabled: boolean) => {
    if (disabled) return;
    onSelectSubMenu(path);
  };

  return (
    <S.Container ref={ref} open={open}>
      <div className="header">
        <Text color="primary" size="xSmall">
          {title}
        </Text>
      </div>
      {subMenu.map((menu, index) => (
        <div
          key={index}
          onClick={() => handleSubMenuClick(menu.path, !menu.isActive)}
        >
          <ButtonSibBar
            label={menu.label}
            path={menu.path}
            title={menu.title}
            icon={menu.icon}
            isActive={menu.isActive}
            disabled={!menu.isActive}
            subMenu={menu.subMenu || []}
            isSubMenu
          />
        </div>
      ))}
    </S.Container>
  );
};

export default ModalSubMenu;
