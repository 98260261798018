import React, { useMemo } from "react";
import { Card } from "@mui/material";
import Spin from "components/Spin";
import Empty from "components/Empty";
import CockpitCardMentions from "componentsNew/CockpitComponents/components/CockpitCardMentions";
import {
  formatDateTime,
  handleServiceColor,
  iconRedMens,
} from "utils/utilityFunctions";
import theme from "styles/theme";

interface ILoadingSpin {
  width?: string;
  height?: string;
}

const EmptyContainer = ({ width = "100%", height = "50vh" }: ILoadingSpin) => {
  return (
    <Card
      style={{
        display: "flex",
        background: theme.COLORS.BACKGROUND_PRIMARY,
        border: "none",
        width: width,
        height: height,
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Empty />
    </Card>
  );
};

const MentionsList = ({ mentions, loading }: any) => {
  const renderMentions = useMemo(() => {
    if (!mentions || mentions.length === 0) {
      return null;
    }

    return mentions?.map((mention: any, index: any) => {
      const service = mention?.parent_name;
      const serviceData = handleServiceColor(service);

      return (
        <div style={{ gap: "1rem" }}>
          <CockpitCardMentions
            key={index}
            id={mention?.id_mention}
            color={serviceData?.color || "defaultColor"}
            iconSocial={iconRedMens(mention.page_type_name).icon}
            snippet={mention.snippet}
            message={mention.full_text}
            author={mention.author}
            dataCreate={formatDateTime(mention.added)}
            iconServices={serviceData?.icon}
            pageTypeName={mention.page_type_name}
            url={mention?.url}
            impact={mention.impact}
            quotesCount={mention.quotes_count}
            likesCount={mention.likes_count}
            sharesCount={mention.shares_count}
            impressionsCount={mention.impressions_count}
            interactionsCount={mention.interactions_count}
            bookmarksCount={mention.bookmarks_count}
            postsCount={mention.posts_count}
            engagementsCount={mention.engagements_count}
            imageInfo={mention.image_info}
            sentiment={mention.sentiment}
            fullWidth
          />
        </div>
      );
    });
  }, [mentions]);

  return (
    <>
      {mentions.length > 0 ? (
        <>
          {loading && <Spin />}
          {renderMentions}
        </>
      ) : (
        <EmptyContainer />
      )}
    </>
  );
};

export default MentionsList;
