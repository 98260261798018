import PlenaryRoomImage from "assets/images/rooms/PlenaryRoom.jpg";
import PersonalDevelopmentImage from "assets/images/rooms/PersonalDevelopment.jpg";
import SalesAndExpansionImage from "assets/images/rooms/SalesAndExpansion.jpg";
import CRMImage from "assets/images/rooms/CRM.jpg";
import TechnologyImage from "assets/images/rooms/Technology.jpg";
import PeopleManagementImage from "assets/images/rooms/PeopleManagement.jpg";
import CLevelImage from "assets/images/rooms/CLevels.jpg";

export const backgroundImages: Record<string, string> = {
  plenaryRoom: PlenaryRoomImage,
  personalDevelopment: PersonalDevelopmentImage,
  salesAndExpansion: SalesAndExpansionImage,
  crm: CRMImage,
  technology: TechnologyImage,
  peopleManagement: PeopleManagementImage,
  cLevel: CLevelImage,
};
