export const SearchService = {
  height: "1.5rem",
  "& .MuiInputBase-root": {
    minHeight: "1.5rem",
    padding: "2px",
  },
  "& .MuiInputBase-input": {
    height: "1.5rem",
    padding: "0 8px",
    fontSize: "0.8rem",
  },
};
