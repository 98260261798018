import React, { useEffect, useState } from "react";
import { TextField, Autocomplete } from "@mui/material";
import Spin from "components/Spin";
import { apiOrchestrator } from "services/apiService";

interface Member {
    user: {
        id: string;
        first_name: string;
        last_name: string;
    };
}
interface MappedMember {
    label: string;
    id: string;
}

interface ToUserSelectionComponentProps {
    setSelectedUser: (user: MappedMember | null) => void;
}

const ToUserSelectionComponent: React.FC<ToUserSelectionComponentProps> = ({ setSelectedUser }) => {
    const [members, setMembers] = useState<MappedMember[]>([]);
    const [loading, setLoading] = useState<boolean>(true);

    useEffect(() => {
        fetchMembers();
    }, []);

    const fetchMembers = async () => {
        setLoading(true);
        try {
            const response = await apiOrchestrator.get("/user_to_organization/members");
            const mappedMembers: MappedMember[] =
                response?.data?.map((d: Member) => ({
                    label: `${d.user.first_name} ${d.user.last_name}`,
                    id: d.user.id,
                })) || [];
            setMembers(mappedMembers);
        } catch (error) {
            console.error("Error fetching members:", error);
        } finally {
            setLoading(false);
        }
    };

    if (loading) {
        return <Spin />;
    }

    return (
        <Autocomplete
            // disablePortal
            options={members}
            sx={{ width: "100%" }}
            onChange={(event, newValue) => {
                setSelectedUser(newValue);
            }}
            getOptionLabel={(option) => option?.label || ""}
            renderInput={(params) => <TextField {...params} label="Pesquise pelo nome e sobrenome" />}
        />
    );
};

export default ToUserSelectionComponent;
