import React from "react";
import Text from "components/Text";
import { MdRadioButtonChecked, MdRadioButtonUnchecked } from "react-icons/md";
import { useFilterGlobal } from "hooks/useFilterGlobal";
import { ALL, POSITIVE, NEGATIVE } from "utils/constants";
import theme from "styles/theme";
import { ContainerRayX } from "./styles";

const FilterSentiment = () => {
  const { filterRaioX, setFilterRaioX } = useFilterGlobal();
  const handleFilter = (filter: string) => {
    return filterRaioX === filter;
  };

  const isActiveAll = handleFilter(ALL);
  const isActivePositive = handleFilter(POSITIVE);
  const isActiveNegative = handleFilter(NEGATIVE);

  return (
    <ContainerRayX>
      <Text size="medium" bold color="yellow">
        Filtros de Escuta Política
      </Text>
      <button
        type="button"
        className={isActiveAll ? "rayX" : "rayXActive"}
        onClick={() => setFilterRaioX(ALL)}
      >
        {!isActiveAll ? (
          <MdRadioButtonUnchecked size={20} color="white" />
        ) : (
          <MdRadioButtonChecked
            color={isActiveAll ? theme.COLORS.YELLOW_1 : "white"}
            size={20}
          />
        )}
        <Text
          size="xSmall"
          bold={isActiveAll}
          color={isActiveAll ? "black" : "white"}
        >
          Tudo
        </Text>
      </button>
      <button
        type="button"
        className={isActivePositive ? "rayX" : "rayXActive"}
        onClick={() => setFilterRaioX(POSITIVE)}
      >
        {!isActivePositive ? (
          <MdRadioButtonUnchecked size={20} color="white" />
        ) : (
          <MdRadioButtonChecked
            color={isActivePositive ? theme.COLORS.YELLOW_1 : "white"}
            size={20}
          />
        )}

        <Text
          size="xSmall"
          bold={isActivePositive}
          color={isActivePositive ? "black" : "white"}
        >
          Menções Positivas
        </Text>
      </button>
      <button
        type="button"
        className={isActiveNegative ? "rayX" : "rayXActive"}
        onClick={() => setFilterRaioX(NEGATIVE)}
      >
        {!isActiveNegative ? (
          <MdRadioButtonUnchecked size={20} color="white" />
        ) : (
          <MdRadioButtonChecked
            color={isActiveNegative ? theme.COLORS.YELLOW_1 : "white"}
            size={20}
          />
        )}

        <Text
          size="xSmall"
          bold={isActiveNegative}
          color={isActiveNegative ? "black" : "white"}
        >
          Menções Negativas
        </Text>
      </button>
    </ContainerRayX>
  );
};

export default FilterSentiment;
