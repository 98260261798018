const useImages = () => {
  const images = [
    {
      id: 1,
      src: require("assets/sponsors/carousel/BANNER.png"),
      title: "Evyan Investimentos",
      link: "https://evyaninvest.com.br/",
    },
    {
      id: 2,
      src: require("assets/sponsors/carousel/BANNER_CAMARGO.png"),
      title: "Camargo",
      link: "https://www.camargoalfaiataria.com.br/",
    },
    {
      id: 3,
      src: require("assets/sponsors/carousel/BANNER_LEMMO.jpg"),
      title: "Lemmo",
      link: "https://ig.rdstation.com/lemmocorretora?fbclid=PAZXh0bgNhZW0CMTEAAab_1_PVq3Cd44q4KuTUJfkGZOtCzXqOH40Gd8YfAhUs9J159WVFSIy9tz0_aem_NGtYcCdSHCtqSl1msNHi4w",
    },
    {
      id: 4,
      src: require("assets/sponsors/carousel/BANNER_LAGOSTAO.jpeg"),
      title: "Lagostão",
      link: "https://www.instagram.com/lagostaopescados?igsh=enU1d3ozdGg1NzF1",
    },
    {
      id: 5,
      src: require("assets/sponsors/carousel/Tecnologia para Negócios.gif"),
      title: "Não Arrisque o Seu Negócio",
      link: "https://www.algoricca.com",
    },
    {
      id: 6,
      src: require("assets/sponsors/carousel/Profissionais de TI.gif"),
      title: "Profissionais de TI Sob Demanda",
      link: "https://www.algoricca.com",
    },
    {
      id: 7,
      src: require("assets/sponsors/carousel/Segurança Digital.gif"),
      title: "Segurança Digital Garantida",
      link: "https://www.algoricca.com",
    },
    {
      id: 8,
      src: require("assets/sponsors/carousel/Negócio Inteligente.gif"),
      title: "Seu Negócio Mais Inteligente",
      link: "https://www.algoricca.com",
    },
    {
      id: 9,
      src: require("assets/sponsors/carousel/Squads Completas.gif"),
      title: "Squads Completas",
      link: "https://www.algoricca.com",
    },
    {
      id: 10,
      src: require("assets/sponsors/carousel/Sua ideia Nossa equipe.gif"),
      title: "Sua Ideia, Nossa Equipe",
      link: "https://www.algoricca.com",
    },
    {
      id: 11,
      src: require("assets/sponsors/carousel/Fábrica de Software.gif"),
      title: "Fábrica de Software",
      link: "https://www.algoricca.com",
    },
    {
      id: 12,
      src: require("assets/sponsors/carousel/Soluções Mobile.gif"),
      title: "Experiências Mobile Excepcionais",
      link: "https://www.algoricca.com",
    },
    {
      id: 13,
      src: require("assets/sponsors/carousel/Inteligência Artificial.gif"),
      title: "Inteligência Artificial",
      link: "https://www.algoricca.com",
    },
    // {
    //   id: 14,
    //   src: require("assets/sponsors/carousel/Apps Gamificados.gif"),
    //   title: "Aplicativos Gamificados",
    //   link: "https://www.algoricca.com",
    // },
    {
      id: 14,
      src: require("assets/sponsors/carousel/LeadMatch.gif"),
      title: "LeadMatch",
      link: "https://www.algoricca.com",
    },
  ];

  return { images };
};

export default useImages;
