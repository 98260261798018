import React from "react";
import { Navigate } from "react-router-dom";
import { useUser } from "global/UserContext";
import { ROUTES } from "utils/constants";

interface PublicRouteProps {
  children: JSX.Element;
}

export const PublicRoute: React.FC<PublicRouteProps> = ({ children }) => {
  const { token } = useUser();

  if (token) {
    return <Navigate to={ROUTES.ROOT} replace />;
  }

  return children;
};
