import React, { useEffect, useState } from "react";
import Spin from "components/Spin";
import { Paper, MenuItem, Rating, IconButton, FormControl, InputLabel } from "@mui/material";
import Select, { SelectChangeEvent } from '@mui/material/Select';

import { SvgIconComponent } from "@mui/icons-material";


import Text from "components/Text";
import { apiNetworking } from "services/apiService";
import * as S from "./styles";

import { Stack } from "@mui/system";

import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';

import { useUser } from "global/UserContext";
import { UserPicture } from "./UserPicture";
import GeneralConfirmationModal from "modals/GeneralConfirmationModal";


interface MappedMember {
    label: string;
    id: string;
}

interface ValueLabelField {
    value: string;
    label: string;
}

interface EdgeTypeExtras {
    values: ValueLabelField[];
}

interface EdgeType {
    code: string;
    extras: EdgeTypeExtras | null;
    label: string;
    weight: number;
}

interface InputComponentProps {
    selectedUser: MappedMember;
    commonEdgeTypes: string[];
}

export const InputComponent: React.FC<InputComponentProps> = ({ selectedUser, commonEdgeTypes }) => {
    const { user } = useUser()

    const [loading, setLoading] = useState<boolean>(true);

    const [selectedEdgeType, setSeletedEdgeType] = useState<string>("")

    const [connectionLevelEdge, setConnectionLevelEdge] = useState<EdgeType | null>(null);
    const [selectedConnectionLevel, setSelectedConnectionLevel] = useState<string | null>(null);

    const [connectionReasonEdge, setConnectionReasonEdge] = useState<EdgeType | null>(null);
    const [selectedConnectionReason, setSelectedConnectionReason] = useState<string | null>(null);

    const [isModalOpen, setIsModalOpen] = useState<boolean>(false)
    const [modalText, setModalText] = useState<string>("")

    const [isFeedbackModalOpen, setIsFeedbackModalOpen] = useState<boolean>(false)
    const [feedbackModalText, setFeedbackModalText] = useState<string>("")

    const [isLodingPost, setIsLoadingPost] = useState<boolean>(false)

    useEffect(() => {
        fetchEdgeTypes();
    }, []);



    const fetchEdgeTypes = async () => {
        setLoading(true);
        try {
            const response = await apiNetworking.get("/v1/edge/types");

            const edgeTypesResponse: EdgeType[] = response.data;

            const levels = edgeTypesResponse.find((e) => e.code === "CONNECTION_LEVEL");
            setConnectionLevelEdge(levels || null);

            const reasons = edgeTypesResponse.find((e) => e.code === "CONNECTION_REASON");
            setConnectionReasonEdge(reasons || null);
        } catch (error) {
            console.error("Erro ao buscar tipos de arestas:", error);
        } finally {
            setLoading(false);
        }
    };


    const handleChange = (event: SelectChangeEvent<string>) => {
        const { name, value } = event.target;
        switch (name) {
            case "CONNECTION_LEVEL":
                setSelectedConnectionLevel(value)
                postEdge(name, value.toString())
                break;
            case "CONNECTION_REASON":
                setSelectedConnectionReason(value);
                postEdge(name, value.toString())
                break;
            case "CONNECTED":
                setSeletedEdgeType("CONNECTED")
                setModalText("Deseja realmente se conectar com esta pessoa?")
                setFeedbackModalText("A conexão foi enviada com sucesso!")
                setIsModalOpen(true)
                break;
            case "SCHEDULED":
                setSeletedEdgeType("SCHEDULED")
                setModalText("Deseja realmente registrar que você quer agendar uma reunião com esta pessoa?")
                setFeedbackModalText("Agendamento solicitado. Equipe Rainmakers entrará em contato para data e horário.")
                setIsModalOpen(true)
                break;
            case "WHATSAPP":
                setSeletedEdgeType("WHATSAPP")
                setModalText("Você realmente trocou Whastapp com esta pessoa?")
                setFeedbackModalText("A conexão foi enviada com sucesso!")
                setIsModalOpen(true)
                break;
            case "RATED":
                postEdge(name, value)
                break;
            default:
                break;
        }
    };

    const handleConfirm = async () => {
        try {
            postEdge(selectedEdgeType)
        } catch {
            alert("Erro ao salvar informação. Contate o suporte.")
        }
    }


    const postEdge = async (type_code: string, value: string | null = null) => {
        setIsLoadingPost(true)
        try {
            const from_user_uuid = user?.id
            const to_user_uuid = selectedUser?.id

            const data = {
                from_user_uuid,
                to_user_uuid,
                type_code,
                value,
            }

            const response = await apiNetworking.post("/v1/edge/create", data);
        } catch (error) {
            console.error("Erro ao salvar aresta:", error);
        }
        finally {
            setIsLoadingPost(false)
            setIsModalOpen(false)
            setIsFeedbackModalOpen(true)
        }
    }

    if (loading) {
        return <Spin />;
    }

    return (
        <>
            <Stack gap={1}>
                <Paper>
                    <S.ProfilePictureWrapper>
                        <UserPicture selectedUser={selectedUser} />
                    </S.ProfilePictureWrapper>
                    <Stack direction={"column"} gap={1}>
                        <FormControl>
                            <InputLabel id="demo-simple-select-helper-label">Selecione o nível da conexão</InputLabel>
                            <Select
                                value={selectedConnectionLevel || ""}
                                label={<Text color="white">Selecione o nível da conexão</Text>}
                                name="CONNECTION_LEVEL"
                                onChange={handleChange}
                            >
                                {connectionLevelEdge?.extras?.values.map((v) => (
                                    <MenuItem key={v.value} value={v.value}>
                                        {v.label}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <FormControl>
                            <InputLabel id="demo-simple-select-helper-label">Selecione o motivo da conexão</InputLabel>
                            <Select
                                value={selectedConnectionReason || ""}
                                label="Selecione o motivo da conexão"
                                name="CONNECTION_REASON"
                                onChange={handleChange}
                            >
                                {connectionReasonEdge?.extras?.values.map((v) => (
                                    <MenuItem key={v.value} value={v.value}>
                                        {v.label}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Stack>

                </Paper>
                <Paper>
                    <Stack direction={"column"} gap={1} sx={{ alignItems: "center" }}>
                        <Text size="large">Que nota você daria para este membro?</Text>
                        <Rating size="large" sx={{ scale: "1.5" }} onChange={(event, newValue) =>
                            handleChange({ target: { name: "RATED", value: newValue?.toString() || "" } } as SelectChangeEvent)} />
                    </Stack>
                </Paper>
                <Paper sx={{ display: "flex", justifyContent: "space-around" }}>
                    <ButtonEdges
                        ariaLabel="connected"
                        label="Conectar"
                        icon={PersonAddAlt1Icon}
                        onClick={() => handleChange({ target: { name: "CONNECTED", value: "CONNECTED" } } as SelectChangeEvent)}
                    />
                    <ButtonEdges
                        ariaLabel="scheduled"
                        label="Agendar"
                        icon={CalendarMonthIcon}
                        onClick={() => handleChange({ target: { name: "SCHEDULED", value: "SCHEDULED" } } as SelectChangeEvent)}
                    />
                    <ButtonEdges
                        ariaLabel="whatsapp"
                        label="WhatsApp"
                        icon={WhatsAppIcon}
                        onClick={() => handleChange({ target: { name: "WHATSAPP", value: "WHATSAPP" } } as SelectChangeEvent)}
                    />
                </Paper>
            </Stack>

            <GeneralConfirmationModal
                variant="normal"
                isOpen={isModalOpen}
                loading={isLodingPost}
                onClose={() => setIsModalOpen(false)}
                text={modalText}
                onConfirm={handleConfirm}   
            />
            <GeneralConfirmationModal
                variant="justClose"
                isOpen={isFeedbackModalOpen}
                loading={false}
                onClose={() => setIsFeedbackModalOpen(false)}
                text={feedbackModalText}
                onConfirm={() => setIsFeedbackModalOpen(false)}
            />
        </>
    );
};


interface ButtonEdgesProps {
    ariaLabel: string;
    icon: SvgIconComponent; // Icon component type
    label: string;
    onClick: () => void; // Callback for click event
}

const ButtonEdges: React.FC<ButtonEdgesProps> = ({ ariaLabel, icon: Icon, label, onClick }) => {
    return (
        <IconButton aria-label={ariaLabel} onClick={onClick}>
            <Stack direction="column" sx={{ alignItems: "center" }}>
                <Icon fontSize="large" sx={{ fontSize: 40 }} />
                <Text size="medium">{label}</Text>
            </Stack>
        </IconButton>
    );
};