import styled, { css, keyframes } from "styled-components";

const fadeIn = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`;

const fadeOut = keyframes`
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
`;

export const TransitionContainer = styled.div<{ in: boolean }>`
  animation: ${(props) =>
    props.in
      ? css`
          ${fadeIn} 500ms ease-in
        `
      : css`
          ${fadeOut} 500ms ease-in
        `};
`;
