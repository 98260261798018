import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Divider,
} from "@mui/material";
import Button from "components/Button";
import theme from "styles/theme";

interface ConfirmLogoutModalProps {
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
}

const ConfirmLogoutModal: React.FC<ConfirmLogoutModalProps> = ({
  open,
  onClose,
  onConfirm,
}) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: {
          background: theme.COLORS.BACKGROUND_SECONDARY,
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
          width: "340px",
          borderRadius: "16px",
        },
      }}
    >
      <DialogTitle
        sx={{
          textAlign: "center",
          color: theme.COLORS.TEXT_PRIMARY,
          fontSize: 18,
        }}
      >
        CONFIRMAÇÃO
      </DialogTitle>
      <Divider />
      <DialogContent>
        <Typography>Deseja realmente sair da plataforma?</Typography>
      </DialogContent>
      <DialogActions sx={{ marginTop: "2rem" }}>
        <Button onClick={onClose} variant="outline" color="inherit" fullWidth>
          Cancelar
        </Button>
        <Button
          onClick={() => {
            onClose();
            onConfirm();
          }}
          variant="primary"
          color="warning"
          fullWidth
        >
          Sair
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmLogoutModal;
