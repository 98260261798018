export const DataProtectionTerm = {
  title: "Lei Geral de Proteção de Dados",
  subtitle: "Termo de consentimento e Autorização para uso de dados e imagem",
  description:
    "Ao fornecer suas informações pessoais, você declara que leu, compreendeu e concorda com os termos deste documento, autorizando a coleta, armazenamento, uso e tratamento de seus dados pessoais, em conformidade com a Lei Geral de Proteção de Dados (Lei nº 13.709/2018 - LGPD).",
  content: `## Termo de Consentimento e Autorização para Uso de Dados e Imagem

Ao fornecer suas informações pessoais, você declara que leu, compreendeu e concorda com os termos deste documento, autorizando a coleta, armazenamento, uso e tratamento de seus dados pessoais, em conformidade com a Lei Geral de Proteção de Dados (Lei nº 13.709/2018 - LGPD).

### 1. Finalidade da Coleta de Dados
Seus dados pessoais, incluindo nome, e-mail, informações de perfil, e respostas fornecidas neste formulário, serão coletados para os seguintes propósitos:

- Análise e aprimoramento dos nossos serviços e produtos.
- Criação de perfis estatísticos e de comportamento, visando à melhor personalização de conteúdos.
- Comunicação direta para envio de informações relevantes, promoções, convites para eventos e atualizações.
- Utilização de sua imagem, quando fornecida, para fins institucionais, de divulgação e publicidade, sempre com o devido respeito à sua integridade.

### 2. Uso de Imagem
Ao fornecer sua foto ou qualquer material audiovisual, você autoriza sua utilização em materiais institucionais, campanhas publicitárias, eventos e divulgações relacionadas aos nossos serviços, tanto em meio digital quanto físico. Essa autorização é concedida a título gratuito, abrangendo, inclusive, a reprodução em sites, redes sociais, e outros canais de comunicação.

### 3. Segurança e Proteção dos Dados
Seus dados pessoais serão mantidos em ambiente seguro, protegido por medidas técnicas e administrativas adequadas para evitar acesso não autorizado, perda, destruição ou alteração dos dados.

### 4. Compartilhamento de Dados
Seus dados pessoais não serão compartilhados com terceiros sem sua autorização prévia, exceto quando necessário para cumprir obrigações legais ou regulatórias, ou quando expressamente solicitado pelas autoridades competentes.

### 5. Direitos do Titular
Você tem o direito de, a qualquer momento, solicitar:

- Acesso aos seus dados pessoais coletados e tratados.
- Correção de dados incompletos, inexatos ou desatualizados.
- Anonimização, bloqueio ou eliminação de dados desnecessários, excessivos ou tratados em desconformidade com a legislação.
- Revogação do consentimento para uso de imagem e dados pessoais, mediante solicitação formal.

### 6. Tempo de Retenção dos Dados
Os dados coletados serão mantidos pelo período necessário para o cumprimento das finalidades descritas ou conforme exigido por lei. Após esse período, serão eliminados com segurança.

### 7. Alterações no Termo de Consentimento
Reservamo-nos o direito de alterar este Termo de Consentimento a qualquer momento. Qualquer alteração será comunicada previamente, com destaque para as mudanças significativas.

### 8. Contato
Para exercer seus direitos ou tirar dúvidas sobre o tratamento de seus dados pessoais, entre em contato conosco através do e-mail: [raul.seixas@algobossa.com](mailto:raul.seixas@algobossa.com)
`,

  version: "v.1.3",
};
