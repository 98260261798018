import React from "react";
import { Modal, Button, Image } from "antd";
import Text from "components/Text";
import theme from "styles/theme";
import * as S from "../styles";

interface RemoveModalProps {
  isRemoveModalVisible: boolean;
  loading: boolean;
  profileImage: string | null;
  handleRemoveImage: () => void;
  handleCloseRemoveModal: () => void;
}

const RemoveModal: React.FC<RemoveModalProps> = ({
  isRemoveModalVisible,
  loading,
  profileImage,
  handleRemoveImage,
  handleCloseRemoveModal,
}) => {
  return (
    <Modal
      open={isRemoveModalVisible}
      onCancel={handleCloseRemoveModal}
      closable
      maskClosable
      footer={null}
      title="CONFIRMAR REMOÇÃO DE IMAGEM"
      width={400}
      style={{
        alignItems: "center",
        justifyContent: "center",
        textAlign: "center",
      }}
    >
      <S.UpdateContainer>
        <Text size="medium" bold color="yellow">
          Deseja realmente remover a foto de perfil?
        </Text>
        {profileImage && (
          <Image
            src={profileImage}
            alt="Profile Image to Remove"
            style={{ width: 150, height: 150, borderRadius: "50%" }}
          />
        )}
        <div style={{ marginTop: 16 }}>
          <Button
            onClick={handleCloseRemoveModal}
            style={{
              margin: 8,
              color: `${theme.COLORS.PRIMARY}`,
              backgroundColor: "transparent",
              border: `1px solid ${theme.COLORS.PRIMARY}`,
            }}
          >
            Cancelar
          </Button>
          <Button
            type="primary"
            block
            danger
            onClick={handleRemoveImage}
            loading={loading}
            style={{
              width: "auto",
              margin: 8,
              color: `${theme.COLORS.WHITE}`,
              backgroundColor: `${theme.COLORS.ERROR}`,
              border: "none",
            }}
          >
            Confirmar remoção
          </Button>
        </div>
      </S.UpdateContainer>
    </Modal>
  );
};

export default RemoveModal;
