import styled from "styled-components";
import theme from "styles/theme";

export const FormContainer = styled.div`
  width: 100%;
  background-color: ${theme.COLORS.BACKGROUND_SECONDARY};
  padding: 8px;
  border-radius: 8px;
  margin-top: 16px;

  h2 {
    color: ${theme.COLORS.BACKGROUND_SECONDARY};
  }

  .MuiFormLabel-root {
    color: ${theme.COLORS.GRAY_10};
  }

  .MuiInputBase-root {
    color: ${theme.COLORS.GRAY_10};
  }

  .MuiInputLabel-root {
    color: ${theme.COLORS.GRAY_10};
  }

  .MuiOutlinedInput-root {
    fieldset {
      border-color: ${theme.COLORS.GRAY_10};
      opacity: 0.4;
    }

    &:hover fieldset {
      border-color: ${theme.COLORS.WHITE};
    }

    &.Mui-focused fieldset {
      border-color: ${theme.COLORS.WHITE};
    }

    &.Mui-disabled {
      cursor: not-allowed;

      fieldset {
        border-color: ${theme.COLORS.GRAY_80} !important;
      }
    }
  }
`;
