import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Divider,
} from "@mui/material";
import Button from "components/Button";
import theme from "styles/theme";

interface MeetingRoomModalProps {
  open: boolean;
  roomTitle: string;
  onCancel: () => void;
  onConfirm: () => void;
}

const MeetingRoomModal: React.FC<MeetingRoomModalProps> = ({
  open,
  roomTitle,
  onCancel,
  onConfirm,
}) => {
  return (
    <Dialog
      open={open}
      onClose={onCancel}
      PaperProps={{
        sx: {
          background: theme.COLORS.BACKGROUND_SECONDARY,
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
          width: "340px",
          borderRadius: "16px",
        },
      }}
    >
      <DialogTitle
        sx={{
          textAlign: "center",
          color: theme.COLORS.TEXT_PRIMARY,
          fontSize: 18,
        }}
      >
        CONFIRMAÇÃO
      </DialogTitle>
      <Divider />
      <DialogContent>
        <Typography>
          Deseja realmente entrar na reunião "<strong>{roomTitle}</strong>"?
        </Typography>
      </DialogContent>
      <DialogActions sx={{ padding: "2rem" }}>
        <Button onClick={onCancel} variant="outline" color="inherit" fullWidth>
          Cancelar
        </Button>
        <Button onClick={onConfirm} variant="primary" color="success" fullWidth>
          Confirmar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default MeetingRoomModal;
