import styled from "styled-components";
import theme from "styles/theme";

export const Content = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 12rem;
  padding-bottom: 1rem;
`;

export const StyledCard = styled.div<{ isDropdownActive: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 16px;
  border-radius: 8px;
  background-color: ${theme.COLORS.BACKGROUND_SECONDARY};
  width: 100%;
  height: ${({ isDropdownActive }) => (isDropdownActive ? "auto" : "56px")};
  overflow: hidden;
  transition: height 0.3s ease;
`;

export const DropdownHeader = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 8px 24px;
  cursor: pointer;
`;

export const HeaderText = styled.div`
  display: flex;
  align-items: end;
  gap: 6px;

  .seven-days {
    height: 12px;
    font-family: Roboto;
    font-size: 10px;
    font-weight: 300;
    line-height: 11.72px;
    color: ${theme.COLORS.TEXT_PRIMARY};
  }
`;

export const DropdownToggle = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  gap: 8px;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;

export const FiltersHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  gap: 8px;
  padding: 8px;
`;

export const SocialAndMentions = styled.div`
  width: 60%;
  padding: 8px;
`;

export const TotalAndClouds = styled.div`
  display: flex;
  width: 40%;
  flex-direction: column;
  margin: 88px 8px 8px 0;
  gap: 8px;
`;

export const MentionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  height: auto;
`;

export const TotalMentions = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const TotalsCountContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  gap: 8px;
`;

export const Clouds = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 8px;
`;

export const ContainerWord = styled.div`
  flex: 1;
  width: auto;
  height: auto;
  display: flex;
  place-content: center;
  place-items: center;
`;

export const ListIcon = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  align-items: center;
  margin-bottom: 8px;
  height: 56px;
  gap: 2px;
`;

export const SortingHeader = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 16px;
`;

export const TimeDropdown = styled.div`
  position: relative;
  display: inline-block;
`;

export const DropdownContent = styled.div`
  display: none;
  position: absolute;
  justify-self: self-start;
  align-self: self-start;
  margin-left: -64px;
  background-color: ${theme.COLORS.BACKGROUND_SECONDARY};
  min-width: 220px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  border-radius: 8px;
  padding: 8px;
  border: 1px solid ${theme.COLORS.GRAY_10};
  ${TimeDropdown}:hover & {
    display: block;
  }
`;

export const DropdownItem = styled.div`
  color: white;
  padding: 8px 16px;
  text-align: left;
  cursor: pointer;

  &:hover {
    background-color: ${theme.COLORS.BACKGROUND_PRIMARY};
    border-radius: 8px;
  }
`;

export const Pagination = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  width: 100%;
`;

export const PageArrow = styled.button<{ disabled: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px;
  border: none;
  background-color: transparent;
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
  color: ${({ disabled }) =>
    disabled ? `${theme.COLORS.GRAY_10}` : `${theme.COLORS.WHITE}`};

  &:hover {
    color: ${({ disabled }) =>
      disabled ? `${theme.COLORS.GRAY_10}` : `${theme.COLORS.PRIMARY}`};
  }

  &:disabled {
    cursor: not-allowed;
  }
`;

export const PageNumber = styled.span<{ active: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 4px;
  padding: 12px;
  border-radius: 50%;
  width: 16px;
  height: 16px;
  cursor: pointer;
  font-size: ${theme.FONT_SIZE.MEDIUM};
  color: ${({ active }) =>
    active ? `${theme.COLORS.TEXT_PRIMARY}` : `${theme.COLORS.WHITE}`};
  background-color: ${({ active }) =>
    active ? `${theme.COLORS.BACKGROUND_PRIMARY}` : `transparent`};
  font-weight: ${({ active }) => (active ? "bold" : "normal")};
  &:hover {
    font-weight: ${theme.FONT_WEIGHT.BOLD};
    text-decoration: none;
    background-color: ${theme.COLORS.PRIMARY};
    color: ${theme.COLORS.BLACK};
  }
`;
