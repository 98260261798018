import { ReactComponent as IconAnalystAdvisor } from "assets/advisors/icon-analyst-advisor.svg";

export const mapSidebarMentorship = [
  {
    name: "Mentoria",
    type: "advisor",
    title: ``,
    icon: IconAnalystAdvisor,
    filter: false,
    path: "",
    disabled: false,
  },
];
