import React, { useEffect, useState } from "react";
import { Tooltip } from "@mui/material";
import Button from "components/Button";
import Text from "components/Text";
import MeetingRoomModal from "./components/MeetingRoomModal";
import { useUser } from "global/UserContext";
import { useIsMobile, useIsTablet } from "utils/mediaQueries";
import {
  RoomData,
  ParticipationStatus,
} from "pages/MeetingRoom/types/meetingTypes";
import * as S from "./styles";

interface RoomProps {
  roomData: RoomData;
  participantStatus: ParticipationStatus;
  isActivationRounds?: boolean;
}

const MeetingRoom: React.FC<RoomProps> = ({
  roomData,
  participantStatus,
  isActivationRounds = false,
}) => {
  const { user } = useUser();
  const userId = user?.id || "";
  const [isModalOpen, setModalOpen] = useState(false);

  const isMobile = useIsMobile();
  const isTablet = useIsTablet();

  useEffect(() => {
    if (isMobile || isTablet) {
      setModalOpen(true);
    }
  }, [isMobile, isTablet]);

  if (!roomData) {
    return (
      <Text size="medium" color="red">
        Sala de reunião não encontrada.
      </Text>
    );
  }

  const iframeUrl = `${process.env.REACT_APP_URL_MEETING}/meetings/room?user=${userId}&meeting=${roomData.vendor_room_id}&pwd=${roomData.room_pwd}`;

  const openMeeting = () => {
    const screenWidth = window.screen.width;
    const screenHeight = window.screen.height;
    const left = screenWidth / 2 - 500;
    const top = screenHeight / 2 - 225;

    const windowFeatures = `toolbar=no, menubar=no, width=1000, height=450, top=${top}, left=${left}`;

    if (iframeUrl) {
      window.open(iframeUrl, "_blank", windowFeatures);
    }
  };

  const handleConfirm = () => {
    setModalOpen(false);
    openMeeting();
  };

  const isDisabled = !participantStatus.is_participant;

  return (
    <>
      {!isMobile && !isTablet && (
        <S.Wrapper>
          <S.Content>
            <Tooltip
              title={
                isDisabled
                  ? "Você não tem autorização para acessar a reunião"
                  : ""
              }
              placement="right"
              arrow
            >
              <Button
                type="submit"
                title={`Entrar na ${roomData.title}`}
                size="small"
                variant="primary"
                onClick={() => setModalOpen(true)}
                style={{
                  width: "108px",
                  marginLeft: isActivationRounds
                    ? `${142.2 * (Number(roomData.position) - 7)}px`
                    : `${142.2 * roomData.position}px`,
                  fontWeight: "bold",
                  fontSize: "10px",
                }}
                disabled={isDisabled}
              >
                Entrar na reunião
              </Button>
            </Tooltip>
          </S.Content>
        </S.Wrapper>
      )}

      <MeetingRoomModal
        open={isModalOpen}
        roomTitle={roomData.title}
        onCancel={() => setModalOpen(false)}
        onConfirm={handleConfirm}
      />
    </>
  );
};

export default MeetingRoom;
