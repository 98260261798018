import React, { createContext, useContext, useState } from "react";

interface EditStatus {
  isEditing: boolean;
  currentFormId: string | null;
  setEditStatus: (editing: boolean, formId: string | null) => void;
}

const EditStatusContext = createContext<EditStatus | undefined>(undefined);

export const EditStatusProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [currentFormId, setCurrentFormId] = useState<string | null>(null);

  const setEditStatus = (editing: boolean, formId: string | null) => {
    setIsEditing(editing);
    setCurrentFormId(formId);
  };

  return (
    <EditStatusContext.Provider
      value={{ isEditing, currentFormId, setEditStatus }}
    >
      {children}
    </EditStatusContext.Provider>
  );
};

export const useEditStatus = () => {
  const context = useContext(EditStatusContext);
  if (!context) {
    throw new Error("useEditStatus must be used within an EditStatusProvider");
  }
  return context;
};
