import { useUser } from "global/UserContext";
import { apiOrchestrator } from "services/apiService";
import { useToast } from "./useToast";

const useUpdateUserContext = () => {
  const { setUser } = useUser();
  const { toastSuccess, toastError } = useToast();

  const updateUserContext = async (userId: string, updatedUserData?: any) => {
    try {
      if (updatedUserData) {
        setUser(updatedUserData);
        toastSuccess("Dados atualizados com sucesso!");
      } else {
        const userData = await apiOrchestrator.get(`/auth/me/${userId}`);
        setUser(userData.data);
        toastSuccess("Dados atualizados com sucesso!");
      }
    } catch (error) {
      toastError("Erro ao atualizar dados do usuário.");
    }
  };

  return { updateUserContext };
};

export default useUpdateUserContext;
