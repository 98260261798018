import React, { useState, useEffect } from "react";
import { Button, Progress } from "antd";
import { IoEye } from "react-icons/io5";
import Text from "components/Text";
import theme from "styles/theme";
import { useUser } from "global/UserContext";
import InternalButton from "components/InternalButton";
import { apiVox } from "services/apiService";
import * as S from "../styles";

interface ISelfAssessmentComponents {
  groupId: string;
}

const SelfAssessmentsComponents: React.FC<ISelfAssessmentComponents> = ({
  groupId,
}) => {
  const { user } = useUser();
  const [progress, setProgress] = useState(0);

  const userId = user?.id;

  const fetchCompletionData = async () => {
    try {
      const response = await apiVox.get(
        `/forms/v1/analysis/completion/${userId}?group_id=${groupId}`,
      );
      const data = response.data;
      setProgress(data.completion);
    } catch (error) {
      console.error("Erro ao buscar dados de progresso", error);
    }
  };

  useEffect(() => {
    fetchCompletionData();
  }, [groupId]);

  return (
    <S.AssessmentPropsContainer>
      <S.LevelContainer>
        <S.LevelInfo>
          <Text>Preenchimento do formulário:</Text>
          <Text bold>{progress.toFixed(1)}%</Text>
        </S.LevelInfo>
        <Progress
          percent={progress}
          showInfo={false}
          strokeColor={theme.COLORS.PRIMARY}
          trailColor={theme.COLORS.GRAY_80}
        />

        {/* Botões desabilitados */}
        {/* <InternalButton
          disabled
          background={`${theme.COLORS.PRIMARY}`}
          icon={<IoEye />}
          variant="outline"
        >
          Ver nível
        </InternalButton> */}
      </S.LevelContainer>
      <S.LevelContainer>
        {/* Botões desabilitados */}
        {/* <Button
          disabled
          type="default"
          size="large"
          block
          style={S.ButtonStyle}
        >
          Pre-visualizar
        </Button>
        <Button
          disabled
          type="primary"
          size="large"
          block
          style={S.PublishButtonStyle}
        >
          Publicar
        </Button> */}
      </S.LevelContainer>
    </S.AssessmentPropsContainer>
  );
};

export default SelfAssessmentsComponents;
