import React, { useEffect } from "react";
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from "react-icons/md";
import { BsTwitterX } from "react-icons/bs";
import {
  FaYoutube,
  FaFacebook,
  FaGoogle,
  FaInstagram,
  FaTwitter,
} from "react-icons/fa";
import {
  IconFacebook,
  IconInstagram,
  IconX,
  IconYouTube,
  IconGoogle,
  IconDrop,
} from "utils/utilsIcons";
import Text from "components/Text";
import Empty from "components/Empty";
import PaginationComponent from "components/Pagination/Pagination";
import MentionsList from "./components/MentionsList";
import TotalsCount from "componentsNew/CockpitComponents/components/TotalsCount";
import IconActive from "../IconActive";
import LoadingSpin from "../LoadingSpin";
import useSocialMentionsWcCockpit from "../SocialMentionsWC/useSocialMentionsWCCockpit";
import useMentions from "./hooks/useMentions";
import { useFilterGlobal } from "hooks/useFilterGlobal";
import theme from "styles/theme";
import * as S from "./styles";

interface IPrincipalMessage {
  isCandidate?: boolean;
}

const IntegratedFeed = ({ isCandidate = false }: IPrincipalMessage) => {
  const { rangeDate, setInitialDate } = useFilterGlobal();

  const { selectedSN, toggleSocialNetwork, isFetching } =
    useSocialMentionsWcCockpit();

  const {
    mentions,
    totalsMentions,
    loading,
    isDropdownActive,
    page,
    totalPages,
    sortField,
    setSortField,
    getTopMentions,
    setDropdownActive,
    setPage,
  } = useMentions({ isCandidate: isCandidate, selectedSN: selectedSN });

  useEffect(() => {
    if (rangeDate.initial_date !== "2010-01-01") {
      setInitialDate("2010-01-01");
    }

    getTopMentions(page);
  }, [page, getTopMentions, rangeDate.initial_date, selectedSN]);

  const handlePageChange = (newPage: number) => {
    setPage(newPage);
  };

  const toggleSortField = (field: string) => {
    setSortField(field);
  };

  const anyActive = selectedSN.length > 0;
  const mentionsCount = totalsMentions?.total_mentions || 0;
  const totalImpressionsCount = totalsMentions?.total_impressions_count || 0;

  return (
    <S.Content>
      <S.StyledCard isDropdownActive={isDropdownActive}>
        <S.DropdownHeader onClick={() => setDropdownActive(!isDropdownActive)}>
          <S.HeaderText>
            <Text size="large" bold>
              Feed Integrado
            </Text>
          </S.HeaderText>
          <S.DropdownToggle>
            {isDropdownActive ? (
              <MdKeyboardArrowUp size={36} color={theme.COLORS.PRIMARY} />
            ) : (
              <MdKeyboardArrowDown size={36} color={theme.COLORS.PRIMARY} />
            )}
          </S.DropdownToggle>
        </S.DropdownHeader>

        {isDropdownActive && (
          <S.Wrapper>
            <S.SocialAndMentions>
              <S.FiltersHeader>
                <S.ListIcon>
                  <IconActive
                    icon={<FaFacebook size={32} />}
                    size="small"
                    active={false}
                    anyActive={anyActive}
                    onToggle={() => toggleSocialNetwork("Facebook")}
                    disabled={true}
                  />
                  <IconActive
                    icon={<FaInstagram size={32} />}
                    size="small"
                    active={selectedSN.includes("Instagram")}
                    anyActive={anyActive}
                    onToggle={() => toggleSocialNetwork("Instagram")}
                    disabled={isFetching}
                  />
                  <IconActive
                    icon={<BsTwitterX size={32} />}
                    size="small"
                    active={false}
                    anyActive={anyActive}
                    onToggle={() => toggleSocialNetwork("X")}
                    disabled={true}
                  />
                  <IconActive
                    icon={<FaYoutube size={32} />}
                    size="small"
                    active={selectedSN.includes("YouTube")}
                    anyActive={anyActive}
                    onToggle={() => toggleSocialNetwork("YouTube")}
                    disabled={isFetching}
                  />
                  <IconActive
                    icon={<FaGoogle size={32} />}
                    size="small"
                    active={false}
                    anyActive={anyActive}
                    onToggle={() => toggleSocialNetwork("Google")}
                    disabled={true}
                  />
                </S.ListIcon>
                <S.SortingHeader>
                  <S.TimeDropdown>
                    <S.DropdownToggle>
                      <Text size="xSmall" bold>
                        {sortField === "date"
                          ? "Mais Recentes"
                          : sortField === "impressions_count"
                            ? "Mais Visualizados"
                            : "Maior Compartilhamento"}
                      </Text>
                      <IconDrop />
                    </S.DropdownToggle>
                    <S.DropdownContent>
                      <S.DropdownItem onClick={() => toggleSortField("date")}>
                        Mais Recentes
                      </S.DropdownItem>
                      <S.DropdownItem
                        onClick={() => toggleSortField("impressions_count")}
                      >
                        Mais Visualizados
                      </S.DropdownItem>
                      <S.DropdownItem
                        onClick={() => toggleSortField("shares_count")}
                      >
                        Maior Compartilhamento
                      </S.DropdownItem>
                    </S.DropdownContent>
                  </S.TimeDropdown>
                </S.SortingHeader>
              </S.FiltersHeader>

              <S.MentionsContainer>
                {loading ? (
                  <LoadingSpin />
                ) : (
                  <>
                    <S.MentionsContainer>
                      <MentionsList mentions={mentions} loading={loading} />
                    </S.MentionsContainer>
                    {totalPages > 1 && (
                      <S.Pagination>
                        <PaginationComponent
                          totalPages={totalPages}
                          currentPage={page}
                          onPageChange={handlePageChange}
                          siblingCount={1}
                          boundaryCount={2}
                          color="custom"
                          customColor={theme.COLORS.BACKGROUND_PRIMARY}
                          size="medium"
                        />
                      </S.Pagination>
                    )}
                  </>
                )}
              </S.MentionsContainer>
            </S.SocialAndMentions>
            <S.TotalAndClouds>
              {loading ? (
                <LoadingSpin />
              ) : (
                <>
                  <S.TotalsCountContainer>
                    <TotalsCount
                      description="Total de Menções"
                      value={mentionsCount}
                    />
                    <TotalsCount
                      description="Visualizações"
                      value={totalImpressionsCount}
                    />
                  </S.TotalsCountContainer>
                  <S.TotalsCountContainer>
                    <TotalsCount description="" value="" />
                    <TotalsCount description="" value="" />
                  </S.TotalsCountContainer>
                  <S.TotalsCountContainer>
                    <TotalsCount description="" value="" />
                    <TotalsCount description="" value="" />
                  </S.TotalsCountContainer>
                  <S.TotalsCountContainer>
                    <TotalsCount description="" value="" />
                    <TotalsCount description="" value="" />
                  </S.TotalsCountContainer>
                </>
              )}
            </S.TotalAndClouds>
          </S.Wrapper>
        )}
      </S.StyledCard>
    </S.Content>
  );
};

export default IntegratedFeed;
