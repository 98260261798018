import { styled } from "styled-components";
import theme from "styles/theme";

export const Wrapper = styled.main<{ withTitle?: boolean; percent?: boolean }>`
  background: #0a0a0a;
  border-radius: 8px;
  width: 100%;
  height: 100%;
  display: flex;
  gap: 4px;
  flex-direction: column;
  justify-content: ${({ withTitle }) => withTitle && "center"};
  padding: 8px;
  min-height: 70px;
`;

export const TitleCount = styled.h2`
  font-size: 18px;
  font-weight: 500;
  color: ${theme.COLORS.GRAY_10};
  align-self: center;
  justify-self: center;
`;

export const Description = styled.h3`
  display: flex;
  font-size: 12px;
  font-weight: 500;
  color: ${theme.COLORS.GRAY_10};
  flex-direction: row;
  gap: 4px;
`;

export const TextCount = styled.p<{ percent?: number; colorPercent?: string }>`
  font-size: 24px;
  font-weight: 700;
  color: ${theme.COLORS.YELLOW_1};
  display: flex;
  align-items: end;
  gap: 8px;
  justify-content: ${({ percent }) => (percent ? "space-between" : "center")};
  padding: 4px 12px 0 12px;

  &.positive {
    span {
      color: ${theme.COLORS.GREEN_1};
    }
  }

  &.negative {
    span {
      color: ${theme.COLORS.RED_1};
    }
  }

  &.neutral {
    span {
      color: #c2c2c2;
    }
  }
`;

export const AdditionalText = styled.p`
  font-size: 12px;
  color: ${theme.COLORS.GRAY_10};
  margin-top: 4px;
  text-align: center;
`;
