import { useState, useEffect } from "react";
import { apiVox } from "services/apiService";

interface Form {
  form_name: string;
  created_at: string;
  author_id: string;
  organization_id: string;
  id: string;
  description: string | null;
}

export const useFetchForms = (
  group_id: string,
  organization_id?: string,
  page = 1,
  size = 50,
) => {
  const [forms, setForms] = useState<Form[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchForms = async () => {
      setLoading(true);
      try {
        const response = await apiVox.get("/forms/v1/forms/forms-by-group", {
          params: { group_id },
        });
        setForms(response.data);
      } catch (err: any) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchForms();
  }, [group_id]);

  return { forms, loading, error };
};
