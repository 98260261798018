const AUTH_TOKEN = "authToken";

const ROUTES = {
  AUTH: {
    LOGIN: "/login",
    LOGOUT: "/logout",
    REGISTER: "/register",
    FORGOT_PASSWORD: "/forgot-password",
    RECOVER_PASSWORD: "/recover-password",
  },
  ROOT: "/dashboard/",
  COCKPIT: "cockpit",
  FINANCIAL_MANAGEMENT: "business-management/financial-management",
  COMMERCIAL_MANAGEMENT: "business-management/commercial-management",
  DIGITAL_MARKETING: "business-management/digital-marketing",
  CLUB_ACTIVATION_ROUNDS: "club/activation-rounds",
  COMMUNITY_PROFILE: "community-profile",
  COMMUNITY_GENERAL_PROFILE:
    "community/intelligence-networking/general-profile",
  COMMUNITY_BIO: "community/intelligence-networking/bio",
  CLUB_BIO: "community/intelligence-connections/bio",
  INTELLIGENCE_NETWORKING: "intelligence-networking",
  THEMATIC_GROUPS: "thematic-groups",
  DIGITAL_GOVERNANCE: "digital-governance",
  USER: {
    PROFILE: "user-profile",
    SETTINGS: "settings/profile",
    PROFILE_MOBILE: "user-profile/profile",
    PROFILE_MOBILE_SELF_ASSESSMENT: "user-profile/self-assessment",
    PROFILE_MOBILE_CHALLENGES_AND_PROJECTS_2025:
      "user-profile/challenges-and-projects-2025",
    PROFILE_FEEDBACK_NETWORK_EXPERIENCE:
      "user-profile/feedback-networking-experience",
    PROFILE_INVITES: "user-profile/invites",
  },
  NOT_FOUND: "not-found",
  ATIVACAO_PRESENCIAL: "networking-exp/ativacao-presencial"
};

export const FEATURE_TO_ROUTE_MAP: Record<string, string> = {
  Cockpit: ROUTES.COCKPIT,
  RodadasAtivacao: ROUTES.CLUB_ACTIVATION_ROUNDS,
  PerfilComunidade: ROUTES.COMMUNITY_PROFILE,
  InteligenciaNetowrking: ROUTES.INTELLIGENCE_NETWORKING,
  GruposTematicos: ROUTES.THEMATIC_GROUPS,
  CuradoriaDados: ROUTES.NOT_FOUND,
  Advisors: ROUTES.NOT_FOUND,
  Educacao: ROUTES.NOT_FOUND,
  GovernancaDigital: ROUTES.DIGITAL_GOVERNANCE,
  PerfilAvaliacoes: ROUTES.USER.PROFILE,
  GestaoFinanceira: ROUTES.FINANCIAL_MANAGEMENT,
  GestaoComercial: ROUTES.COMMERCIAL_MANAGEMENT,
  MerketingDigital: ROUTES.DIGITAL_MARKETING,
  AtivacaoPresencial: ROUTES.ATIVACAO_PRESENCIAL
};

const POSITIVE = "positive";
const NEGATIVE = "negative";
const ALL = "all";

export { AUTH_TOKEN, ROUTES, POSITIVE, NEGATIVE, ALL };
