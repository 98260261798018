import React, { useEffect, useRef } from "react";
import { useUser } from "global/UserContext";
import { CircularProgress, Box } from "@mui/material";

const Logout = () => {
  const { logout } = useUser();
  const hasLoggedOut = useRef(false);

  useEffect(() => {
    if (!hasLoggedOut.current) {
      hasLoggedOut.current = true;
      logout();
    }
  }, [logout]);

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      <CircularProgress />
    </Box>
  );
};

export default Logout;
