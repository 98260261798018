import React, { useEffect, useState } from "react";
import "react-toastify/dist/ReactToastify.css";
import { toast, ToastContainer } from "react-toastify";
import { Button } from "antd";
import AvatarDefault from "assets/avatar-default.png";
import Text from "components/Text";
import { useTab } from "global/TabContext";
import { useUser } from "global/UserContext";
import { useEditStatus } from "global/EditStatusContext";
import useProfileImage from "pages/SettingsProfile/hooks/useProfileImage";
import SelfAssessmentsComponents from "./components/SelfAssessmentsComponents";
import SocialIconsContainer from "./components/SocialIconsContainer";
import UploadModal from "./components/UploadModal";
import RemoveModal from "./components/RemoveModal";
import SavingStatus from "./components/SavingStatus";
import SuccessStatus from "./components/SuccessStatus";
import { useUserSocialNetworks } from "./hooks/useUserSocialNetworks";
import { formatUserName } from "utils/formatUserName";
import * as S from "./styles";

const UserProfileCard: React.FC = () => {
  const { activeTabContext } = useTab();
  const { user } = useUser();
  const { isEditing } = useEditStatus();

  const [userFirstName, setUserFirstName] = useState(user?.first_name);
  const [userLastName, setUserLastName] = useState(user?.last_name);

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isRemoveModalVisible, setIsRemoveModalVisible] = useState(false);
  const [showSavingStatus, setShowSavingStatus] = useState(isEditing);

  const [previewImage, setPreviewImage] = useState<string | null>(null);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const { profileImage, updateProfileImage, deleteProfileImage, loading } =
    useProfileImage(user?.id || "");

  const {
    socialNetworks,
    loading: socialLoading,
    error: socialError,
  } = useUserSocialNetworks();

  useEffect(() => {
    if (user) {
      setUserFirstName(user?.first_name);
      setUserLastName(user?.last_name);
    }
  }, [user]);

  useEffect(() => {
    if (isEditing) {
      setShowSavingStatus(true);
    } else {
      const timer = setTimeout(() => {
        setShowSavingStatus(false);
      }, 3000);

      return () => clearTimeout(timer);
    }
  }, [isEditing]);

  const handleUpload = (info: any) => {
    const file = info.file;
    if (!file) {
      toast.error("Nenhum arquivo foi selecionado.");
      return;
    }
    if (!file.type || !file.type.startsWith("image/")) {
      toast.error("Por favor, envie um arquivo de imagem válido.");
      return;
    }

    const maxFileSize = 500 * 1024; // 500 KB

    const img = new Image();
    const reader = new FileReader();

    reader.onload = (e) => {
      img.src = e.target?.result as string;

      img.onload = () => {
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");
        const MAX_WIDTH = 1024; // Maximum width (adjust as needed)
        const MAX_HEIGHT = 1024; // Maximum height (adjust as needed)
        let width = img.width;
        let height = img.height;

        // Calculate the new width and height based on max constraints
        if (width > height) {
          if (width > MAX_WIDTH) {
            height = Math.round((height * MAX_WIDTH) / width);
            width = MAX_WIDTH;
          }
        } else {
          if (height > MAX_HEIGHT) {
            width = Math.round((width * MAX_HEIGHT) / height);
            height = MAX_HEIGHT;
          }
        }

        // Resize the image
        canvas.width = width;
        canvas.height = height;
        ctx?.drawImage(img, 0, 0, width, height);

        // Convert the resized canvas to a Blob
        canvas.toBlob(
          (blob) => {
            if (blob && blob.size <= maxFileSize) {
              const resizedFile = new File([blob], file.name, {
                type: file.type,
                lastModified: Date.now(),
              });

              setSelectedFile(resizedFile);
              setPreviewImage(URL.createObjectURL(resizedFile));
            } else {
              toast.error(
                "A imagem não pôde ser redimensionada abaixo de 500 KB.",
              );
            }
          },
          file.type,
          0.8, // Quality factor for compression (0.0 to 1.0)
        );
      };
    };

    reader.readAsDataURL(file);

    // setSelectedFile(file);
    // setPreviewImage(URL.createObjectURL(file));
  };

  const handleConfirmUpload = () => {
    if (selectedFile) {
      updateProfileImage(selectedFile);
      setPreviewImage(null);
      setSelectedFile(null);
      toast.success("Foto de perfil atualizada com sucesso!");
    } else {
      toast.error("Nenhum arquivo selecionado.");
    }
  };

  const handleCancelUpload = () => {
    setPreviewImage(null);
    setSelectedFile(null);
    setIsModalVisible(false);
  };

  const handleRemoveImage = () => {
    deleteProfileImage();
    toast.success("Foto de perfil removida com sucesso!");
    setIsRemoveModalVisible(false);
  };

  const handleCloseModal = () => {
    setIsModalVisible(false);
  };

  const handleCloseRemoveModal = () => {
    setIsRemoveModalVisible(false);
  };

  const isCockpitTab = activeTabContext === "tab_cockpit";

  // TODO - improve
  type TabFormMapperType = Record<string, string>
  const TabFormMapper: TabFormMapperType = {
    "tab_user_profile_self_assessment": "9e038ecb-1542-4e90-acbd-91c537102bc3",
    "tab_user_profile_challeges_2025": "7e84a4c4-4008-48bf-b866-37344af8819f",
    "tab_user_feedback_neworking_exp": "2e192c7d-daab-4d25-b094-b50ca32b53e6",
  }


  const isSelfAssessmentsTab = TabFormMapper[activeTabContext] ? true : false;

  const renderAvatarOrImage = () => {
    if (previewImage) {
      return (
        <S.PreviewImage>
          <Text size="medium" bold>
            Pré-visualização:
          </Text>
          <img
            src={previewImage}
            alt="Preview"
            style={{ width: 120, height: 120, borderRadius: "50%" }}
          />
        </S.PreviewImage>
      );
    }

    if (profileImage) {
      return (
        <img
          src={profileImage}
          alt={`${userFirstName} profile picture`}
          style={{ width: 120, height: 120, borderRadius: "50%" }}
        />
      );
    }
    return (
      <S.ProfilePicture
        src={AvatarDefault}
        alt={`${userFirstName} profile picture`}
      />
    );
  };

  return (
    <S.CardContainer>
      <S.ProfilePictureWrapper>{renderAvatarOrImage()}</S.ProfilePictureWrapper>
      {!isCockpitTab && (
        <Button
          type="link"
          style={S.ButtonChangePictureStyle}
          onClick={() => setIsModalVisible(true)}
        >
          Alterar foto de perfil
        </Button>
      )}

      <Text size="large" bold className="user-name">
        {formatUserName(`${user?.first_name} ${user?.last_name}`)}
      </Text>

      {!isCockpitTab && (
        <SocialIconsContainer
          socialNetworks={socialNetworks}
          socialLoading={socialLoading}
          socialError={socialError}
        />
      )}

      {isSelfAssessmentsTab && (
        <>
          <SelfAssessmentsComponents groupId={TabFormMapper[activeTabContext]} />
          <div>{showSavingStatus ? <SavingStatus /> : <SuccessStatus />}</div>
        </>
      )}

      <UploadModal
        isModalVisible={isModalVisible}
        loading={loading}
        previewImage={previewImage}
        profileImage={profileImage}
        handleCancelUpload={handleCancelUpload}
        handleConfirmUpload={handleConfirmUpload}
        handleUpload={handleUpload}
      />

      <RemoveModal
        isRemoveModalVisible={isRemoveModalVisible}
        loading={loading}
        profileImage={profileImage}
        handleRemoveImage={handleRemoveImage}
        handleCloseRemoveModal={handleCloseRemoveModal}
      />

      <ToastContainer position="top-right" autoClose={5000} />
    </S.CardContainer>
  );
};

export default UserProfileCard;
