import React, { useState } from "react";
import {
  Box,
  RadioGroup,
  FormControlLabel,
  Radio,
  TextField,
  Select,
  MenuItem,
} from "@mui/material";
import Button from "components/Button";
import { useToast } from "hooks/useToast";
import { apiRainmakers } from "services/apiService";
import { useMeetingRooms } from "hooks/useMeetingRooms";
import theme from "styles/theme";

interface LocationTabProps {
  eventId: string;
  onNext: () => void;
  onCancel: () => void;
  isEditMode?: boolean;
}

const LocationTab: React.FC<LocationTabProps> = ({
  eventId,
  onNext,
  onCancel,
  isEditMode = false,
}) => {
  const [isOnline, setIsOnline] = useState<boolean | null>(null);
  const [selectedRoom, setSelectedRoom] = useState<number | null>(null);
  const [location, setLocation] = useState<string>("");
  const { meetingRooms, loading } = useMeetingRooms();
  const { toastError, toastSuccess } = useToast();

  const handleSaveLocation = async () => {
    try {
      const payload = isOnline
        ? { is_online: true, room_id: selectedRoom }
        : { is_online: false, location };

      if (isEditMode) {
        await apiRainmakers.patch(`/events/v1/local/${eventId}`, payload);
        toastSuccess("Local do evento atualizado com sucesso!");
      } else {
        await apiRainmakers.post(`/events/v1/local/${eventId}`, payload);
        toastSuccess("Local do evento salvo com sucesso!");
      }

      onNext();
    } catch (error) {
      console.error("Erro ao salvar o local:", error);
      toastError("Erro ao salvar o local do evento. Tente novamente.");
    }
  };

  const isFormValid = () => {
    if (isOnline === true) {
      return selectedRoom !== null;
    }
    if (isOnline === false) {
      return location.trim().length > 0;
    }
    return false;
  };

  return (
    <Box
      sx={{
        width: "100%",
        minHeight: "300px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        gap: "1rem",
        padding: "1rem",
      }}
    >
      <RadioGroup
        name="isOnline"
        value={isOnline !== null ? String(isOnline) : ""}
        onChange={(e) => {
          const value = e.target.value === "true";
          setIsOnline(value);
          if (!value) {
            setSelectedRoom(null);
          } else {
            setLocation("");
          }
        }}
        row
        sx={{ flexDirection: "column" }}
      >
        <FormControlLabel
          value="false"
          control={
            <Radio
              sx={{
                color: theme.COLORS.PRIMARY,
                "&.Mui-checked": { color: theme.COLORS.PRIMARY },
              }}
            />
          }
          label="Presencial"
        />
        <FormControlLabel
          value="true"
          control={
            <Radio
              sx={{
                color: theme.COLORS.PRIMARY,
                "&.Mui-checked": { color: theme.COLORS.PRIMARY },
              }}
            />
          }
          label="Virtual"
        />
      </RadioGroup>

      {isOnline === false && (
        <TextField
          label="Insira aqui o endereço"
          value={location}
          onChange={(e) => setLocation(e.target.value)}
          fullWidth
        />
      )}

      {isOnline === true && (
        <Select
          value={selectedRoom ?? ""}
          onChange={(e) => setSelectedRoom(Number(e.target.value))}
          displayEmpty
          fullWidth
          disabled={loading}
        >
          <MenuItem value="" disabled>
            {loading ? "Carregando salas..." : "Selecionar sala do evento"}
          </MenuItem>
          {meetingRooms.map((room) => (
            <MenuItem key={room.room_data.id} value={room.room_data.id}>
              {room.room_data.title}
            </MenuItem>
          ))}
        </Select>
      )}

      <Box display="flex" justifyContent="space-between" gap={2}>
        <Button
          variant="outline"
          color="secondary"
          onClick={onCancel}
          fullWidth
        >
          Cancelar
        </Button>
        <Button
          variant="primary"
          color="primary"
          onClick={handleSaveLocation}
          fullWidth
          disabled={!isFormValid()}
        >
          {isEditMode ? "Salvar Alterações" : "Agendamento"}
        </Button>
      </Box>
    </Box>
  );
};

export default LocationTab;
