import styled from "styled-components";

interface propsContainer {
  open: boolean;
  isActive: boolean;
  isSelected: boolean;
  isSubMenu?: boolean;
  isDisabled: boolean;
}

export const Container = styled.div<propsContainer>`
  width: 100%;
  height: 3.5rem;
  display: flex;
  align-items: center;
  background: ${({ theme, isSelected }: { theme: any; isSelected: boolean }) =>
    isSelected && theme.COLORS.BACKGROUND_SECONDARY};
  opacity: ${({ isActive }: { isActive: boolean }) => (!isActive ? 0.5 : 1)};
  cursor: ${({ isActive }: { isActive: boolean }) =>
    isActive ? "pointer" : "not-allowed"};

  ${({ isSubMenu }) =>
    !isSubMenu &&
    `
      :hover {
        background: ${({
          theme,
          isActive,
          isSelected,
        }: {
          theme: any;
          isActive: boolean;
          isSelected: boolean;
        }) => (isSelected ? theme.COLORS.BLACK : theme.COLORS.GRAY_70)};
        cursor: ${({ isActive }: { isActive: boolean }) =>
          isActive ? "pointer" : "not-allowed"};
      }
    `}
`;

export const Wrapper = styled.div`
  padding: 0.5rem;
  width: 100%;
  height: 100%;
  display: flex;
  gap: 0.5rem;
  align-items: center;

  &:hover {
    background-color: ${({ theme }: { theme: any }) => theme.COLORS.GRAY_50};
  }
`;

export const Bar = styled.div`
  width: 0.25rem;
  height: 100%;
  background: ${({ theme }) => theme.COLORS.PRIMARY};
`;
