import React, { useState, useEffect, useRef } from "react";
import TitleFormComponent from "../TitleForm";
import Text from "components/Text";
import Button from "components/Button";
import { Modal, Box } from "@mui/material";
import ReactMarkdown from "react-markdown";
import theme from "styles/theme";
import * as S from "./styles";

interface ITermComponent {
  title?: string;
  subtitle?: string;
  description?: string;
  content?: string;
  version?: string;
}

const TermComponent = ({
  title = "",
  subtitle = "",
  description = "",
  content = "",
  version = "v.1.0",
}: ITermComponent) => {
  const [viewModal, setViewModal] = useState(false);
  const [accepted, setAccepted] = useState(false);
  const [acceptedDate, setAcceptedDate] = useState<string | null>(null);
  const [primaryButtonDisabled, setPrimaryButtonDisabled] = useState(true);
  const contentRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const storedAccepted = localStorage.getItem("termAccepted");
    const storedDate = localStorage.getItem("acceptedDate");
    if (storedAccepted === "true" && storedDate) {
      setAccepted(true);
      setAcceptedDate(storedDate);
    }
  }, []);

  const handleOpenModal = () => {
    setViewModal(true);
  };

  const handleCloseModal = () => {
    setViewModal(false);
  };

  const handleAccept = () => {
    const currentDate = new Date().toLocaleString("pt-BR", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
    });

    localStorage.setItem("termAccepted", "true");
    localStorage.setItem("acceptedDate", currentDate);

    setAccepted(true);
    setAcceptedDate(currentDate);
    setViewModal(false);
  };

  const handleDecline = () => {
    handleCloseModal();
  };

  const handleScroll = () => {
    if (contentRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = contentRef.current;
      if (scrollTop + clientHeight >= scrollHeight) {
        setPrimaryButtonDisabled(false);
      }
    }
  };

  return (
    <>
      <S.Wrapper>
        <TitleFormComponent title={title} />
        <S.Container>
          <S.TextContainer>
            <Text size="medium" bold>
              {subtitle}
            </Text>
            <S.Description>{description}</S.Description>
          </S.TextContainer>

          {!accepted ? (
            <Button
              onClick={handleOpenModal}
              style={{ backgroundColor: `${theme.COLORS.GRAY_10}` }}
            >
              Abrir Termo ({version})
            </Button>
          ) : (
            <S.AcceptedContainer>
              <S.CheckIcon />
              <Text size="xSmall">Assinado!</Text>
              <Text size="small">({acceptedDate})</Text>
            </S.AcceptedContainer>
          )}
        </S.Container>
      </S.Wrapper>

      <Modal open={viewModal} onClose={handleCloseModal}>
        <Box sx={{ ...S.ModalStyle }}>
          <h2
            style={{
              color: theme.COLORS.TEXT_PRIMARY,
              textAlign: "center",
              fontFamily: "Roboto",
              fontSize: "1.5rem",
              fontWeight: 400,
              textDecoration: "underline",
            }}
          >
            {subtitle}
          </h2>
          <S.ScrollableContent onScroll={handleScroll} ref={contentRef}>
            <ReactMarkdown className="content-markdown">
              {content}
            </ReactMarkdown>
          </S.ScrollableContent>

          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: "1rem",
              gap: "1rem",
            }}
          >
            <Button onClick={handleDecline} variant="outline" fullWidth>
              Recusar
            </Button>
            <Button
              onClick={handleAccept}
              variant="primary"
              // disabled={primaryButtonDisabled}
              fullWidth
            >
              Aceitar
            </Button>
          </div>
        </Box>
      </Modal>
    </>
  );
};

export default TermComponent;
