import React, { useState, useCallback } from "react";
import Button from "components/Button";
import ConfirmLogoutModal from "components/Logout/components/ConfirmLogoutModal";
import { useUser } from "global/UserContext";
import * as S from "./styles";

const UserProfileMobile = () => {
  const { logout } = useUser();

  const [logoutModalOpen, setLogoutModalOpen] = useState(false);

  const handleOpenLogoutModal = () => setLogoutModalOpen(true);
  const handleCloseLogoutModal = () => setLogoutModalOpen(false);

  const handleConfirmLogout = useCallback(() => {
    setLogoutModalOpen(false);
    setTimeout(() => {
      logout();
    }, 300);
  }, [logout]);

  return (
    <S.Background>
      <S.Container>
        <S.Content>
          <Button variant="minimal" onClick={handleOpenLogoutModal}>
            Sair
          </Button>
          {/* <Button variant="minimal" disabled>
            Deletar Conta
          </Button> */}
        </S.Content>
      </S.Container>

      <ConfirmLogoutModal
        open={logoutModalOpen}
        onClose={handleCloseLogoutModal}
        onConfirm={handleConfirmLogout}
      />
    </S.Background>
  );
};

export default UserProfileMobile;
