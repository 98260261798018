import Spin from "components/Spin";
import React, {
  forwardRef,
  AnchorHTMLAttributes,
  ButtonHTMLAttributes,
  JSX,
  useState,
  useEffect,
} from "react";
import * as S from "./styles";

type ButtonTypes =
  | AnchorHTMLAttributes<HTMLAnchorElement>
  | ButtonHTMLAttributes<HTMLButtonElement>;

export type ButtonProps = {
  size?: "small" | "medium" | "large";
  fullWidth?: boolean;
  icon?: JSX.Element;
  variant?: "outline" | "minimal" | "primary" | "darkBg" | "red";
  startCount?: boolean;
  sizeCount?: number | null;
  as?: React.ElementType;
  loading?: boolean;
  disabled?: boolean;
} & ButtonTypes;

const Button: React.ForwardRefRenderFunction<S.WrapperProps, ButtonProps> = (
  {
    children,
    icon,
    size = "medium",
    fullWidth = false,
    variant = "primary",
    startCount,
    sizeCount = null,
    loading,
    disabled,
    ...props
  },
  ref,
) => {
  const [count, setCount] = useState<number | null>(sizeCount);
  const [inCount, setInCount] = useState(startCount);

  useEffect(() => {
    let intervalId: any;

    if (startCount) {
      if (!inCount) {
        setInCount(true);
        setCount(sizeCount);
      }
      intervalId = setInterval(() => {
        setCount((prevCount) =>
          prevCount && prevCount > 0 ? prevCount - 1 : prevCount,
        );
      }, 1000);
    } else {
      setCount(sizeCount);
      setInCount(false);
    }

    return () => clearInterval(intervalId);
  }, [startCount, sizeCount, inCount]);

  return (
    <S.Wrapper
      size={size}
      fullWidth={fullWidth}
      hasIcon={!!icon}
      variant={variant}
      darkBg={variant === "darkBg" || inCount}
      ref={ref}
      disabled={disabled}
      {...props}
    >
      {icon && <span className="icon">{icon}</span>}
      {!!children && (
        <span>
          {inCount && count !== null && count >= 0
            ? `${children} (${count})`
            : children}
        </span>
      )}
      {loading && <Spin size="small" />}
    </S.Wrapper>
  );
};

export default forwardRef(Button);
