import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Stack,
  Pagination,
  Tooltip,
  IconButton,
} from "@mui/material";

import { ReactComponent as PendingStatusIcon } from "assets/icons/status/pending-status.svg";
import { ReactComponent as AcceptedStatusIcon } from "assets/icons/status/accepted-status.svg";
import { ReactComponent as DeclinedStatusIcon } from "assets/icons/status/declined-status.svg";
import { ReactComponent as DeleteIcon } from "assets/icons/actions/delete.svg";
import { ReactComponent as EditIcon } from "assets/icons/actions/edit.svg";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

import { useIsMobile, useIsTablet } from "utils/mediaQueries";
import GlobalModal from "modals/GlobalModal/GlobalModal";
import * as S from "./styles";

interface Invite {
  id: string;
  user_id: string;
  invitee_first_name: string;
  invitee_last_name: string;
  invitee_email: string;
  invitee_cell_phone: string;
  invite_status: "pending" | "accepted" | "declined";
  invited_at: string;
  updated_at: string;
}

interface InvitesListProps {
  invites: Invite[];
  totalInvites: number;
  page: number;
  rowsPerPage: number;
  sortColumn: string;
  isDesc: boolean;
  setIsDesc: any;
  onPageChange: (newPage: number) => void;
  onRowsPerPageChange: (newRowsPerPage: number) => void;
  onClickEdit: (invite: Invite) => void;
  onClickDelete: (inviteId: string) => void;
  onClickOrderBy: (column: string) => void;
  onClickCopy: (inviteId: string) => void;
}

const InvitesList: React.FC<InvitesListProps> = ({
  invites,
  totalInvites,
  page,
  rowsPerPage,
  sortColumn,
  isDesc,
  onPageChange,
  setIsDesc,
  onClickEdit,
  onClickOrderBy,
  onClickDelete,
  onClickCopy,
}) => {
  const isMobile = useIsMobile();
  const isTablet = useIsTablet();

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [selectedInvite, setSelectedInvite] = useState<Invite | null>(null);

  const handleDeleteClick = (invite: Invite) => {
    setSelectedInvite(invite);
    setIsModalOpen(true);
  };

  const handleConfirmDelete = () => {
    if (selectedInvite) {
      onClickDelete(selectedInvite.id);
      setSelectedInvite(null);
      setIsModalOpen(false);
    }
  };

  const handleCancelDelete = () => {
    setSelectedInvite(null);
    setIsModalOpen(false);
  };

  const handleHeaderClick = (key: string) => {
    if (sortColumn === key) {
      setIsDesc(!isDesc);
    } else {
      onClickOrderBy(key);
      setIsDesc(true);
    }
  };

  const headers = isMobile
    ? [
        { key: "invite_status", label: "Status" },
        { key: "invitee_first_name", label: "Nome" },
        { key: "actions", label: "Ações" },
      ]
    : [
        {
          key: "invite_status",
          label: "Status",
          sx: { borderRadius: "8px 0 0 8px" },
        },
        { key: "invitee_first_name", label: "Nome" },
        { key: "invited_at", label: "Data" },
        { key: "invitee_cell_phone", label: "Telefone" },
        { key: "invitee_email", label: "Email" },
        { key: "actions", label: "Ações", sx: { borderRadius: "0 8px 8px 0" } },
      ];

  return (
    <S.InviteListContainer>
      <TableContainer>
        <Table size={isMobile ? "small" : "small"}>
          <TableHead>
            <TableRow>
              {headers.map((h) => (
                <TableCell
                  key={h.key}
                  sx={h.sx ? { ...h.sx } : undefined}
                  onClick={() => handleHeaderClick(h.key)}
                  style={{ cursor: "pointer" }}
                >
                  <Stack spacing={1} direction={"row"}>
                    <span>{h.label}</span>
                    {sortColumn === h.key && (
                      <div style={{ display: "flex", alignItems: "center" }}>
                        {isDesc ? (
                          <ArrowDownwardIcon fontSize="small" />
                        ) : (
                          <ArrowUpwardIcon fontSize="small" />
                        )}
                      </div>
                    )}
                  </Stack>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {invites.map((invite) => (
              <TableRow key={invite.id} sx={{ height: "56px" }}>
                <TableCell>
                  <Tooltip
                    title={getInviteStatusTooltipLabel(invite.invite_status)}
                    placement="right"
                  >
                    {getInviteStatusIcon(invite.invite_status)}
                  </Tooltip>
                </TableCell>
                <TableCell>
                  {invite.invitee_first_name} {invite.invitee_last_name}
                </TableCell>
                {!isMobile && (
                  <>
                    <TableCell>
                      {new Date(invite.invited_at).toLocaleDateString("pt-BR")}
                    </TableCell>
                    <TableCell>{invite.invitee_cell_phone}</TableCell>
                    <TableCell>{invite.invitee_email}</TableCell>
                  </>
                )}
                <TableCell>
                  <Stack direction="row" spacing={1}>
                    <Tooltip title="Editar convite" placement="top">
                      <IconButton
                        onClick={() => onClickEdit(invite)}
                        sx={{ padding: "4px" }}
                      >
                        <EditIcon />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Deletar convite" placement="top">
                      <IconButton
                        onClick={() => handleDeleteClick(invite)}
                        sx={{ padding: "4px" }}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Copiar link do convite" placement="top">
                      <IconButton
                        onClick={() => onClickCopy(invite.id)}
                        sx={{ padding: "4px" }}
                      >
                        <ContentCopyIcon fontSize="small" />
                      </IconButton>
                    </Tooltip>
                  </Stack>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <Pagination
          count={Math.ceil(totalInvites / rowsPerPage)}
          page={page + 1}
          onChange={(e, value) => onPageChange(value - 1)}
          sx={{
            display: "flex",
            justifyContent: "center",
            marginTop: "8px",
            padding: isMobile ? "0 1rem" : "0",
          }}
        />
      </TableContainer>

      <GlobalModal
        open={isModalOpen}
        title="CONFIRMAÇÃO"
        description="Tem certeza que deseja excluir este convite? Esta ação não pode ser desfeita."
        confirmButtonText="Excluir"
        cancelButtonText="Cancelar"
        onConfirm={handleConfirmDelete}
        onCancel={handleCancelDelete}
        onClose={handleCancelDelete}
      />
    </S.InviteListContainer>
  );
};

function getInviteStatusIcon(
  status: "pending" | "accepted" | "declined",
): JSX.Element {
  switch (status) {
    case "pending":
      return <PendingStatusIcon />;
    case "accepted":
      return <AcceptedStatusIcon />;
    case "declined":
      return <DeclinedStatusIcon />;
    default:
      return <PendingStatusIcon />;
  }
}

function getInviteStatusTooltipLabel(
  status: "pending" | "accepted" | "declined",
): string {
  switch (status) {
    case "pending":
      return "Pendente";
    case "accepted":
      return "Aceito";
    case "declined":
      return "Recusado";
    default:
      return "Pendente";
  }
}

export default InvitesList;
