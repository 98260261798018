import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  right: 0;
  top: 30%;
  z-index: 4;
`;

export const Divisor = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  z-index: 4;
  right: 0;
  top: 100%;
  z-index: 777000;
`;
