import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Tabs,
  Tab,
  DialogActions,
  Typography,
  Divider,
} from "@mui/material";
import Button from "components/Button";
import EventDataTab from "./EventTabs/EventDataTab";
import LocationTab from "./EventTabs/LocationTab";
import ScheduleTab from "./EventTabs/ScheduleTab";
import ParticipantTab from "./EventTabs/ParticipantTab";
import AdvancedConfigTab from "./EventTabs/AdvancedConfigTab";
import { useUser } from "global/UserContext";
import { apiRainmakers } from "services/apiService";
import { useToast } from "hooks/useToast";
import theme from "styles/theme";

interface ICreateEventModal {
  open: boolean;
  onClose: () => void;
  onEventCreated: (groupId: string) => void;
  onUpdate: () => void;
  isEditMode?: boolean;
  eventId?: string;
}

const CreateEventModal: React.FC<ICreateEventModal> = ({
  open,
  onClose,
  onEventCreated,
  onUpdate,
  isEditMode = false,
  eventId,
}) => {
  const [tabIndex, setTabIndex] = useState(0);
  const [tabValidations, setTabValidations] = useState({
    eventData: false,
    location: false,
    schedule: false,
    participants: false,
    advancedConfig: false,
  });
  const [confirmCancelOpen, setConfirmCancelOpen] = useState(false);
  const { toastSuccess, toastError, toastInfo } = useToast();
  const { user } = useUser();
  const userId = user?.id;

  const [eventData, setEventData] = useState({
    name: "",
    description: "",
    type: "",
    isExclusive: false,
    id: "",
  });

  useEffect(() => {
    if (isEditMode && eventId) {
      const fetchEventData = async () => {
        try {
          const response = await apiRainmakers.get(
            `/events/v1/events/${eventId}`,
          );
          const { name, description, type_code, is_exclusive } = response.data;
          setEventData({
            name,
            description,
            type: type_code,
            isExclusive: is_exclusive,
            id: eventId,
          });
        } catch (error) {
          toastError("Erro ao carregar os dados do evento.");
        }
      };
      fetchEventData();
    }
  }, [isEditMode, eventId]);

  const saveEventData = async (
    updatedData: Partial<typeof eventData>,
  ): Promise<string | null> => {
    try {
      const payload = {
        author_uuid: userId,
        name: updatedData.name || eventData.name,
        description: updatedData.description || eventData.description,
        is_exclusive: updatedData.isExclusive ?? eventData.isExclusive,
        is_active: true,
        type_code: updatedData.type || eventData.type,
      };

      if (eventData.id) {
        const response = await apiRainmakers.patch(
          `/events/v1/events/${eventData.id}`,
          payload,
        );
        setEventData({
          name: updatedData.name || eventData.name,
          description: updatedData.description || eventData.description,
          type: updatedData.type || eventData.type,
          isExclusive: updatedData.isExclusive ?? eventData.isExclusive,
          id: eventData.id,
        });
        return response.data.id;
      } else {
        const response = await apiRainmakers.post(
          "/events/v1/events/",
          payload,
        );
        setEventData({
          name: updatedData.name || "",
          description: updatedData.description || "",
          type: updatedData.type || "",
          isExclusive: updatedData.isExclusive || false,
          id: response.data.id,
        });
        onUpdate();
        return response.data.id;
      }
    } catch (error) {
      toastError("Erro ao salvar os dados do evento.");
      return null;
    }
  };

  const updateTabValidation = (
    tab: keyof typeof tabValidations,
    isValid: boolean,
  ) => {
    setTabValidations((prev) => ({ ...prev, [tab]: isValid }));
  };

  const handleTabChange = (_: React.ChangeEvent<{}>, newValue: number) => {
    if (newValue > tabIndex && !Object.values(tabValidations)[tabIndex]) {
      return;
    }
    setTabIndex(newValue);
  };

  const handleCreateEvent = async (values: {
    name: string;
    description: string;
    type: string;
    isExclusive: boolean;
  }): Promise<string | null> => {
    try {
      const payload = {
        author_uuid: userId,
        name: values.name,
        description: values.description,
        is_exclusive: values.isExclusive,
        is_active: true,
        type_code: values.type,
      };

      const response = await apiRainmakers.post("/events/v1/events/", payload);
      console.log("Evento criado com sucesso:", response.data);

      return response.data.id;
    } catch (error) {
      console.error("Erro ao criar o evento:", error);
      return null;
    }
  };

  const handleCancel = () => {
    if (eventData.id) {
      setConfirmCancelOpen(true);
    } else {
      resetForm();
      onClose();
      onUpdate();
    }
  };

  const handleConfirmCancel = async () => {
    setConfirmCancelOpen(false);
    if (eventData.id) {
      try {
        await apiRainmakers.delete(`/events/v1/events/${eventData.id}`);
        toastInfo("Evento cancelado com sucesso.");
      } catch (error) {
        toastError("Erro ao cancelar o evento.");
      }
    }
    resetForm();
    onClose();
    onUpdate();
  };

  const resetForm = () => {
    setTabIndex(0);
    setTabValidations({
      eventData: false,
      location: false,
      schedule: false,
      participants: false,
      advancedConfig: false,
    });
  };

  const [scheduleData, setScheduleData] = useState<any | null>(null);

  const submitSchedule = async (payload: any) => {
    try {
      const response = await apiRainmakers.post(
        `/events/v1/schedule-rule/${eventData.id}`,
        payload,
      );
      toastSuccess("Agendamento salvo com sucesso!");
      setScheduleData(payload);
      setTabIndex(3);
    } catch (error) {
      console.error("Erro ao salvar o agendamento:", error);
      toastError("Erro ao salvar o agendamento.");
    }
  };

  const resetModal = () => {
    setTabIndex(0);
    setEventData({
      name: "",
      description: "",
      type: "",
      isExclusive: false,
      id: "",
    });
  };

  const handleFinalizeCreation = () => {
    toastSuccess("Evento criado com sucesso!");
    resetModal();
    onClose();
    onUpdate();
  };

  return (
    <Dialog
      open={open}
      onClose={handleCancel}
      fullWidth
      maxWidth="sm"
      PaperProps={{
        sx: {
          background: theme.COLORS.BACKGROUND_SECONDARY,
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
          minHeight: "400px",
          minWidth: "680px",
        },
      }}
    >
      <DialogTitle
        sx={{
          textAlign: "center",
          color: theme.COLORS.TEXT_PRIMARY,
          fontSize: 18,
        }}
      >
        NOVO EVENTO
      </DialogTitle>
      <Divider />
      <DialogContent>
        <Tabs
          value={tabIndex}
          onChange={handleTabChange}
          sx={{
            "& .MuiTab-root": {
              color: theme.COLORS.TEXT_PRIMARY,
            },
            "& .MuiTab-root.Mui-selected": {
              color: theme.COLORS.GRAY_10,
            },
            "& .MuiTabs-indicator": {
              backgroundColor: theme.COLORS.PRIMARY,
            },
            marginBottom: "1rem",
          }}
        >
          <Tab label="Dados do Evento" sx={{ fontSize: "0.75rem" }} />
          <Tab label="Local" sx={{ fontSize: "0.75rem" }} />
          <Tab label="Agendamento" sx={{ fontSize: "0.75rem" }} />
          <Tab label="Participantes" sx={{ fontSize: "0.75rem" }} />
          <Tab label="Config. Avançadas" sx={{ fontSize: "0.75rem" }} />
        </Tabs>
        {tabIndex === 0 && (
          <EventDataTab
            onNext={async (values) => {
              const eventId = await saveEventData(values);
              if (eventId) {
                setTabIndex(1);
                toastSuccess("Dados do evento salvos com sucesso!");
              }
            }}
            onCancel={handleCancel}
          />
        )}
        {tabIndex === 1 && (
          <LocationTab
            eventId={eventData.id}
            onNext={() => setTabIndex(2)}
            onCancel={handleCancel}
            isEditMode={isEditMode}
          />
        )}
        {tabIndex === 2 && (
          <ScheduleTab
            eventId={eventData.id}
            onNext={(schedule) => submitSchedule(schedule)}
            onCancel={handleCancel}
            isEditMode={isEditMode}
          />
        )}
        {tabIndex === 3 && (
          <ParticipantTab
            eventId={eventData.id}
            onNext={() => setTabIndex(4)}
            onCancel={handleCancel}
          />
        )}
        {tabIndex === 4 && (
          <AdvancedConfigTab
            eventId={eventData.id}
            onNext={handleFinalizeCreation}
            onCancel={onClose}
          />
        )}
      </DialogContent>

      <Dialog
        open={confirmCancelOpen}
        onClose={() => setConfirmCancelOpen(false)}
        PaperProps={{
          sx: {
            background: theme.COLORS.BACKGROUND_SECONDARY,
            borderRadius: "8px",
            maxWidth: "400px",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
          },
        }}
      >
        <DialogTitle
          sx={{
            textAlign: "center",
            color: theme.COLORS.TEXT_PRIMARY,
            fontSize: 18,
          }}
        >
          CONFIRMAÇÃO
        </DialogTitle>
        <Divider />
        <DialogContent>
          <Typography
            sx={{
              color: theme.COLORS.TEXT_PRIMARY,
              textAlign: "start",
              mb: 2,
              fontSize: "1rem",
            }}
          >
            Deseja realmente cancelar a criação do Evento? Todos os dados
            inseridos serão perdidos.
          </Typography>
        </DialogContent>
        <DialogActions sx={{ justifyContent: "center" }}>
          <Button
            onClick={() => setConfirmCancelOpen(false)}
            variant="outline"
            fullWidth
          >
            Não
          </Button>
          <Button onClick={handleConfirmCancel} variant="red" fullWidth>
            Sim
          </Button>
        </DialogActions>
      </Dialog>
    </Dialog>
  );
};

export default CreateEventModal;
