import { useMediaQuery } from "@mui/material";

export const BREAKPOINTS = {
  MOBILE: "(max-width: 768px)",
  TABLET: "(min-width: 769px) and (max-width: 1024px)",
  DESKTOP: "(min-width: 1025px)",
};

export const useIsMobile = () => useMediaQuery(BREAKPOINTS.MOBILE);
export const useIsTablet = () => useMediaQuery(BREAKPOINTS.TABLET);
export const useIsDesktop = () => useMediaQuery(BREAKPOINTS.DESKTOP);
